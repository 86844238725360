import React, { useState } from "react";

const Question7 = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className="faq-section animated"
      onMouseEnter={toggleAccordion}
      onMouseLeave={toggleAccordion}
    >
      <div
        className={`faq-header ${isOpen ? "open" : ""}`}
        onClick={toggleAccordion}
      >
        <div className="faq-question">
          How do you screen and vet virtual assistants?
        </div>
        {/* <div
          className="faq-icon"
          style={{ fontSize: "40px", paddingRight: "20px" }}
        >
          {isOpen ? "-" : "+"}
          <FontAwesomeIcon icon="fa-circle-question" color="black" />
        </div> */}
      </div>
      {isOpen && (
        <div className="faq-answer">
          <span>
            We conduct a rigorous and comprehensive recruitment process,
            selecting individuals based not only on their proficiency in a wide
            array of virtual assistant tasks, but also on their strong work
            ethic and, most importantly, their unwavering dedication to
            assisting clients in elevating their businesses to new heights.
          </span>
        </div>
      )}
    </div>
  );
};

export default Question7;
