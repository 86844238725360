import React, { useEffect, useState } from "react";
import axios from "axios";
import Footer from "../Home/Footer";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { IoMdAddCircleOutline } from "react-icons/io";
import { MdOutlineEdit } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import {ThreeDots} from 'react-loader-spinner';

const ShowBlog = () => {

  const[loading,setLoading]= useState(false)

  useEffect(() => {
    // Scroll to the top when the component mounts (page loads)
    window.scrollTo(0, 0);
  }, []);

  const [allBlog, setallBlog] = useState(null);
  const [updateFlag, setUpdateFlag] = useState(false);
  const navigate = useNavigate();
  const getBlog = async () => {
    setLoading(true)
    await axios
      .get(`${process.env.REACT_APP_URL}/api/getBlog`)
      .then((res) =>{ if(res.data.error){console.log(res.data.error);setLoading(false); toast.error('res.data.error')}else{
        setallBlog(res.data.data);setLoading(false);
        console.log("$$$$$$$$$", res.data.data);
      }})
      .catch((err) => {
        console.log(err);setLoading(false)
      });
  };

  const handleDelete = async (id) => {
    console.log("frontid------", id);
    await axios
      .delete(`${process.env.REACT_APP_URL}/api/deleteBlog`, { data: { id: id } })
      .then((res) => {
        if (res.data == "200") {
          toast.success("Deleted Successfully", {
            position: toast.POSITION.TOP_CENTER,
          });
          setUpdateFlag(!updateFlag);
        } else {
          toast.error("Not Deleted", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEdit = async (id) => {
    console.log("frontid------", id);
    await axios
      .delete(`${process.env.REACT_APP_URL}/api/editBlog`, { data: { id: id } })
      .then((res) => {
        if (res.data == "200") {
          toast.success("Deleted Successfully", {
            position: toast.POSITION.TOP_CENTER,
          });
          setUpdateFlag(!updateFlag);
        } else {
          toast.error("Not Edited", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getBlog();
    setUpdateFlag(false);
  }, [updateFlag]);
  return (
    <>
    <div
      style={{ minHeight: "80vh", height:'100%', background: "#89c8ff" }}
      className="min-h-screen "
    >
      <div>
        <div
          style={{
            width: "100%",
            height: "60px",
            background: "#009fe3",
            display: "flex",
            alignItems: "center",
            marginTop: "75px",
            justifyContent: "center",
          }}
        >
          <span
            style={{
              color: "white",
              fontWeight: "bold",
              fontSize: "1.5rem",
            }}
          >
            Blog List
          </span>
        </div>
      </div>
      <div
        className="  mx-5 flex flex-col"
        style={{ background: "#89c8ff" }}
      >
        <div className="flex justify-end mb-2 mt-10">
          <button
            className="bg-blue-500 d-flex text-white  rounded-md border-none cursor-pointer  ps-2 pe-6 py-2"
            style={{ textDecoration: "none", color: "white", display: "flex", alignItems: "center" }}
            onClick={() => {
              // Add the logic to navigate or display an "Addtool" form
              navigate("/addBlog");
            }}
          >
            <IoMdAddCircleOutline className="me-1" /> Add Blog
          </button>
        </div>
        <div className="overflow-x-auto  " >
          <table className="min-w-full bg-white border rounded-lg shadow-lg">
            <thead className="bg-gray-100">
              <tr>
              <th className="py-2 px-4 border-b" style={{ minWidth: "50px" }}>
                  Sr. No.
                </th>
                <th className="py-2 px-4 border-b" style={{ minWidth: "150px" }}>
                  Name
                </th>

                <th className="py-2 px-4 border-b" style={{ minWidth: "150px" }}>
                  Picture
                </th>

                <th className="py-2 px-4 border-b" style={{ minWidth: "300px" }}>
                  Summary
                </th>

                {/* <th className="py-2 px-4 border-b" style={{ minWidth: "300px" }}>
                  Description
                </th> */}

                <th className="py-2 px-4 border-b" style={{ minWidth: "150px" }}>
                  Actions
                </th>
              </tr>
            </thead>
          


            <tbody style={{ maxHeight: '300px' }}>
  {
    allBlog?.length === 0 ? 
    <h3 className="flex justify-center mx-3">No Blogs Added</h3> : ''
  }
  {allBlog?.sort((a, b) => new Date(b.date) - new Date(a.date)).map((item, index) => (
    <tr key={index} className={index % 2 === 0 ? "bg-gray-200" : "bg-gray-100"} style={{ transition: "background-color 0.3s ease" }}>
      <td
        className="py-2 px-4 border-b text-center"
        style={{ width: "50px" }}
      >
        {index+1}
      </td>
      {/* <td
        className="py-2 px-4 border-b text-center"
        style={{ width: "150px" }}
      >
        {item.name}
      </td> */}
 <td className="py-4 px-4 border-b" style={{ minWidth: '150px', height: '150px', overflow: 'hidden', display: 'grid', alignItems: 'center' }}>
  <div style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '100%', paddingRight: '15px', scrollbarWidth: 'thin' }}>
    {item.name}
  </div>
</td>
      <td className="py-2 px-4 border-b" style={{ minWidth: "150px" }}>
        <img
          src={item.screenshot}
          alt=""
          style={{ height: '120px', width: '120px' }}
          className="mx-auto rounded-md"
        />
      </td>
      <td className="py-4 px-4 border-b" style={{ height: '150px', minWidth: "150px", overflow: 'hidden', display: 'grid', alignItems: 'center' }}>
        <div style={{ overflowY: 'auto', maxHeight: '100%', paddingRight: '15px' }}>
          {item.summary}
        </div>
      </td>
      {/* Additional column for description, similar to the one in the first tbody */}
      {/* <td className="py-4 px-4 border-b" style={{ height: '150px', minWidth: "150px", overflow: 'hidden', display: 'grid', alignItems: 'center' }}>
        <div style={{ overflowY: 'auto', maxHeight: '100%', paddingRight: '15px' }}>
          {item.description.map(x => (
            <span key={x}>{x} /n</span>
          ))}
        </div>
      </td> */}
      <td style={{ minWidth: '150px' }} className="py-2 px-4 border-b ">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Link
            to={`/editBlog/${item._id}`}
            style={{
              display: "flex",
              width: '30px', height: '30px',
              justifyContent: "center",
              alignItems: "center",
              textDecoration: "none",
            }}
            className="mx-1"
          >
            <button
              value={item._id}
              style={{ width: '30px', height: '30px', justifyContent: "center", border: '1px solid #E5E7EB' }}
              className="bg-white text-black shadow-md   rounded-md   cursor-pointer hover:bg-gray-300"
            >
              <MdOutlineEdit style={{ width: '25px', height: '25px' }} />
            </button>
          </Link>

          {/* <button
            value={item._id}
            onClick={() => handleDelete(item._id)}
            style={{ width: '30px', height: '30px', border: '1px solid #E5E7EB' }}
            className="bg-white text-black shadow-md mx-1  rounded-md   cursor-pointer hover:bg-gray-300"
          >
            <MdDeleteOutline style={{ width: '25px', height: '25px' }} />
          </button> */}
          <button
    onClick={() => handleDelete(item._id)}
    style={{
        width: '30px',
        height: '30px',
        border: '1px solid #E5E7EB',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center' // Center vertically
    }}
    className="bg-white text-black shadow-md mx-1 rounded-md cursor-pointer"
>
    <MdDeleteOutline style={{ width: '25px', height: '25px' }} />
</button>
        </div>
      </td>
    </tr>
  ))}
</tbody>
          </table>
          <div className=' flex justify-center mx-auto mt-4'>
         {loading ? (
          // <div>hello</div>
            <ThreeDots type="Oval" position="top-center" className='d-flex justify-center' color="rgb(0,159,227)" height={50} width={50} />
           
          ) : (
            ''
          )}
          </div>
        </div>
      </div>
    
    </div>
    <Footer/>
    </>
  )
}

export default ShowBlog