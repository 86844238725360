import React,{useState,useEffect} from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import Image from "../Images/3099aff4115ee20f43e3cdad04f59c48-removebg-preview.png";
import ImageLn from "../Images/linkdn.png";
import ImageInsta from '../Images/insta.webp'

import { toast } from "react-toastify";
import axios from "axios";
import {ThreeDots} from 'react-loader-spinner';

const Footer = () => {

  const [sendMail,setSendMail] = useState('')
  const[firstName,setFirstName]=useState('')
  const[secondName,setSecondName]=useState('')
  const [checkbox,setCheckox] = useState(false)
  const[loading,setLoading]= useState(false)


  const handleSubmit=async(e)=>{
    e.preventDefault()
    setLoading(true)
    if(checkbox == false || sendMail.length == 0){
      toast.error("Fill all fields in the Form", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false)
      return;
    }

    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    if (!emailRegex.test(sendMail)) {
      toast.error("Invalid Email Format", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false)
      return;
    }
    const data = await axios
    .post(
      `${process.env.REACT_APP_URL}/api/subscribeFooter`,
      { sendMail, firstName,secondName},
      {
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      }
    ).then(res =>
      {if(res.data.error){toast.error(res.data.data, {
      position: toast.POSITION.TOP_CENTER,
    });console.log(res.data); setLoading(false)}
    else if(res.data.msg == 'ok'){toast.success("Subscribed Successfully", {
      position: toast.POSITION.TOP_CENTER,
    });console.log(res.data); setLoading(false); setSendMail('');setCheckox(false)}
    else{toast.error("Some Error Occured", {
      position: toast.POSITION.TOP_CENTER,
    });console.log(res.data); setLoading(false)}})
    .catch(err=> {toast.error("Some Error Occured", {
      position: toast.POSITION.TOP_CENTER,
    }); setLoading(false);console.log(err)})


  }

  return (
    <div className="footer">
      <div className="footer-column">
        <h3 className="text-footer" style={{ textShadow: "1px 1px 0px black" }}>
          Contact Information
        </h3>
        <p
          style={{
            // marginBottom: "30px",
            color: "#999",
            lineHeight: "20px",
            fontSize: "19px",
            marginBottom: "15px",
            paddingTop:'7px'
          }}
        >
          AI Virtual Ops <br />
          <br />
          8350, Bee Ridge Road,
          <br /> Sarasota, FL, 34241 <br />
          <br />
          +1 860 990 2447
        </p>
        <h4 className="text-footer" style={{ marginBottom: "10px" }}>
          LEGAL INFORMATION
        </h4>
        <p>
          <p style={{ color: "#f9f9f9" }}>
            <Link
              to="/privacy"
              style={{ textDecoration: "none", color: "#f9f9f9" }}
            >
              Privacy Policy
            </Link>
          </p>

          <p style={{ color: "#f9f9f9" }}>
            <Link
              to="/tos"
              style={{ textDecoration: "none", color: "#f9f9f9" }}
            >
              Terms of Service
            </Link>
          </p>

          <p style={{ color: "#f9f9f9" }}>
            <Link
              to="/cookie-policy"
              style={{ textDecoration: "none", color: "#f9f9f9" }}
            >
              Cookie Policy
            </Link>
          </p>
        </p>

      {/* Add your contact information here */}
      </div>
      <div className="vertical-line"></div>
      <div className="footer-column" style={{}}>
        <h3 style={{textShadow: "1px 1px 0px black"}} className="text-footer">Stay in Touch</h3>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <label htmlFor="" style={{ marginBottom: "10px" }}>
            <span style={{ color: "#999",textShadow: "1px 1px 0px black"  }}>Email</span>
          </label>
          <input
            type="text"
            placeholder="Enter your email"
            style={{ padding: "10px" }}
            value={sendMail}
            onChange={(e)=>setSendMail(e.target.value)}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <input type="checkbox" style={{ marginTop: "15px" }} checked={checkbox} onChange={(e)=>{setCheckox(e.target.checked)}} />
          <p htmlFor="subscribe" className="mt-2 ms-2 mb-4" style={{ color: "#999",textShadow: "1px 1px 0px black" }}>
            I subscribe to the AI Virtual Ops newsletter, have read and agree to
            the Privacy Policy and Terms of Service of AI Virtual Ops.*
          </p>
        </div>
        <a className="learn-more"   onClick={(e)=>handleSubmit(e)} style={{ fontSize: "18px", transition: "color 0.3s, transform 0.3s" }} 
       onMouseEnter={(e) => {
         e.target.style.color = "black";
         e.target.style.transform = "scale(1.05)"; // Increase size by 5%
       }}
       onMouseLeave={(e) => {
         e.target.style.color = "white";
         e.target.style.transform = "scale(1)"; // Return to normal size
       }}
        >
          Submit
        </a>
        <div className='form-group flex justify-start  mt-4'>
         {loading ? (
          // <div>hello</div>
            <ThreeDots type="Oval" position="top-center" color="white" height={50} width={50} />
           
          ) : (
            ''
          )}
          </div>
        {/* Add your stay in touch information here */}
      </div>
      <div className="vertical-line"></div>
      <div className="footer-column" style={{}}>
        <h3 className="text-footer" style={{ marginBottom: "40px",textShadow: "1px 1px 0px black" }}>
          Get Consultation
        </h3>
        <div>
          <a
            className="learn-more"
            // href="https://calendly.com/sassy-aivo/30min?month=2023-11"
            href="https://tidycal.com/aivo/discovery-call"
            target="_blank"
            style={{ fontSize: "18px", transition: "color 0.3s, transform 0.3s" }} 
            onMouseEnter={(e) => {
              e.target.style.color = "black";
              e.target.style.transform = "scale(1.05)"; // Increase size by 5%
            }}
            onMouseLeave={(e) => {
              e.target.style.color = "white";
              e.target.style.transform = "scale(1)"; // Return to normal size
            }}
          >
            Book a Meeting
          </a>
        </div>
        <h2 className="text-footer mb-4" style={{ marginTop: "30px" }}>
          Follow US
        </h2>
        <div className="flex ">
        <a
          href="https://www.linkedin.com/company/ai-virtual-ops/"
          target="_blank"
        >
          <img
            // src="https://rellify.com/wp-content/uploads/2023/09/linkedin_90x90.png"
            src={ImageLn}
            alt=""
            width="60"
            height="60"
          />
        </a>
        <a
          href="https://www.facebook.com/AIVirtualOps"
          style={{ marginLeft: "15px", background: "transparent" }}
        >
          <img
            src={Image}
            alt=""
            width="60"
            height="60"
            style={{
              background: "white",
              borderRadius: "5px",
              marginLeft: "0px",
            }}
          />
        </a>
        <a
          href="https://www.instagram.com/ai.virtual.ops/"
          style={{ marginLeft: "15px", background: "transparent" }}
        >
          <img
            src={ImageInsta}
            alt=""
            width="60"
            height="60"
            style={{
              background: "white",
              borderRadius: "5px",
              marginLeft: "0px",
            }}
          />
        </a>
        </div>
        {/* Add your Get Consultation information here */}
      </div>
      <div className="vertical-line"></div>
      {/* <div className="footer-column">
        <h3 style={{textShadow: "1px 1px 0px black"}} className="text-footer">Be Part of Our Team!</h3>
        <p className="buy-now-form" style={{ marginTop: "40px" }}>
          <a
            className="learn-more"
            href="https://forms.gle/MRbJ4aa1uQFjtwu89"
            target="_blank"
            style={{ fontSize: "18px", transition: "color 0.3s, transform 0.3s" }} 
            onMouseEnter={(e) => {
              e.target.style.color = "black";
              e.target.style.transform = "scale(1.05)"; // Increase size by 5%
            }}
            onMouseLeave={(e) => {
              e.target.style.color = "white";
              e.target.style.transform = "scale(1)"; // Return to normal size
            }}
          >
            JOIN US
          </a>
        </p>
       
      </div> */}
    </div>
  );
};

export default Footer;
