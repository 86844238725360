// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul{
    list-style-type: disc;
}
ol{
    list-style-type: decimal;
}`, "",{"version":3,"sources":["webpack://./src/Blog/BlogListNumber.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;AACA;IACI,wBAAwB;AAC5B","sourcesContent":["ul{\n    list-style-type: disc;\n}\nol{\n    list-style-type: decimal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
