import React from "react";

const Maincontent = () => {
  return (
    <div>
      <div
        style={{
          width: "100%",
          height: "60px",
          background: "#009fe3",
          display: "flex",
          alignItems: "center",
          marginTop: "75px",
        }}
      >
        <span
          style={{
            color: "white",
            fontWeight: "bold",
            marginLeft: "10%",
            fontSize: "1.5rem",
          }}
        >
          Privacy Policy
        </span>
      </div>
    </div>
  );
};

export default Maincontent;
