import React from "react";
import "./Dynamic.css";
import Image from "../Images/christina-wocintechchat-com-RMweULmCYxM-unsplash.jpg";
import ImageF from "../Images/lukas-hND1OG3q67k-unsplash.jpg";
import ImageS from "../Images/desola-lanre-ologun-IgUR1iX0mqM-unsplash.jpg";

const Dynamic = () => {
  return (
    <div className="profit-main" style={{ background: "#89c8ff" }}>
      <div
        className="mission-main"
        style={{
          width: "90%",
          gap: "2vw",
          marginTop: "20px",
        }}
      >
        <div className="first-Dynamic mt-8">
          <h2 className="my-5 dynamicheadwidth" >
            A Dynamic Team Guided by Core Principles
          </h2>
          <p
            style={{
              textAlign: "justify",
              textJustify: "inter-word",
              marginBottom: "-10px",
            }}
          >
            Learn how AI Virtual Ops elevates businesses to new AI First
            paradigm and the same time embracing our core values and ethics in
            achieving measurable and scalable success for companies across the
            globe.
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            {/* <button className="learn-more">Our People</button> */}
            {/* <button
              className="learn-more"
              href='https://docs.google.com/forms/d/e/1FAIpQLSdrn2b6CH7fPoLf_vb3NAsMoF8-U2H0GIiQaavcUoKdSZWTKg/closedform?pli=1'
            >
              Join The Team
            </button> */}
           
              <button
              className="learn-more"
               style={{transition: "color 0.3s, transform 0.3s" }}
               onMouseEnter={(e) => {
                 e.target.style.color = "black";
                 e.target.style.transform = "scale(1.05)"; // Increase size by 5%
               }}
               onMouseLeave={(e) => {
                 e.target.style.color = "white";
                 e.target.style.transform = "scale(1)"; // Return to normal size
               }}
              >
                 <a
              // className="learn-more"
              href='https://docs.google.com/forms/d/e/1FAIpQLSdrn2b6CH7fPoLf_vb3NAsMoF8-U2H0GIiQaavcUoKdSZWTKg/closedform?pli=1'
              target="_blank" // Opens the link in a new tab/window
              rel="noopener noreferrer" // Recommended for security reasons when using target="_blank"
            >
                Join The Team
                </a>
              </button>
          
          </div>
          <img
            src={Image}
            alt=""
            style={{ borderRadius: "30px", width: "99%" }}
          />
        </div>
        <div className="second-Dynamic">
          <img
            src={ImageS}
            alt=""
            style={{}}
            className="dynimg"
          />
          <img
            src={ImageF}
            alt=""
            className="dynimg"
            style={{}}
          />
        </div>
      </div>
    </div>
  );
};

export default Dynamic;
