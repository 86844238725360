import React, { useState, useEffect } from "react";
import "./MainBody.css";
import { Link } from "react-router-dom";
import Imagef from "../Images/oqvrck3rt6o0pflvplwu.webp";
import yarnpage from "../Images/YarnIt_Page.png";
import Supergrow from "../Images/Supergrow_logo.png";
import superpage from "../Images/Supergrow_Page.png";
import chapple from "../Images/Chapple_Logo.png";
import chapplepage from "../Images/Chapple_Page.png";
import Float from "../Images/Flot.ai_logo.png";
import Floatpage from "../Images/Flot.ai_Page.png";
import Auto from "../Images/Autoblogging_Logo.png";
import Autopage from "../Images/Autoblogging_Page.png";
import Mixo from "../Images/logo-wordmark.png";
import Mixopage from "../Images/Mixo_Page.png";
import Sofr from "../Images/Softr-Logo-Colour-Dark.png";
import sofrpage from "../Images/Softr_Page.png";
import prores from "../Images/ProRes_Logo.png";
import prorespage from "../Images/ProRes_Page.png";
import Fabric from "../Images/Fabric_Logo.png";
import Fabricpage from "../Images/Fabric_Page.png";
import Gpt from "../Images/GPTZero_Logo-removebg-preview.png";
import Gptpage from "../Images/GPTZero_Page.png";
import Hix from "../Images/hixai-logo.png";
import Hixpage from "../Images/Screenshot (391).png";
import Knowlee from "../Images/Screenshot (393).png";
import Knowleepage from "../Images/Screenshot (392).png";
import promptpal from "../Images/download.svg";
import promprpage from "../Images/Screenshot (394).png";
import aimagic from "../Images/Screenshot__396_-removebg-preview.png";
import aimagicpage from "../Images/Screenshot (395).png";
import humata from "../Images/Screenshot__397_-removebg-preview.png";
import humatapage from "../Images/humata-page.png";
import speech from "../Images/Screenshot (398).png";
import speechpage from "../Images/download.png";
import fillout from "../Images/Screenshot__399_-removebg-preview.png";
import filloutpage from "../Images/fillout-page.png";
import finwise from "../Images/Screenshot__400_-removebg-preview.png";
import finwisepage from "../Images/finwise-page.png";

import boost from '../Images/boost.png'
import boostl from '../Images/boostlogo.png'
import guidde from '../Images/guidde.png'
import guiddel from '../Images/guiddelogo.png'
import txtc from '../Images/textcortex.png'
import txtcl from '../Images/textcortexlogo.png'
import riverside from '../Images/riverside.png'
import riversidel from '../Images/riversidel.png'

import byp from '../Images/byp.png'
import bypl from '../Images/bypl.PNG'


import mrush from '../Images/mrush.png'
import mrushl from '../Images/mrushl.png'
import vwo from '../Images/vwo.png'
import vwol from '../Images/vwol.png'

import {ThreeDots} from 'react-loader-spinner';





import axios from "axios";

const MainBody = () => {

  const[loading,setLoading]= useState(false)

  // const data = [
  //   {
  //     id: 1,
  //     image: yarnpage,
  //     name: "YarnIt",
  //     logo: Imagef,
  //     tags: ["Generative AI"],
  //     tag: ["#ContentCreation #Yarnitapp #AllinOne"],
  //     link: "https://yarnit.live/?gr_pk=Gn28&gr_uid=zvxE",
  //     description:
  //       "Discover the power of YarnIt through shaping the future of content creation! Leveraging cutting-edge AI tools, YarnIt brings unparalleled precision to crafting engaging narratives, streamlining workflows, and unlocking creative potential.",
  //   },
  //   {
  //     id: 2,
  //     image: superpage,
  //     name: "Supergrow",
  //     logo: Supergrow,
  //     tags: ["Generative AI"],
  //     tag: ["#PersonalBrand #SupergrowAI #ContentCreation"],
  //     link: "https://www.supergrow.ai//?ref=AIVO",
  //     description:
  //       "Setting new benchmarks in marketing efficiency; Supergrow's advanced AI tools seamlessly integrate data-driven insights, predictive analytics, and automated marketing strategies, empowering businesses to reach new heights.",
  //   },
  //   {
  //     id: 3,
  //     image: chapplepage,
  //     name: "Chapple",
  //     tags: ["Generative AI"],
  //     logo: chapple,
  //     tag: ["#AIWritingAssistant #ChappleAI #ContentCreation"],
  //     link: "https://chapple.ai/register?aff=ABJAYUOQNLQG",
  //     description:
  //       "Represents the pinnacle in AI-driven content creation.  Chapple transforms content crafting and seamlessly merges state-of-the-art technology with user-friendly features, ensuring creative expression is within reach for everyone.",
  //   },
  //   {
  //     id: 4,
  //     image: Floatpage,
  //     name: "Flot.ai",
  //     logo: Float,
  //     tags: ["Generative AI"],
  //     tag: ["#AICopilot #Flot.ai #AllinOne"],
  //     link: "https://flot.ai/?via=AIVO",
  //     description:
  //       "Stands at the forefront of AI innovation, offering cutting-edge solutions to enhance companies' capabilities. Flot.ai's advanced technology empowers AI toolkits, ensuring seamless integration and heightened performance across various applications. Explore the future of AI with Flot.ai.",
  //   },
  //   {
  //     id: 5,
  //     image: Autopage,
  //     name: "Autoblogging",
  //     logo: Auto,
  //     tags: ["Generative AI"],
  //     tag: ["#ContentCreation #Autoblogging.ai #AIArticleWriter"],
  //     link: "https://autoblogging.ai/?sld=aivosassy",
  //     description:
  //       "Designed to assist bloggers, website owners, and agencies in enhancing their online presence by leveraging state-of-the-art technology. Autoblogging's goal is to streamline content creation processes, reduce costs, and empower human collaborators with an efficient initial draft through standard operating procedures (SOPs).",
  //   },
  //   {
  //     id: 6,
  //     image: Mixopage,
  //     name: "Mixo",
  //     logo: Mixo,
  //     tags: ["Web-builder AI"],
  //     tag: ["#WebBuilder #Mixo #Entrepreneurship"],
  //     link: "https://mixo.io/?via=AIVO",
  //     description:
  //       "Accelerates the startup journey by swiftly launching, validating, and nurturing ideas for entrepreneurs. Providing a hassle-free experience, Mixo generates comprehensive website content, enabling users to effortlessly create captivating landing pages and kickstart their growth journey.",
  //   },
  //   {
  //     id: 7,
  //     image: sofrpage,
  //     name: "Softr",
  //     logo: Sofr,
  //     tags: ["Web Development AI"],
  //     tag: ["#WebDevelopment #SoftrPlatform #AIInnovation"],
  //     link: "https://softrplatformsgmbh.grsm.io/7se2x6jkx8sg",
  //     description:
  //       "Innovative AI-powered platform that empowers users to effortlessly create and customize web applications. Seamlessly blending advanced technology with a user-friendly interface, Softr simplifies the development process, enabling individuals and businesses to bring their ideas to life with ease.",
  //   },
  //   {
  //     id: 8,
  //     image: prorespage,
  //     name: "ProRes.ai",
  //     logo: prores,
  //     tags: ["Resume Builder AI"],
  //     tag: ["#ResumeBuilder #ProRes #CareerElevation"],
  //     link: "https://prores.ai/?via=AIVO",
  //     description:
  //       "Leverages AI technology to craft customized, professional resumes that showcase your unique skills and experiences.",
  //   },
  //   {
  //     id: 9,
  //     image: Fabricpage,
  //     name: "Fabric",
  //     logo: Fabric,
  //     tags: ["Workspace AI"],
  //     tag: ["#WorkspaceRevolution #FabricAI #AIProductivity"],
  //     link: "https://fabric.so/?via=AIVO",
  //     description:
  //       "Innovates platform through revolutionizing file explorer, becoming your AI-native workspace. Seamlessly organize your drives, clouds, notes, links, and files in one intelligent home.",
  //   },
  //   {
  //     id: 10,
  //     image: Gptpage,
  //     name: "GPTZero",
  //     logo: Gpt,
  //     tags: ["AI-text Detection"],
  //     tag: ["#AITextDetection #GPTZero #ResponsibleAI"],
  //     link: "https://gptzero.me/?via=AIVO",
  //     description:
  //       "Pioneering AI-text detection for over 1M users, empowering responsible AI adoption. GPTZero safeguards education and our world with cutting-edge technology.",
  //   },
  //   {
  //     id: 11,
  //     image: Hixpage,
  //     name: "Hix",
  //     logo: Hix,
  //     tags: ["AI-text Detection"],
  //     tag: ["#AITextDetection #GPTZero #ResponsibleAI"],
  //     link: "https://hix.ai/?ref=ymuzota",
  //     description:
  //       "HIX AI Writer, just as its name implies, is one of the best AI text generators that can help with speedy content creation. Thanks to its sophisticated algorithms and leading-edge calculations, AI Writer empowers users to write high-quality content with the click of a button.",
  //   },
  //   {
  //     id: 12,
  //     image: Knowleepage,
  //     name: "Knowlee",
  //     logo: Knowlee,
  //     tags: ["AI-text Detection"],
  //     tag: ["#AITextDetection #GPTZero #ResponsibleAI"],
  //     link: "https://app.knowlee.ai/",
  //     description:
  //       "it acts as a personal assistant, understanding user preferences and requirements, ensuring that every interaction is meaningful and tailored.",
  //   },
  //   {
  //     id: 13,
  //     image: promprpage,
  //     name: "Promptpal",
  //     logo: promptpal,
  //     tags: ["AI-text Detection"],
  //     tag: ["#AITextDetection #GPTZero #ResponsibleAI"],
  //     link: "https://promptpal.net/?via=AIVO",
  //     description:
  //       "PromptPal can be used to train your image prompt writing skills. By extracting descriptions that were used to train Midjourney's text-to-image model, you can gain valuable insights into the process of creating vivid and compelling descriptions that evoke specific images in the mind of the reader.",
  //   },
  //   {
  //     id: 14,
  //     image: aimagicpage,
  //     name: "Promptpal",
  //     logo: aimagic,
  //     tags: ["AI-text Detection"],
  //     tag: ["#AITextDetection #GPTZero #ResponsibleAI"],
  //     link: "https://aimagicx.com/",
  //     description:
  //       "AI Magicx easily crafts various content types, from articles and ads to product descriptions and social media posts, offering versatility for your writing needs.",
  //   },
  //   {
  //     id: 15,
  //     image: humatapage,
  //     name: "Promptpal",
  //     logo: humata,
  //     tags: ["AI-text Detection"],
  //     tag: ["#AITextDetection #GPTZero #ResponsibleAI"],
  //     link: "https://www.humata.ai/?via=AIVO",
  //     description:
  //       "Humata AI is a tool that uses artificial intelligence to help you analyze and understand complex documents from your files. You can ask questions about your data and get answers powered by AI instantly. It can generate various content based on your files, such as reports, papers, or summaries.",
  //   },
  //   {
  //     id: 16,
  //     image: speech,
  //     name: "Promptpal",
  //     logo: speechpage,
  //     tags: ["AI-text Detection"],
  //     tag: ["#AITextDetection #GPTZero #ResponsibleAI"],
  //     link: "https://speechify.com/?source=fb-for-mobile&via=AIVO",
  //     description:
  //       "In addition to transforming text into speech, Speechify allows you to convert text into audio files in MP3 and WAV formats. You can use these high-quality natural-sounding voices as a voiceover in your content.",
  //   },
  //   {
  //     id: 17,
  //     image: filloutpage,
  //     name: "Promptpal",
  //     logo: fillout,
  //     tags: ["AI-text Detection"],
  //     tag: ["#AITextDetection #GPTZero #ResponsibleAI"],
  //     link: "https://www.fillout.com?ref=AIVO",
  //     description:
  //       "Fillout helps you easily create powerful forms, surveys and quizzes. Our form builder is highly customizable and offers a wide range of native integrations, like Airtable, Google Sheets, Notion, Calendly, Mailchimp and many more. Create the exact form you need in minutes.",
  //   },
  //   {
  //     id: 18,
  //     image: finwisepage,
  //     name: "Promptpal",
  //     logo: finwise,
  //     tags: ["AI-text Detection"],
  //     tag: ["#AITextDetection #GPTZero #ResponsibleAI"],
  //     link: "https://finwiseapp.io/",
  //     description:
  //       "FinWise is a personal finance management app designed to help you take control of your finances, track expenses, plan your future and achieve your financial goals. It consolidates all your accounts, budgets, and transactions in one place using AI for easy tracking and analysis.",
  //   },
  //   {
  //     id: 19,
  //     image: boost,
  //     name: "Boost Space",
  //     logo: boostl,
  //     tags: ["Data Synchronization AI"],
  //     tag: ["#AIForDataCentralization #BoostSpaceToBoostBusiness #DataCentralization"],
  //     link: "https://boost.space/",
  //     description:
  //       "Boost your business with Boost Space, a powerful AI tool that centralizes your data and empowers your tools. Enhance speed, optimize functionality, and transform user experiences. Experience the true potential of your business with Boost Space.",
  //   },
  //   {
  //     id: 20,
  //     image: guidde,
  //     name: "Guidde",
  //     logo: guiddel,
  //     tags: ["Generative AI Platform"],
  //     tag: ["#PersonalizedVideoGuides #InnovateWithGuiddeAI #VideoDocumentationInSeconds"],
  //     link: "https://www.guidde.com/",
  //     description:
  //       "Accelerate video documentation creation with Guidde, the generative AI platform for businesses. Empower your team to produce content 11x faster, revolutionizing the way you share information. Experience efficiency and innovation with Guidde. ",
  //   },
  //   {
  //     id: 21,
  //     image: finwisepage,
  //     name: "Streak CRM",
  //     logo: 'https://boost.space/wp-content/themes/fluid-child/assets/img/bs-assets/bs-logo-white.svg?ver=2',
  //     tags: ["CRM AI"],
  //     tag: [""],
  //     link: "https://www.streak.com/",
  //     description:
  //       "",
  //   },
  //   {
  //     id: 22,
  //     image: txtc,
  //     name: "Text.cortex",
  //     logo: txtcl,
  //     tags: ["AI Assistant"],
  //     tag: ["#AIRevolution #TextCortexMagic #AIforContent"],
  //     link: "https://textcortex.com/",
  //     description:
  //       "Revolutionize the landscape of content creation. TextCortex AI employs advanced NLG algorithms, enriched with proven marketing strategies, empower marketers, e-commerce enthusiasts, and copywriters to elevate their content effortlessly. Uncover limitless possibilities with AI-driven copywriting with TextCortex! ",
  //   },
  //   {
  //     id: 23,
  //     image: riverside,
  //     name: "Riverside",
  //     logo: riversidel,
  //     tags: ["Audio and Video Editing AI"],
  //     tag: ["#ContentCreationHub #RiversideInnovation #PodcastRevolution"],
  //     link: "https://riverside.fm/",
  //     description:
  //       "Empowering content creators worldwide, Riverside.fm emerges as the leading platform for seamless content production. Trusted by industry giants like Spotify, Marvel, Mark Zuckerberg, Gary Vee, and Apple, Riverside.fm is your key to unlocking unparalleled video and audio creation experiences. ",
  //   },
  //   {
  //     id: 24,
  //     image: byp,
  //     name: "bypassGPT",
  //     logo: bypl,
  //     tags: [" AI Detector Rewriter"],
  //     tag: ["#AIContentMastery #BypassGPTBoost  #SeamlessWriting"],
  //     link: "https://bypassgpt.ai/",
  //     description:
  //       "Supercharge your content strategy with BypassGPT—bypassing AI checkers to safeguard your rankings and deliver compelling, AI-friendly content. Stay ahead in the digital landscape by ensuring your content seamlessly navigates AI detection, preserving your online presence and audience engagement.  ",
  //   },
  //   {
  //     id: 25,
  //     image: mrush,
  //     name: "MailRush",
  //     logo: mrushl,
  //     tags: [" Cold Email Service Provider AI"],
  //     tag: ["#EmailOutreachPro #MailRushAdvantage #DeliverabilityBoost"],
  //     link: "https://mailrush.io/",
  //     description:
  //       "Aggrandize your email outreach strategy with MailRush.io—an all-in-one solution for B2B campaigns, automated follow-ups, and sender reputation management. Experience seamless cold email execution, enhanced deliverability, and effortless automation, setting a new standard for your email marketing success. ",
  //   },
  //   {
  //     id: 26,
  //     image: vwo,
  //     name: "VWO",
  //     logo: vwol,
  //     tags: [" Cold Email Service Provider AI"],
  //     tag: ["#EmailOutreachPro #MailRushAdvantage #DeliverabilityBoost"],
  //     link: "https://vwo.com/",
  //     description:
  //       "Aggrandize your email outreach strategy with MailRush.io—an all-in-one solution for B2B campaigns, automated follow-ups, and sender reputation management. Experience seamless cold email execution, enhanced deliverability, and effortless automation, setting a new standard for your email marketing success. ",
  //   },
  //   // Add more data objects for each box
  // ];
const data = []

  const [tools, setTools] = useState([])

  useEffect(() => {
    setLoading(true)
    axios.get(`${process.env.REACT_APP_URL}/api/gettool`).then(res => { console.log(res.data.data, "----"); setTools(res.data.data); setLoading(false) })
  }, [])

  return (
    <div style={{ marginTop: '30px' }}>
      <hr
        style={{
          width: "98%",
          opacity: "0",
          marginTop: "20px",
        }}
      />
      <div
        className="profit-main"
        style={{ background: "#89c8ff", paddingTop: "50px" }}
      >
        <div
          className="container-tool-body mx-5"
          style={{  marginBottom: "50px" }}
        >
          {data.map((item) => (
            <div key={item.id} className="box d-flex flex-column " style={{ position: 'relative' }} >
              <div className="" style={{ marginBottom: '70px' }}>
                <div className="box-image">
                  <img src={item.image} alt={item.name} />
                </div>
                <div className="box-details d-flex "  >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div className="box-logo">
                      <img src={item.logo} />
                    </div>
                  </div>
                  <hr />
                  <div className="box-tags">
                    {item.tags.map((tag, index) => (
                      <span key={index} className="box-tag mt-1">
                        {tag}
                      </span>
                    ))}
                  </div>
                  <div style={{}}>
                    {item.tag.map((tag, index) => (
                      <span key={index} className="box-tag-2 mt-1">
                        {tag}
                      </span>
                    ))}
                  </div>
                  <p
                    style={{
                      // height: "125px",
                      marginTop: "0px",
                      marginBottom: "auto",
                      textAlign: "justify",
                      textJustify: "inter-word",
                    }}
                  // className="mb-auto"
                  >
                    {item.description}
                  </p>
                  {/* <p className="p-tag-tool">Free</p> */}
                  {/* <div className="mt-auto " style={{ display: "flex", justifyContent: "center" }}>
             
                    <button
                    href={item.link}
                      className="learn-more mt-auto "
                      // style={{ marginTop: "20px" }}
                    >
                           <a
                    href={item.link}
                    target="_blank"
                    
                    style={{
                      textDecoration: "none",
                      color: "white",
                    }}
                  >
                     Details
                  </a>
                     
                    </button>
                </div> */}
                </div>
              </div>
              <div className=" mt-auto d-flex justify-content-center mx-auto mb-3" style={{ position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}>
                <button
                  href={item.link}
                  className="learn-more mt-auto "
                // style={{ marginTop: "20px" }}
                >
                  <a
                    href={item.link}
                    target="_blank"

                    style={{
                      textDecoration: "none",
                      color: "white",
                    }}
                  >
                    Details
                  </a>

                </button>

              </div>

            </div>
          ))}
          {tools.map((item) => (
            <div key={item.id} className="box d-flex flex-column " style={{ position: 'relative' }} >
              <div className="" style={{ marginBottom: '70px' }}>
                <div className="box-image">
                  <img src={item.screenshot} alt={item.name} />
                </div>
                <div className="box-details d-flex "  >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div className="box-logo">
                      <img src={item.logo} />
                    </div>
                  </div>
                  <hr />
                  <div className="box-tags">
                    {item.tags.map((tag, index) => (
                      <span key={index} className="box-tag mt-1">
                        {tag}
                      </span>
                    ))}
                  </div>
                  <div style={{}}>
                    {item.tag.map((tag, index) => (
                      <span key={index} className="box-tag-2 mt-1">
                        #{tag}
                      </span>
                    ))}
                  </div>
                  <p
                    style={{
                      // height: "125px",
                      marginTop: "0px",
                      marginBottom: "auto",
                      textAlign: "justify",
                      textJustify: "inter-word",
                    }}
                  // className="mb-auto"
                  >
                    {item.description}
                  </p>

                </div>
              </div>
              <div className=" mt-auto d-flex justify-content-center mx-auto mb-3" style={{ position: 'absolute', bottom: '0', left: '50%', transform: 'translateX(-50%)' }}>
                <button
                  href={item.link}
                  className="learn-more mt-auto "
                // style={{ marginTop: "20px" }}
                >
                  <a
                    href={item.link}
                    target="_blank"

                    style={{
                      textDecoration: "none",
                      color: "white",
                    }}
                  >
                    Details
                  </a>

                </button>

              </div>

            </div>
          ))}
          
        </div>
        <div className=' flex justify-center mx-auto mt-4'>
         {loading ? (
          // <div>hello</div>
            <ThreeDots type="Oval" position="top-center" className='flex justify-center' color="rgb(0,159,227)" height={50} width={50} />
           
          ) : (
            ''
          )}
          </div>
        {/* <div
          className="container-tool-body"
          style={{ width: "90%", marginBottom: "50px" }}
        >
          {tools.map((item) => (
            <div key={item.id} className="box d-flex flex-column " style={{position: 'relative'}} >
              <div className="" style={{marginBottom:'70px'}}>
              <div className="box-image">
                <img src={item.screenshot} alt={item.name} />
              </div>
              <div className="box-details d-flex "  >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="box-logo">
                    <img src={item.logo} />
                  </div>
                </div>
                <hr />
                <div className="box-tags">
                  {item.tags.map((tag, index) => (
                    <span key={index} className="box-tag">
                      {tag}
                    </span>
                  ))}
                </div>
                <div style={{  }}>
                  {item.tag.map((tag, index) => (
                    <span key={index} className="box-tag-2">
                      #{tag}
                    </span>
                  ))}
                </div>
                <p
                  style={{
                    // height: "125px",
                    marginTop: "0px",
                    marginBottom: "auto",
                    textAlign: "justify",
                    textJustify: "inter-word",
                  }}
                  // className="mb-auto"
                >
                  {item.description}
                </p>
            
              </div>
              </div>
              <div className=" mt-auto d-flex justify-content-center mx-auto mb-3" style={{  position:'absolute' ,bottom: '0', left: '50%', transform: 'translateX(-50%)'  }}>
             <button
             href={item.link}
               className="learn-more mt-auto "
               // style={{ marginTop: "20px" }}
             >
                    <a
             href={item.link}
             target="_blank"
             
             style={{
               textDecoration: "none",
               color: "white",
             }}
           >
              Details
           </a>
              
             </button>

         </div>
            
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default MainBody;
