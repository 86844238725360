// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.who-we-container{
    display: grid;
    grid-template-columns: repeat(2,0.4fr);
    justify-content: center;
    gap: 20px;
}
.who-we-first{
    border-radius: 5%;
    background-color: white;
    padding: 30px;
    margin-bottom: 6vw;  
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.learn-more {
    margin-top: 40px;
    padding: 15px 30px;
    background-color: #8cd22d;
    color: white;
    font-size: 18px;
    font-weight: 400;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
}
@media (max-width:800px) {
    .who-we-container{
        grid-template-columns: repeat(1,1fr);
    }
}`, "",{"version":3,"sources":["webpack://./src/Home/Whoweserve.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sCAAsC;IACtC,uBAAuB;IACvB,SAAS;AACb;AACA;IACI,iBAAiB;IACjB,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;AAC5C;AACA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,+BAA+B;AACnC;AACA;IACI;QACI,oCAAoC;IACxC;AACJ","sourcesContent":[".who-we-container{\n    display: grid;\n    grid-template-columns: repeat(2,0.4fr);\n    justify-content: center;\n    gap: 20px;\n}\n.who-we-first{\n    border-radius: 5%;\n    background-color: white;\n    padding: 30px;\n    margin-bottom: 6vw;  \n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n.learn-more {\n    margin-top: 40px;\n    padding: 15px 30px;\n    background-color: #8cd22d;\n    color: white;\n    font-size: 18px;\n    font-weight: 400;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    font-family: 'Lato', sans-serif;\n}\n@media (max-width:800px) {\n    .who-we-container{\n        grid-template-columns: repeat(1,1fr);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
