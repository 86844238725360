// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.question {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #000;
    width: 100%;
}

.faq-section {
    margin-bottom: 10px;
    width: 100%;
    /* border-top: 1px solid #000; */
}

.faq-header {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
}

/* .faq-header.open {
    background-color: #eeefe8;
} */

.faq-question {
    flex-grow: 1;
    font-size: 25px;
    font-weight: bold;
    line-height: 100%;
    color: #666;
    padding-left: 10px
}

.faq-icon {
    font-size: 18px;
    margin-left: 10px;
}

.faq-answer {
    padding: 20px;
    /* background-color: #eeefe8; */
    font-size: 20px;
    /* margin-top: 24px; */
    margin-bottom: 24px;
    font-weight: 400;
    line-height: 150%;
    color: #666;

}`, "",{"version":3,"sources":["webpack://./src/Price/Question.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,0BAA0B;IAC1B,WAAW;AACf;;AAEA;IACI,mBAAmB;IACnB,WAAW;IACX,gCAAgC;AACpC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,aAAa;AACjB;;AAEA;;GAEG;;AAEH;IACI,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,iBAAiB;IACjB,WAAW;IACX;AACJ;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,+BAA+B;IAC/B,eAAe;IACf,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;IACjB,WAAW;;AAEf","sourcesContent":[".question {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    border-top: 1px solid #000;\n    width: 100%;\n}\n\n.faq-section {\n    margin-bottom: 10px;\n    width: 100%;\n    /* border-top: 1px solid #000; */\n}\n\n.faq-header {\n    display: flex;\n    align-items: center;\n    cursor: pointer;\n    padding: 10px;\n}\n\n/* .faq-header.open {\n    background-color: #eeefe8;\n} */\n\n.faq-question {\n    flex-grow: 1;\n    font-size: 25px;\n    font-weight: bold;\n    line-height: 100%;\n    color: #666;\n    padding-left: 10px\n}\n\n.faq-icon {\n    font-size: 18px;\n    margin-left: 10px;\n}\n\n.faq-answer {\n    padding: 20px;\n    /* background-color: #eeefe8; */\n    font-size: 20px;\n    /* margin-top: 24px; */\n    margin-bottom: 24px;\n    font-weight: 400;\n    line-height: 150%;\n    color: #666;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
