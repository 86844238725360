import React from "react";
import "./Third.css";
import Line from "../Images/AIVO line.png";

const Third = () => {
  return (
    <div className="container-3">
      {/* right */}
      <div className="right-3">
        <h2 className="main-3">Empowering Success in Every Area</h2>
        <img src={Line} alt="" style={{ width: "35%" }} />
        <div
          style={{ marginTop: "30px", fontSize: "20px", textAlign: "center" }}
        >
          We offer exceptional support in these areas,
        </div>
        <div
          style={{
            fontSize: "20px",
            marginBottom: "30px",
            textAlign: "center",
          }}
        >
          while also extending our capabilities beyond them
        </div>
        <div
          style={{
            fontWeight: "bold",
            marginBottom: "30px",
            textAlign: "center",
          }}
        >
          If Your Niche Isn't Listed, Let's Chat and Get Creative!
        </div>
      </div>
    </div>
  );
};

export default Third;
