import React, { useEffect } from "react";
import Header from "./Header";
import Maincontent from "./Maincontent";
import Boxes from "./Boxes";
import Skill from "./Skill";
import Profit from "./Profit";
import Feature from "../Home/Feature";
import Faq from "./Faq";
import Form from "./Form";
import Footer from "../Home/Footer";

const Price = () => {
  useEffect(() => {
    // Scroll to the top when the component mounts (page loads)
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header />
      <Maincontent />
      <Boxes />
      <Skill />
      <Profit />
      <Feature />
      <Faq />
      <Footer />
      {/* <Form /> */}
    </div>
  );
};

export default Price;
