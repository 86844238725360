import React, { useEffect } from "react";
import Header from "./Header";
import Maincontent from "./Maincontent";
import TeamNew from "./TeamNew";
import Imagecontainer from "./Imagecontainer";
import Missinos from "./Missinos";
import Leadership from "./Leadership";
import Serve from "./Serve";
import Reverse from "./Reverse";
import Ourvalue from "./Ourvalue";
import Dynamic from "./Dynamic";
import Footer from "../Home/Footer.js";

const About = () => {
  useEffect(() => {
    // Scroll to the top when the component mounts (page loads)
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header />
      {/* <Maincontent /> */}
      <TeamNew />
      <Imagecontainer />
      <Missinos />
      <Leadership />
      <Serve />
      <Reverse />
      <Ourvalue />
      <Dynamic />
      <Footer />
      {/* <Secondcontent />
      <Feature /> */}
    </div>
  );
};

export default About;
