import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {ThreeDots} from 'react-loader-spinner';
import './BlogIndi.css'
import { RiFacebookBoxFill } from "react-icons/ri";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { htmlConverterReact } from 'html-converter-react';

const BlogIndi = () => {

  const[loading,setLoading]= useState(false)

    const { id } = useParams();
    const[blog,setBlog]= useState([])

    useEffect(()=>{
      setLoading(true)
        axios.get(`${process.env.REACT_APP_URL}/api/getblogIndi/${id}`).then(res => {console.log(res.data.data,"----");setLoading(false); setBlog(res.data.data)})
       },[])

  return (

    <>
  <div>
    <div
      style={{
        width: "100%",
        paddingTop: "10px",
        paddingBottom: "10px",
        background: "#009fe3",
        display: "flex",
        alignItems: "center",
        marginTop: "75px",
      }}
    >
      <span
        style={{
          color: "white",
          fontWeight: "bold",
          fontSize: "1.5rem",
        }}
        className="text-center flex justify-center mx-auto"
      >
        {blog.name}
      </span>
    </div>
  </div>

  <div className="social-media-links">
    <a
      href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        window.location.href
      )}&quote=${encodeURIComponent(blog.name)}`}
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
    >
      <RiFacebookBoxFill className="mr-2" /> Share on Facebook
    </a>
    <a
      href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
        window.location.href
      )}&text=${encodeURIComponent(blog.name)}`}
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
    >
      <FaXTwitter className="mr-2" /> Tweet on Twitter
    </a>
    <a
      href={`https://www.linkedin.com/feed/?shareActive=true&text=${encodeURIComponent(
        blog.name + " " + window.location.href
      )}`}
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
    >
      <FaLinkedin className="mr-2" /> Post on LinkedIn
    </a>
  </div>

  <div className="profit-main">
    <div className="profit-second pb-5">
      <div
        style={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        <img
          src={blog.screenshot}
          className="mb-10"
          alt=""
          style={{
            marginTop: "30px",
            width: "60%",
            minWidth: "300px",
            borderRadius: "20px",
          }}
        />
      </div>
      <div
        className="flex justify-center flex-wrap  space-x-4 "
        style={{ marginBottom: "40px" }}
      >
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            window.location.href
          )}&quote=${encodeURIComponent(blog.name)}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{width:'220px'}}
          className="flex items-center px-4 smlink py-2 my-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <RiFacebookBoxFill className="mr-2" /> Share on Facebook
        </a>
        <a
          href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
            window.location.href
          )}&text=${encodeURIComponent(blog.name)}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{width:'220px'}}
          className="flex items-center px-4 smlink mx-0 my-2 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
        >
          <FaXTwitter className="mr-2" /> Tweet on Twitter
        </a>
        <a
          href={`https://www.linkedin.com/feed/?shareActive=true&text=${encodeURIComponent(
            blog.name + " " + window.location.href
          )}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{width:'220px'}}
          className="flex items-center px-4 smlink mx-0 my-2 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <FaLinkedin className="mr-2" /> Post on LinkedIn
        </a>
      </div>
      <div
        className="mx-auto"
        style={{
          marginTop: "30px",
          width: "60%",
          minWidth: "300px",
          borderRadius: "20px",
        }}
      >
        {htmlConverterReact(blog.description)}
      </div>
    </div>
  </div>
  <div className="flex justify-center mx-auto mt-4">
    {loading ? (
      <ThreeDots
        type="Oval"
        position="top-center"
        className="d-flex justify-center"
        color="rgb(0,159,227)"
        height={50}
        width={50}
      />
    ) : (
      ""
    )}
  </div>
</>
  )
}

export default BlogIndi

{/* <>
      <div>
        <div
          style={{
            width: "100%",
            // height: "60px",
            paddingTop:'10px',
            paddingBottom:'10px',
            background: "#009fe3",
            display: "flex",
            alignItems: "center",
            marginTop: "75px",
          }}
        >
          <span
            style={{
              color: "white",
              fontWeight: "bold",
              // marginLeft: "10%",
              fontSize: "1.5rem",
            }}
            className="text-center  flex justify-center mx-auto"
            // className='text-center'
          >
           {blog.name}
          </span>
        </div>
      </div>
      <div className="profit-main " >
        <div className="profit-second pb-5">
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <img
              src={blog.screenshot}
              className='mb-10'
              alt=""
              style={{ marginTop: "30px", width: "60%",minWidth:'300px',borderRadius:'20px' }}
            />
           </div>
           <div className="flex justify-center space-x-4" style={{marginBottom:'40px'}}>
  <a
    href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}&quote=${encodeURIComponent(blog.name)}`}
    target="_blank"
    rel="noopener noreferrer"
    style={{ flexWrap: 'nowrap' }}
    className="flex items-center px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
  >
    <RiFacebookBoxFill className="mr-2" /> Share on Facebook
  </a>
  <a
    href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent(blog.name)}`}
    target="_blank"
    rel="noopener noreferrer"
    style={{ flexWrap: 'nowrap' }}
    className="flex items-center px-4 py-2 bg-blue-400 text-white rounded hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-300"
  >
    <FaXTwitter className="mr-2" /> Tweet on Twitter
  </a>
  <a
 href={`https://www.linkedin.com/feed/?shareActive=true&text=${encodeURIComponent(blog.name + ' ' + window.location.href)}`}
    target="_blank"
    rel="noopener noreferrer"
    style={{ flexWrap: 'nowrap' }}
    className="flex items-center px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
  >
    <FaLinkedin className="mr-2" /> Post on LinkedIn
  </a>
</div>
        <div className=' mx-auto ' style={{ marginTop: "30px", width: "60%",minWidth:'300px',borderRadius:'20px' }}>
 {htmlConverterReact(blog.description)}
 </div>
        </div>
      </div>
      <div className=' flex justify-center mx-auto mt-4'>
         {loading ? (
          // <div>hello</div>
            <ThreeDots type="Oval" position="top-center" className='d-flex justify-center' color="rgb(0,159,227)" height={50} width={50} />
           
          ) : (
            ''
          )}
          </div>
    </> */}