// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Teko);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.first-Dynamic{
    width: 60%;
}
.second-Dynamic{
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* margin-top: 6vw; */
}
.second-button {
    margin-top: 40px;
    padding: 15px 30px;
    border: 1px solid black;
    border-radius: 4px;
    cursor: pointer;
}

.dynamicheadwidth{
    width:60%;
    line-height:normal;
}
.dynimg{
    border-radius: 30px;
     width: 80%;
      height: 80%
}
@media(max-width:1364px){
    .dynamicheadwidth{
        width:80%
    }  
}
@media(max-width:800px){
    .first-Dynamic{
        width: 100%;
    }
    .second-Dynamic{
        width: 100%;
        display: flex;
        flex-direction: column;
           }
    .dynimg{
            border-radius: 30px;
             width: 100%;
              height: 100%
        }
}
`, "",{"version":3,"sources":["webpack://./src/Aboutus.js/Dynamic.css"],"names":[],"mappings":"AAEA;IACI,UAAU;AACd;AACA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,qBAAqB;AACzB;AACA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,uBAAuB;IACvB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,SAAS;IACT,kBAAkB;AACtB;AACA;IACI,mBAAmB;KAClB,UAAU;MACT;AACN;AACA;IACI;QACI;IACJ;AACJ;AACA;IACI;QACI,WAAW;IACf;IACA;QACI,WAAW;QACX,aAAa;QACb,sBAAsB;WACnB;IACP;YACQ,mBAAmB;aAClB,WAAW;cACV;QACN;AACR","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Teko');\n\n.first-Dynamic{\n    width: 60%;\n}\n.second-Dynamic{\n    width: 40%;\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    /* margin-top: 6vw; */\n}\n.second-button {\n    margin-top: 40px;\n    padding: 15px 30px;\n    border: 1px solid black;\n    border-radius: 4px;\n    cursor: pointer;\n}\n\n.dynamicheadwidth{\n    width:60%;\n    line-height:normal;\n}\n.dynimg{\n    border-radius: 30px;\n     width: 80%;\n      height: 80%\n}\n@media(max-width:1364px){\n    .dynamicheadwidth{\n        width:80%\n    }  \n}\n@media(max-width:800px){\n    .first-Dynamic{\n        width: 100%;\n    }\n    .second-Dynamic{\n        width: 100%;\n        display: flex;\n        flex-direction: column;\n           }\n    .dynimg{\n            border-radius: 30px;\n             width: 100%;\n              height: 100%\n        }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
