import React, { useState,useEffect } from "react";
import axios from "axios";
import { FaTools } from "react-icons/fa";
import Footer from "../Home/Footer";
import {toast} from 'react-toastify'
import {ThreeDots} from 'react-loader-spinner';

const Addtool = () => {

  const[loading,setLoading]= useState(false)

  useEffect(() => {
    // Scroll to the top when the component mounts (page loads)
    window.scrollTo(0, 0);
  }, []);

  const [toolName, setToolName] = useState("");
  const [toolLogo, setToolLogo] = useState(null);
  const [screenshot, setScreenshot] = useState(null);
  const [toolTags, setToolTags] = useState("");
  const [toolTag, setToolTag] = useState("");
  const [toolDescription, setToolDescription] = useState("");
  const [toolLink, setToolLink] = useState("");

  const convertTobase64 = (e) => {
    console.log(e);
    let render = new FileReader();
    render.readAsDataURL(e.target.files[0]);
    render.onload = () => {
      console.log(render.result);
      setToolLogo(render.result);
    };
    render.onerror = (error) => {
      console.log("error", error);
    };
  };

  const convertTobase = (e) => {
    console.log(e);
    let render = new FileReader();
    render.readAsDataURL(e.target.files[0]);
    render.onload = () => {
      console.log(render.result);
      setScreenshot(render.result);
    };
    render.onerror = (error) => {
      console.log("error", error);
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)

    // Handle form submission logic here
    // You can use the form data (toolName, toolLogo, etc.) as needed
    console.log("Form submitted:", {
      toolName,
      toolLogo,
      screenshot,
      toolTags,
      toolTag,
      toolDescription,
      toolLink,
    });
    const data = await axios
      .post(
        `${process.env.REACT_APP_URL}/api/addtool`,
        { toolName, toolLogo, screenshot, toolTags,toolTag, toolDescription, toolLink },
        {
          "Content-type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        }
      )
      .then((res) => {console.log(res.data);toast.success("Added Successfully", {
        position: toast.POSITION.TOP_CENTER,
      });setLoading(false)})
      .catch((err) => {
        console.log("err in api", err);setLoading(false)
      });
    setToolName("");
    setToolLogo(null);
    setToolTags("");
    setToolTag("");
    setScreenshot(null);
    setToolDescription("");
    setToolLink("");
    setTimeout(() => {
      window.location.reload()
    }, 2000);
  };

  return (
    <>
    <div style={{backgroundColor:'#89C8FF', minHeight:'80vh', maxHeight:'100%'}} >

<div>
      <div
        style={{
          width: "100%",
          height: "60px",
          background: "#009fe3",
          display: "flex",
          alignItems: "center",
          marginTop: "75px",
          justifyContent: "center",
        }}
      >
        <span
          style={{
            color: "white",
            fontWeight: "bold",

            fontSize: "1.5rem",
          }}
        >
          Add Tool
        </span>
      </div>
    </div>
<div className="mx-3 py-5">
    <div style={{backgroundColor:'whitesmoke'}}  className="max-w-2xl mx-auto p-6  rounded-md shadow-md  mt-20 grid gap-4 ">
      <div className="col-span-2 text-center">
        {/* <h2 className="text-2xl font-semibold mb-4">Add Tool</h2> */}
        <FaTools  className=" mx-auto mb-4 text-7xl"/>
      </div>
      <form className="col-span-2" onSubmit={handleSubmit}>
        <div className="flex flex-wrap -mx-2">
          <div className="mb-4 px-2 w-full md:w-1/2">
            <label
              htmlFor="toolName"
              className="block text-sm font-medium text-gray-600"
            >
              Tool Name<span style={{color:'red'}}> *</span>
            </label>
            <input
              type="text"
              id="toolName"
              value={toolName}
              onChange={(e) => setToolName(e.target.value)}
              className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-indigo-300"
              required
            />
          </div>

          <div className="mb-4 px-2 w-full md:w-1/2">
            <label
              htmlFor="toolTags"
              className="block text-sm font-medium text-gray-600"
            >
              Tool Tag (comma-separated)<span style={{color:'red'}}> *</span>
            </label>
            <input
              type="text"
              id="toolTags"
              value={toolTags}
              onChange={(e) => setToolTags(e.target.value)}
              className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-indigo-300"
              required
            />
          </div>

          <div className="mb-4 px-2 w-full md:w-1/2">
            <label
              htmlFor="toolLogo"
              className="block text-sm font-medium text-gray-600"
            >
              Tool Logo<span style={{color:'red'}}> *</span>
            </label>
            <input
              type="file"
              id="toolLogo"
              accept="image/*"
             
              onChange={convertTobase64}
              className="mt-1 p-2 bg-white w-full border rounded-md focus:outline-none focus:ring focus:border-indigo-300"
              required
            />
          </div>

          <div className="mb-4 px-2 w-full md:w-1/2">
            <label
              htmlFor="screenshot"
              className="block text-sm font-medium text-gray-600"
            >
              Website Screenshot<span style={{color:'red'}}> *</span>
            </label>
            <input
              type="file"
              id="screenshot"
              accept="image/*"
              onChange={convertTobase}
              className="mt-1 p-2 bg-white w-full border rounded-md focus:outline-none focus:ring focus:border-indigo-300"
              required
            />
          </div>
        </div>

        <div className="mb-4 col-span-2">
          <label
            htmlFor="toolLink"
            className="block text-sm font-medium text-gray-600"
          >
            Tool Link<span style={{color:'red'}}> *</span>
          </label>
          <input
            type="url"
            id="toolLink"
            value={toolLink}
            onChange={(e) => setToolLink(e.target.value)}
            className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-indigo-300"
            required
          />
        </div>

        <div className="mb-4 col-span-2">
          <label
            htmlFor="toolLink"
            className="block text-sm font-medium text-gray-600"
          >
            Tool Hashtag (comma-separated)<span style={{color:'red'}}> *</span>
          </label>
          <input
            type="text"
            id="toolTag"
            value={toolTag}
            onChange={(e) => setToolTag(e.target.value)}
            className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-indigo-300"
            required
          />
        </div>

        <div className="mb-4 col-span-2">
          <label
            htmlFor="toolDescription"
            className="block text-sm font-medium text-gray-600"
          >
            Tool Description<span style={{color:'red'}}> *</span>
          </label>
          <textarea
            id="toolDescription"
            value={toolDescription}
            onChange={(e) => setToolDescription(e.target.value)}
            className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-indigo-300"
            rows="4"
            required
          ></textarea>
        </div>

        <div className="col-span-2 flex justify-center">
          <button
            type="submit"
            style={{backgroundColor:'blue'}}
            className=" bg-indigo-600 text-white p-3 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring focus:border-indigo-300 border-none"
          >
            Submit
          </button>
        </div>
        <div className=' flex justify-center mx-auto mt-4'>
         {loading ? (
          // <div>hello</div>
            <ThreeDots type="Oval" position="top-center" className='d-flex justify-center' color="rgb(0,159,227)" height={50} width={50} />
           
          ) : (
            ''
          )}
          </div>
      </form>
    </div>
    </div>
    </div>
    <Footer/>
    </>
  );
};

export default Addtool;
