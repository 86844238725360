// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    position: relative;
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: 'Lato', sans-serif;
}

.wrapper{
    border: 1px solid grey;
  }
  
  .editor{
    background-color: lavender;
  }`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;IACI,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,SAAS;IACT,+BAA+B;AACnC;;AAEA;IACI,sBAAsB;EACxB;;EAEA;IACE,0BAA0B;EAC5B","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');\n\n* {\n    position: relative;\n    box-sizing: border-box;\n}\n\nbody {\n    margin: 0;\n    font-family: 'Lato', sans-serif;\n}\n\n.wrapper{\n    border: 1px solid grey;\n  }\n  \n  .editor{\n    background-color: lavender;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
