import React, { useState, useEffect } from "react";
import "./Second.css";
import Line from "../Images/AIVO line.png";
import First from "../Images/aivo ds.png";
import Image from "../Images/hr.png";
import { Link } from "react-router-dom";
import Chatbot from "./Chatbot";

const Second = () => {
  const [showChatbot, setShowChatbot] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Adjust the offset and condition based on your design
      const offset = 1000;
      const currentScrollPos = window.scrollY;

      if (currentScrollPos > offset) {
        setShowChatbot(true);
      } else {
        setShowChatbot(false);
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="main-container-2" style={{ marginTop: "50px" }}>
      <h1
        style={{ marginBottom: "20px", textAlign: "center", marginTop: "0px", fontWeight:'900' }}
      >
        About AI Virtual Ops
      </h1>
      <div className="container-2" style={{marginBottom:'50px'}}>
        {/* right */}
        <div className="right-2">
          <h2
            className="main-2"
            style={{
              fontSize: "1.5rem",
              // textAlign: "justify",
              
              textJustify: "inter-word",
              marginTop: "0px",
            }}
          >
            AI Virtual Ops streamlines the hiring process for proficient and knowledgeable virtual assistants, providing comprehensive training and overseeing all employment-related tasks, thus enabling you to dedicate more time to scaling your business
          </h2>
          {/* <img src={Line} alt="" width="300" style={{ marginBottom: "30px" }} /> */}
          <div className="mx-1" style={{}}>
            Your Success, Our Selection: AI Virtual Ops Picks the Finest Virtual
            Assistants
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", width: "75%" }}
          >
            <Link to="/price">
              <button
                // style={{
                //   padding: "20px",
                //   background: "orange",
                //   cursor: "pointer",
                //   border: "none",
                //   borderRadius: "30px",
                // }}
                className="learn-more"
                style={{transition: "color 0.3s, transform 0.3s" }}
                onMouseEnter={(e) => {
                  e.target.style.color = "black";
                  e.target.style.transform = "scale(1.05)"; // Increase size by 5%
                }}
                onMouseLeave={(e) => {
                  e.target.style.color = "white";
                  e.target.style.transform = "scale(1)"; // Return to normal size
                }}
              >
                Hire your Operations Assistant now!
              </button>
            </Link>
          </div>
        </div>
        <div className="left-2 mb-10">
          <div className="image-con">
            <img src={First} alt="Flippable Image" className="imgflipw"  />
            <img src={Image} alt="Flipped Image" className="imgflipw"  />
          </div>
          {showChatbot && <Chatbot />}
        </div>
      </div>
    </div>
  );
};

export default Second;
