import React from "react";
import "./Maincontent.css";
import Line from "../Images/AIVO line.png";

const Maincontent = () => {
  return (
    <div className="profit-main">
      <div className="profit-second">
        <h1 style={{ textAlign: "center", fontSize: "3rem" }}>
          Human-centered AI Operations
        </h1>
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <img src={Line} alt="" className="image-line" />
        </div>
        <div style={{ marginTop: "50px", fontSize: "19px" }}>
          <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
            AI Virtual Ops is an employee-owned startup that that incorporates
            AI augmenta﻿tion in business operations to accelerate workflows and
            optimize efficiency.
          </p>
          <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
            Established in 2023, AI Virtual Ops is a nascent, employee-owned
            remote operations agency. We leverage the power of AI while
            employing proficient operations assistants who are passionate about
            helping our clients advance their businesses and organizations to
            the next level.
          </p>
          <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
            At AI Virtual Ops, we understand that remote work can be
            challenging, especially for businesses that are new to the concept.
            That's why we offer a range of services to help our clients navigate
            the remote work landscape with ease. From virtual administrative
            support to social media management, we have a team of experts who
            are well-equipped to handle any task.
          </p>
          <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
            As a remote agency, we pride ourselves on our ability to provide
            flexible solutions that meet the needs of our clients. Whether you
            need ongoing support or a one-time project, we're here to help. With
            AI Virtual Ops, you can trust that you're in good hands.
          </p>
          <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
            <strong>
              We are an employee-owned startup specializing in AI operations,
              dedicated to developing innovative solutions that help businesses
              optimize their operations and achieve their goals. Our team of
              experts stays at the forefront of the rapidly-evolving field of AI
              to help clients unlock new opportunities and succeed in a
              competitive landscape. Contact us to learn more about our
              solutions for businesses of all sizes.
            </strong>
          </p>
        </div>
        <div style={{ height: "70px" }}></div>
      </div>
    </div>
  );
};

export default Maincontent;
