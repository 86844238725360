import React from "react";
import "./First.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import No from "../Images/mg-bgr.png";
import Cn from "../Images/cn-bgr.png";
import Fr from "../Images/fr-bgr.png";
import Logo from "../Images/aivops logo.png";

const First = () => {
  return (
    <div
      className="profit-main"
      style={{
        marginTop: "0px",
        flexDirection: "column",
        alignItems: "center",
        background: "#89c8ff",
        paddingBottom: "30px",
      }}
    >
      <h1
        style={{
          textAlign: "center",
          marginTop: "0px",
          marginBottom: "40px",
          paddingTop: "40px",
        }}
      >
        How We Are Different
      </h1>
      <div
        className="profit-second first-main-con"
        // style={{ display: "flex", flexDirection: "column", gap: "20px" }}
      >
        <div className="card">
          {/* <FontAwesomeIcon icon={faMoneyBill} className="icon" /> */}
          <img src={No} className="mx-auto" alt="" width={50} />
          <h2 style={{ textAlign: "center" }}>No Hidden Fees</h2>
          <p
            style={{
              textAlign: "justify",
              textJustify: "inter-word",
            }}
          >
            You'll only be charged for the hours you use, with no additional
            fees for membership or any other unnecessary charges.
          </p>
        </div>
        <div className="card">
          <img src={Cn} className="mx-auto" alt="" width={50} />
          <h2 style={{ textAlign: "center" }}>Cancel Anytime</h2>
          <p
            style={{
              textAlign: "justify",
              textJustify: "inter-word",
            }}
          >
            We prioritize flexibility. You can cancel at your convenience by
            refraining from buying more hours. There are no long-term
            commitments or obligations.
          </p>
        </div>
        <div className="card">
          <img src={Fr} className="mx-auto" alt="" width={50} />
          <h2 style={{ textAlign: "center" }}>Full refund guarantee</h2>
          <p
            style={{
              textAlign: "justify",
              textJustify: "inter-word",
            }}
          >
            Our clients are delighted with our proficient Virtual Assistants,
            quick response times, and the efficiency of our workflow. Yet,
            should you not be thoroughly impressed for any reason, we'll gladly
            refund your unused hours.
          </p>
        </div>
      </div>
    </div>
  );
};

export default First;
