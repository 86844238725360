// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-3 {
    width: 100%;
    display: flex;
    height: auto;
    flex-direction: column;
    /* background-color: #f9f9f9; */
    padding-top: 50px;
}

.left-3 {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.right-3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.right-5 {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-right: 5%;
}

.main-3 {
    font-size: 2.5rem;
    line-height: 46px;
    letter-spacing: 0;
    font-weight: 700;
}

@media screen and (max-width: 1000px) {
    .container-3 {
        flex-direction: column;
        padding: 40px 0;
    }

    .left-3 {
        width: 100%;
        margin-top: 30px;
    }

    .right-5 {
        width: 100%;
        align-items: center;
        padding-right: 0;
    }

    .main-3 {
        text-align: center;
        padding: 0 20px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Home/Third.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,YAAY;IACZ,sBAAsB;IACtB,+BAA+B;IAC/B,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,uBAAuB;IACvB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI;QACI,sBAAsB;QACtB,eAAe;IACnB;;IAEA;QACI,WAAW;QACX,gBAAgB;IACpB;;IAEA;QACI,WAAW;QACX,mBAAmB;QACnB,gBAAgB;IACpB;;IAEA;QACI,kBAAkB;QAClB,eAAe;IACnB;AACJ","sourcesContent":[".container-3 {\n    width: 100%;\n    display: flex;\n    height: auto;\n    flex-direction: column;\n    /* background-color: #f9f9f9; */\n    padding-top: 50px;\n}\n\n.left-3 {\n    width: 50%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.right-3 {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n.right-5 {\n    width: 50%;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    justify-content: center;\n    padding-right: 5%;\n}\n\n.main-3 {\n    font-size: 2.5rem;\n    line-height: 46px;\n    letter-spacing: 0;\n    font-weight: 700;\n}\n\n@media screen and (max-width: 1000px) {\n    .container-3 {\n        flex-direction: column;\n        padding: 40px 0;\n    }\n\n    .left-3 {\n        width: 100%;\n        margin-top: 30px;\n    }\n\n    .right-5 {\n        width: 100%;\n        align-items: center;\n        padding-right: 0;\n    }\n\n    .main-3 {\n        text-align: center;\n        padding: 0 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
