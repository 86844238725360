import React from "react";
import Line from "../Images/AIVO line.png";

const Maincontent = () => {
  return (
    <div className="profit-main">
      <div className="profit-second mt-10">
        <h1 style={{ textAlign: "center", fontSize: "3rem", color: "#009fe3" }}>
          Facts. Ideas. Tech.
        </h1>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {/* <img src={Line} alt="" style={{ width: "35%" }} /> */}
        </div>
        <div className="mb-5" style={{ marginTop: "50px", fontSize: "19px" }}>
          <p className="text-center">
            At AI Virtual Ops, our commitment lies in imparting knowledge to
            enhance your business and productivity. Should you require
            assistance, allow us to free your time, enabling you to think
            alternative strategies for business growth. The AI Virtual Ops Team
            stands ready to manage all your needs effectively.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Maincontent;
