import React, { useEffect } from "react";
import BackgroundImage from "./BackgroungImage";
import Feature from "./Feature";
import First from "./First";
import Five from "./Five";
import Footer from "./Footer";
import Four from "./Four";
import Second from "./Second";
import Seven from "./Seven";
import Six from "./Six";
import Third from "./Third";
import Whatwedo from "./Whatwedo";
import Whoweserve from "./Whoweserve";
import NewBanner from "./NewBanner";

const Home = () => {
  useEffect(() => {
    // Scroll to the top when the component mounts (page loads)
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <NewBanner/>
      {/* <BackgroundImage /> */}

      <Whatwedo />
      <First />
      <Second />

      <Four />
      <Whoweserve />
      <Five />
      <Six />
      <Feature />
      {/* <Seven /> */}
      <Footer />
    </div>
  );
};

export default Home;
