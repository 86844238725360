import React from "react";
import "./BackgroundImage.css";
import Video from "../Images/Homepage_1.mp4";
import Logo from "../Images/aivops logo.png"; // Import your CSS file for styling
import image from '../Images/aivowebsite.png'

const BackgroundImage = () => {
  return (
    <div className="background-container" style={{ marginTop: "75px" }}>
      {/* <div className="background-video"> */}
        <video autoPlay muted loop>
          <source src={Video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
{/* <img src={image} style={{ width:'100%', height:'100%' }}/> */}
        <div className="overlay">
          <div className="content-container">
            <div className="center-content">
              {/* <h1
                style={{
                  lineHeight: 1.2,
                  textAlign: "center",
                  fontWeight: 700,
                }}
                className="h1-home"
              >
                AI Virtual Ops
              </h1> */}
            </div>
            {/* <hr
              style={{
                width: "50%",
                height: "10px",
                color: "white",
                background: "white",
                marginTop: "-50px",
              }}
            /> */}

            <div className="bottom-content">
              {/* <h2
                style={{
                  fontSize: "2rem",
                  lineHeight: 1.5,
                  fontWeight: 600,
                  textAlign: "center",
                }}
                className="mx-1"
              >
                Elevating Businesses with AI-Powered Virtual Assistance – Where
                Success Takes Flight
              </h2> */}
            </div>
          </div>
        </div>
      </div>
    // </div>
  );
};

export default BackgroundImage;
