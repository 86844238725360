import React from "react";
import Line from "../Images/AIVO line.png";
import { Link } from "react-router-dom";

const Maincontent = () => {
  return (
    <div
      style={{
        marginTop: "50px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "50px",
      }}
    >
      <h1 className="my-3" style={{ textAlign: "center", fontSize: "3rem", lineHeight:'4rem' }}>
        Tailored Pricing and Packages to Suit Your Needs
      </h1>
      {/* <img src={Line} alt="" style={{ width: "35%" }} /> */}
      <h3 className="my-5 mx-2" style={{ textAlign: "center" }}>
        Try our services with a zero-risk, 30-day free trial, no strings
        attached!
      </h3>
     
        <Link
          to="/Contactus"
          style={{ textDecoration: "none"}}
        >
           <button
        className="learn-more mx-2"
        style={{ marginTop: "0px",
        transition: "color 0.3s, transform 0.3s", }}
        onMouseEnter={(e) => {
          e.target.style.color = "black";
          e.target.style.transform = "scale(1.05)"; // Increase size by 5%
        }}
        onMouseLeave={(e) => {
          e.target.style.color = "white";
          e.target.style.transform = "scale(1)"; // Return to normal size
        }}
      >
          Get your AI Virtual Ops Virtual Assistant now!
          </button>
        </Link>
  
    </div>
  );
};

export default Maincontent;
