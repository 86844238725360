// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profit-main{
    width: 100%;
    display: flex;
    justify-content: center;
}
.profit-second{
    width: 80%;
}
.box-font{
    font-size: 44px;
    line-height: 40px;
    font-weight: 700;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/Price/Profit.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,UAAU;AACd;AACA;IACI,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".profit-main{\n    width: 100%;\n    display: flex;\n    justify-content: center;\n}\n.profit-second{\n    width: 80%;\n}\n.box-font{\n    font-size: 44px;\n    line-height: 40px;\n    font-weight: 700;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
