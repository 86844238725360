import React from "react";
import "./Form.css";

const Form = () => {
  return (
    <div className="profit-main" style={{ marginTop: "50px" }}>
      <div className="profit-second">
        <h1>
          Test the Rellify Article Intelligence app now! 30 Days. No Risk. No
          Charge.
        </h1>
        <div>
          <div className="input-container">
            <label htmlFor="">
              Company Name<span style={{ color: "red" }}>*</span>
            </label>
            <input type="text" className="input-style" />
          </div>
          <div className="second-inputs">
            <div className="input-container input-width">
              <label htmlFor="">
                First Name<span style={{ color: "red" }}>*</span>
              </label>
              <input type="text" className="input-style" />
            </div>
            <div className="input-container input-width">
              <label htmlFor="">
                Last Name<span style={{ color: "red" }}>*</span>
              </label>
              <input type="text" className="input-style" />
            </div>
          </div>
          <div className="input-container" style={{ marginTop: "30px" }}>
            <label htmlFor="">
              Email Address<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              className="input-style"
              placeholder="Your Email Address"
            />
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              //   alignItems: "flex-start",
              //   justifyContent: "space-between",
            }}
          >
            <input type="checkbox" />
            <p htmlFor="subscribe" style={{ color: "#999", marginLeft: "5px" }}>
              I subscribe to the Rellify newsletter, have read and agree to the
              privacy policy and Terms of Service of Rellify, Inc.
              <span style={{ color: "red" }}>*</span>
            </p>
          </div>
          <p className="buy-now-form">
            <a className="buy-link-form">SUBMIT</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Form;
