import React from "react";
import "./MainContent.css";
import Yarnit from "../Images/YarnIt_Page.png";
import Yarnlogo from "../Images/oqvrck3rt6o0pflvplwu.webp";

const MainContent = () => {
  return (
    <div className="profit-main " style={{ marginBottom: "30px" }} >
      <div className="main-tool-box profit-second">
        <div className="" style={{ marginTop: "30px" }}>
          <div
            style={{
              marginBottom: "30px",
              fontWeight: "bold",
              fontSize: "3rem",
              textAlign: "center",
            }}
          >
            AI Tool of the Month
          </div>
          <div className="main-box-tool">
            <div className="main-box-tool-one">
              <div
                style={{
                  marginBottom: "30px",
                }}
              >
                <img src={Yarnlogo} alt="" style={{ width: "90%" }} />
              </div>
              <div className="box-tags" style={{ marginBottom: "30px" }}>
                <span className="box-tag">Generative AI</span>
              </div>
              <p
                style={{
                 
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
                className='text-yarnit-width'
              >
                Discover the power of YarnIt through shaping the future of
                content creation! Leveraging cutting-edge AI tools, YarnIt
                brings unparalleled precision to crafting engaging narratives,
                streamlining workflows, and unlocking creative potential.
              </p>
            </div>
            <div className="main-box-tool-second">
              <a
                href="https://yarnit.live/?gr_pk=Gn28&gr_uid=zvxE"
                target="_blank"
              >
                <img
                  src={Yarnit}
                  alt=""
                  height="80%"
                  width="100%"
                  style={{ boxShadow: "0 10px 10px rgba(0, 0, 0, 0.5)" }}
                />
              </a>
            </div>
          </div>
        </div>
        {/* <div style={{ marginTop: "30px" }}>
        <div style={{ marginBottom: "30px" }}>
          Featured Tool:{" "}
          <span style={{ fontWeight: "bold" }}>Spikes Studio</span>
        </div>
        <img
          src="https://assets.aitoolsdirectory.com/file/aitools/spikes-studio-viral-video-clip-maker.jpg"
          alt=""
          height="210px"
          width="95%"
          style={{ boxShadow: "0 10px 10px rgba(0, 0, 0, 0.5)" }}
        />
      </div> */}
      </div>
    </div>
  );
};

export default MainContent;
