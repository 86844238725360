// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1, h2, h3, h4, h5, h6{
    margin-top:30px;
    margin-bottom:20px;
}`, "",{"version":3,"sources":["webpack://./src/Blog/BlogIndi.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;AACtB","sourcesContent":["h1, h2, h3, h4, h5, h6{\n    margin-top:30px;\n    margin-bottom:20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
