// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-tool-box{
    display: flex;
    justify-content: center;
}
.main-box-tool{
    display: flex;
    margin-bottom: 60px;
}
.main-box-tool-one{
    width: 40%;
}
.main-box-tool-second{
    width: 60%;
}
.text-yarnit-width{
   width:80%;
}
@media(max-width:800px){
    .text-yarnit-width{
        width:100%;
     }
    .main-tool-box{
        flex-direction: column;
    }
    .main-box-tool{
        flex-direction: column;
    }
    .main-box-tool-one{
        width: 100%;
    }
    .main-box-tool-second{
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/Toolpage/MainContent.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,UAAU;AACd;AACA;IACI,UAAU;AACd;AACA;GACG,SAAS;AACZ;AACA;IACI;QACI,UAAU;KACb;IAGD;QACI,sBAAsB;IAC1B;IACA;QACI,sBAAsB;IAC1B;IACA;QACI,WAAW;IACf;IACA;QACI,WAAW;IACf;AAbJ","sourcesContent":[".main-tool-box{\n    display: flex;\n    justify-content: center;\n}\n.main-box-tool{\n    display: flex;\n    margin-bottom: 60px;\n}\n.main-box-tool-one{\n    width: 40%;\n}\n.main-box-tool-second{\n    width: 60%;\n}\n.text-yarnit-width{\n   width:80%;\n}\n@media(max-width:800px){\n    .text-yarnit-width{\n        width:100%;\n     }\n}\n@media (max-width:800px) {\n    .main-tool-box{\n        flex-direction: column;\n    }\n    .main-box-tool{\n        flex-direction: column;\n    }\n    .main-box-tool-one{\n        width: 100%;\n    }\n    .main-box-tool-second{\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
