import React from "react";
import "./Mission.css";
import Image from "../Images/aivo_websiteaivv.png";
import { Link } from "react-router-dom";

const Missinos = () => {
  return (
    <div className="profit-main">
      <div className="mission-main" style={{ marginTop: "4vw" }}>
        <div className="mission-first">
          <h1 style={{ width: "90%" }}>
            AI Virtual Ops was built for AI First organizations of the future.
          </h1>
          <div className="mission-div">
            <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
              We elevate business teams with operational, support and
              administrative resources to cover the transitions of their
              businesses operations from "Digital First" in 2024 to "AI First"
              by 2025.
            </p>
            <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
              Our AI Virtual Ops Team is trained and ready for you to take your
              business further. AIVO believes that with Generative AI and
              Machine Learning and Large Language Models, a lot of the
              semi-specialized work can be better handled by our trained
              resources.
            </p>
            <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
              This includes functions like business support, Technical Support,
              Marketing Operations, Sales Operations, Medial Office front office
              and scheduling, Real Estate industry operations, Hospital and
              Payor Operations, Customer Success, Business Process Operations,
              L1-l2 Support.
            </p>
            <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
              We cater to any industry or domain that can grow and stabilize
              with an additional helping hand from AIVO associates.{" "}
            </p>

            <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <button className="learn-more"
             style={{transition: "color 0.3s, transform 0.3s" }}
             onMouseEnter={(e) => {
               e.target.style.color = "black";
               e.target.style.transform = "scale(1.05)"; // Increase size by 5%
             }}
             onMouseLeave={(e) => {
               e.target.style.color = "white";
               e.target.style.transform = "scale(1)"; // Return to normal size
             }}
            ><Link to ='/contactus'>Contact Us</Link></button>
          </div>
          </div>
         
        </div>
        <div
          className="mission-second"
          style={{ display: "flex", alignItems: "center" }}
        >
          <img src={Image} alt="" style={{ borderRadius: "20px" }} />
        </div>
      </div>
    </div>
  );
};

export default Missinos;
