import React from "react";
import Header from "./Header";
import MainContent from "./MainContent";
import Search from "./Search";
import Dropdown from "./Dropdown";
import MainBody from "./MainBody";

const Toolpage = () => {
  return (
    <div style={{maxWidth:'100vw'}}>
      <Header />
      <MainContent />
      {/* <Search /> */}
      {/* <Dropdown /> */}
      <MainBody />
    </div>
  );
};

export default Toolpage;
