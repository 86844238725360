import React from "react";
import Line from "../Images/AIVO line.png";
import "./Five.css";
import { Link } from "react-router-dom";
import Blog from "../Images/blog-1.png";

const Five = () => {
  return (
    <div className="container-3" style={{ paddingTop: "0px" }}>
      <h2
        // style={{
        //   fontWeight: "bold",
        //   fontSize: "4rem",
        //   textAlign: "center",
        //   marginBottom: "0px",
        // }}
        style={{ textAlign: "center", marginBottom: "30px" }}
        className="main-3"
      >
        Blog
      </h2>
      <div className="five-blog lg:mx-auto ">
        <div className="mx-2">
          <img
            src={Blog}
            // width="555"
            // height="312"
            style={{ width: "100%", height: "80%", borderRadius: "20px" }}
            className="mx-auto"
          />
        </div>
        {/* right */}
        <div className="mx-3">
          <h2
            className="main-3"
            style={{
              width: "90%",
            }}
          >
            Human Virtual Assistants: Transforming AI Cloud Computing for a
            Smarter Tomorrow
          </h2>
          {/* <img src={Line} alt="" style={{ width: "50%" }} /> */}
          <div
            style={{
              marginTop: "10px",
              fontSize: "20px",
              marginBottom: "10px",
              marginLeft:'10px',
              width: "90%",
              textAlign: "justify",
              textJustify: "inter-word",
            }}
            className="div-short"
          >
            In an era dominated by technological advancements, the fusion of
            human-like interaction and artificial intelligence (AI) has paved
            the way for a fascinating new chapter in the world of cloud
            computing. Human virtual assistants, often powered by sophisticated
            AI algorithms, have become indispensable tools for businesses and
            individuals alike
          </div>
          <div className="w-100  " style={{ marginLeft:'10px',fontWeight: "bold", marginBottom: "30px" }}>
     
     <Link
       style={{ textDecoration: "none", color: "white" }}
       to="/blog/firstblog"
       className=""
     >
              <button className="learn-more mx-auto "    
     style={{transition: "color 0.3s, transform 0.3s" }}
       onMouseEnter={(e) => {
         e.target.style.color = "black";
         e.target.style.transform = "scale(1.05)"; // Increase size by 5%
       }}
       onMouseLeave={(e) => {
         e.target.style.color = "white";
         e.target.style.transform = "scale(1)"; // Return to normal size
       }}>
       Read More
       </button>
     </Link>
   
 </div>
        </div>
    
      </div>
     
    </div>
  );
};

export default Five;
