import React, { useState, useEffect, useRef } from "react";
import './Skill.css'

const Skill = () => {
  const [percentage, setPercentage] = useState(0);
  const [first, setfirst] = useState(0);
  const [third, setthird] = useState(0);
  const [animationthird, setanimationthird] = useState(false);
  const [animationHasRun, setAnimationHasRun] = useState(false);
  const [animationfirst, setanimation] = useState(false);
  const animatedRef = useRef(null);
  const animated = useRef(null);
  const animate = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !animationHasRun) {
          // Run the animation when the component becomes visible
          animateFill();
          setAnimationHasRun(true);
          observer.unobserve(animatedRef.current);
        }
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    if (animatedRef.current) {
      observer.observe(animatedRef.current);
    }

    return () => {
      if (animatedRef.current) {
        observer.unobserve(animatedRef.current);
      }
    };
  }, [animationHasRun]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !animationfirst) {
          // Run the animation when the component becomes visible
          animateFirst();
          setanimation(true);
          observer.unobserve(animated.current);
        }
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    if (animated.current) {
      observer.observe(animated.current);
    }

    return () => {
      if (animated.current) {
        observer.unobserve(animated.current);
      }
    };
  }, [animationfirst]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !animationthird) {
          // Run the animation when the component becomes visible
          animatethird();
          setanimationthird(true);
          observer.unobserve(animate.current);
        }
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    if (animate.current) {
      observer.observe(animate.current);
    }

    return () => {
      if (animate.current) {
        observer.unobserve(animate.current);
      }
    };
  }, [animationthird]);

  const animateFill = () => {
    const animationDuration = 2000;
    const startTime = Date.now();

    const animationFrame = () => {
      const currentTime = Date.now();
      const elapsed = currentTime - startTime;

      if (elapsed < animationDuration) {
        const newPercentage = (elapsed / animationDuration) * 80;
        setPercentage(newPercentage);
        requestAnimationFrame(animationFrame);
      } else {
        setPercentage(80);
      }
    };

    animationFrame();
  };
  const animateFirst = () => {
    const animationDuration = 2000;
    const startTime = Date.now();

    const animationFrame = () => {
      const currentTime = Date.now();
      const elapsed = currentTime - startTime;

      if (elapsed < animationDuration) {
        const newPercentage = (elapsed / animationDuration) * 75;
        setfirst(newPercentage);
        requestAnimationFrame(animationFrame);
      } else {
        setfirst(75);
      }
    };

    animationFrame();
  };
  const animatethird = () => {
    const animationDuration = 2000;
    const startTime = Date.now();

    const animationFrame = () => {
      const currentTime = Date.now();
      const elapsed = currentTime - startTime;

      if (elapsed < animationDuration) {
        const newPercentage = (elapsed / animationDuration) * 90;
        setthird(newPercentage);
        requestAnimationFrame(animationFrame);
      } else {
        setthird(90);
      }
    };

    animationFrame();
  };
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        background: "#89c8ff",
      }}
    >
      <div style={{ width: "80%" }}>
        <h2 className="my-6" style={{ textAlign: "center", fontSize: "2rem" }}>
          Efficiency Gains with AI Virtual Operations Assistants
        </h2>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="widthloadbar" >
            <div className="animated" ref={animated}>
              <small className="small-text">
                Management effort: Save
                <span>{Math.round(first)}%</span>
              </small>
              <span className="blue-bar" style={{ width: `${first}%` }}></span>
            </div>

            <div className="animated" ref={animatedRef}>
              <small className="small-text">
                SEO effort: Save
                <span>{Math.round(percentage)}%</span>
              </small>
              <span
                className="blue-bar"
                style={{ width: `${percentage}%` }}
              ></span>
            </div>
            <div className="animated" ref={animate}>
              <small className="small-text">
                Operations Effort:
                <span>{Math.round(third)}%</span>
              </small>
              <span className="blue-bar" style={{ width: `${third}%` }}></span>
            </div>
          </div>
        </div>
        <h3
          style={{
            textAlign: "center",
            fontSize: "1.5rem",
            marginBottom: "50px",
          }}
        >
          Imagine the limitless possibilities when you hire AI Virtual Ops to
          trim down your time and effort commitments for your business!
        </h3>
        {/* <p className="buy-now" style={{ marginBottom: "50px" }}>
          <a className="learn-more" style={{}}>
            Hire AI Virtual Ops now!
          </a>
        </p> */}
        <div className="flex justify-center">
          <button style={{backgroundColor:'#8CD22D',color:'white'}} className="buy-now1 px-4 py-3 mt-4 mb-8  rounded-md">
            <a className="">START YOUR FREE TRIAL NOW</a>
            {/* <button className="learn-more">START YOUR FREE TRIAL NOW</button> */}
          </button>
          </div>
      </div>
    </div>
  );
};

export default Skill;
