// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.question-section {
    margin-left: 100px;
    margin-right: 100px;
}
@media (max-width:700px) {
    .question-section{
        margin-left: 0px;
        margin-right: 0px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Price/Faq.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;AACA;IACI;QACI,gBAAgB;QAChB,iBAAiB;IACrB;AACJ","sourcesContent":[".question-section {\n    margin-left: 100px;\n    margin-right: 100px;\n}\n@media (max-width:700px) {\n    .question-section{\n        margin-left: 0px;\n        margin-right: 0px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
