import React from "react";
import Image from "../Images/andy-kelly-0E_vhMVqL9g-unsplash.jpg";

const Serve = () => {
  return (
    <div className="profit-main" style={{ marginTop: "4vw" }}>
      <div className="profit-second mission-main" style={{ width: "90%" }}>
        <div className="mission-first">
          <h1>Why work with AI Virtual Ops</h1>
          <p
            style={{
              width: "90%",
              textAlign: "justify",
              textJustify: "inter-word",
            }}
          >
            AI Virtual Ops trains all its associates on the latest happenings
            within the Artificial Intelligence, Generative AI, Large Language
            Models and all platforms, workflows and tools which are built on top
            of these technologies. This enables our resources to not only use an
            AI tool but also go back and use the base technologies like{" "}
            <a
              href="https://chat.openai.com/chat"
              target="_blank"
              style={{ textDecoration: "none",color: 'blue' }}
            >
              ChatGPT by OpenAI
            </a>
            ,{" "}
            <a
              href="https://www.anthropic.com/index/introducing-claude"
              target="_blank"
              style={{ textDecoration: "none",color: 'blue' }}
            >
              Claude by Anthropic
            </a>
            ,{" "}
            <a
              href="https://ai.google/discover/palm2/"
              target="_blank"
              style={{ textDecoration: "none",color: 'blue' }}
            >
              PaLM 2 by Google
            </a>
            , and{" "}
            <a
              href="https://ai.meta.com/llama/"
              target="_blank"
              style={{ textDecoration: "none",color: 'blue' }}
            >
              Llama 2 by Meta
            </a>
            .
          </p>
          <p
            style={{
              width: "90%",
              textAlign: "justify",
              textJustify: "inter-word",
            }}
          >
            These trained resources are there to utilize AI and operate your
            business and perform the day to day tasks needed but also with use
            of automation and Generative AI improve your workflows so that your
            operational costs reduce.
          </p>
          <p
            style={{
              width: "90%",
              textAlign: "justify",
              textJustify: "inter-word",
            }}
          >
            So if you had regular VA's and BPO resources before trying us, AIVO
            can safely promise we can reduce your pre AIVO operational costs by
            20% in 6 months and 35% in 12 months.
          </p>
        </div>
        <div className="mission-second">
          <img src={Image} alt="" style={{ borderRadius: "20px" }} />
        </div>
      </div>
    </div>
  );
};

export default Serve;
