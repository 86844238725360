import React, { useState } from "react";

const Question8 = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className="faq-section animated"
      onMouseEnter={toggleAccordion}
      onMouseLeave={toggleAccordion}
    >
      <div
        className={`faq-header ${isOpen ? "open" : ""}`}
        onClick={toggleAccordion}
      >
        <div className="faq-question">
          What sets your agency apart from other virtual assistant providers?
        </div>
        {/* <div
          className="faq-icon"
          style={{ fontSize: "40px", paddingRight: "20px" }}
        >
          {isOpen ? "-" : "+"}
          <FontAwesomeIcon icon="fa-circle-question" color="black" />
        </div> */}
      </div>
      {isOpen && (
        <div className="faq-answer">
          <span>
            As a virtual assistant company leveraging advanced AI technologies,
            our virtual assistants are exceptionally proficient in harnessing AI
            tools to enhance productivity. Additionally, as a company owned by
            its employees, our virtual assistants receive competitive
            compensation. We are dedicated to maintaining transparency in
            disclosing how your payment directly supports our virtual assistants
            and their well-being.
          </span>
        </div>
      )}
    </div>
  );
};

export default Question8;
