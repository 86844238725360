import React, { useEffect } from "react";
import Header from "./Header";
import Maincontent from "./Maincontent";
import Footer from "../Home/Footer.js";

const Contact = () => {
  useEffect(() => {
    // Scroll to the top when the component mounts (page loads)
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header />
      <Maincontent />
      <Footer />
    </div>
  );
};

export default Contact;
