import React, { useEffect } from "react";
import Header from "../Home/header";
import MainContent from "./MainContent";
import Body from "./Body";
import Footer from "../Home/Footer";

const Terms = () => {
  useEffect(() => {
    // Scroll to the top when the component mounts (page loads)
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <MainContent />
      <Body />
      <Footer />
    </div>
  );
};

export default Terms;
