import React,{useState,useEffect} from "react";
import "./BlogSection.css";
import { Link } from "react-router-dom";
import BlogF from "../Images/blog-1.png";
import BlogS from "../Images/blog-2.png";
import BlogT from "../Images/blog-3.png";
import BlogFo from "../Images/blog-4.png";
import BlogFi from "../Images/blog-5.png";
import Blog6 from "../Images/blog-6.png";
import Blog7 from "../Images/blog-7.png";
import Blog8 from "../Images/blog-8.png";
import Blog9 from "../Images/blog-9.png";
import Blog10 from "../Images/blog-10.png";
import Blog11 from "../Images/blog-11.png";
import Blog12 from "../Images/blog-12.png";
import {ThreeDots} from 'react-loader-spinner';


import axios from "axios";
import { useNavigate } from "react-router-dom";

import { htmlConverterReact } from 'html-converter-react';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML, convertFromRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from 'draft-convert';
import htmlToDraft from 'html-to-draftjs';


const BlogSection = () => {

  const defaultContent = 'Your default blog description goes here...';

  // Use state to manage the editor state
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(ContentState.createFromText(defaultContent))
  );

  const navigate = useNavigate();

  const[loading,setLoading]= useState(false)

  const blogs = [
    {
      id: 1,
      url: "firstblog",
      image: BlogF,
      title:
        "Human Virtual Assistants: Transforming AI Cloud Computing for a Smarter Tomorrow",
      description:
        "In an era dominated by technological advancements, the fusion of human-like interaction and artificial intelligence (AI) has paved the way for a fascinating new chapter in the world of cloud computing. ",
    },
    {
      id: 2,
      url: "secondblog",
      image: BlogS,
      title:
        "The Future of Business Support: Working with Virtual Assistant Agencies",
      description:
        "In the fast-paced world of business, staying ahead of the competition often means finding innovative ways to streamline operations, cut costs, and increase efficiency.",
    },
    {
      id: 3,
      url: "thirdblog",
      image: BlogT,
      title: "Virtual Assistant Services for Startups: A Game Changer",
      description:
        "Startups can be found in various industries, such as technology, biotech, e-commerce, and more. They typically begin with a vision or idea, secure funding from investors or other sources, and work towards establishing a sustainable business model.",
    },
    {
      id: 4,
      url: "fourblog",
      image: BlogFo,
      title: "Virtual Assistant vs. AI: Finding the Right Balance",
      description:
        "In the rapidly evolving landscape of technology and artificial intelligence, the concept of a virtual assistant versus an AI (Artificial Intelligence) is a subject of great interest and debate. As we move towards an increasingly digital and interconnected world.",
    },
    {
      id: 5,
      url: "fiveblog",
      image: BlogFi,
      title: "Virtual Assistants: Affordable Alternatives to In-House Staff",
      description:
        "In today's fast-paced business landscape, where agility and cost-efficiency are paramount, the traditional model of hiring in-house staff is undergoing a transformation. Companies, whether large or small, are exploring alternative ways to meet their administrative and operational needs without breaking the bank.",
    },
    {
      id: 6,
      url: "sixblog",
      image: Blog6,
      title:
        "Boosting Efficiency: A Comprehensive Guide to Optimizing Productivity with Virtual Assistants",
      description:
        "In today's fast-paced business landscape, where time is of the essence, optimizing productivity is not just a goal but a necessity for achieving lasting success. One powerful tool that has become indispensable in this pursuit is the virtual assistant. ",
    },
    {
      id: 7,
      url: "sevenblog",
      image: Blog7,
      title:
        "Balancing Act: Strategies for Achieving Work-Life Harmony in Your Home Office",
      description:
        "The transition to remote work has brought both opportunities and challenges, and one of the most significant challenges is striking a balance between work and personal life. In this blog, we'll explore practical tips and strategies to help you achieve a harmonious work-life balance while working from home.",
    },
    {
      id: 8,
      url: "eightblog",
      image: Blog8,
      title:
        "Navigating the Virtual Assistant Landscape: A Comprehensive Guide to Choosing the Right Solution for Your Business Needs",
      description:
        "In the fast-paced digital era, businesses are continually seeking innovative solutions to streamline operations and boost efficiency. One such solution gaining prominence is the use of virtual assistants. These AI-powered tools offer a wide array of functionalities, from automating routine tasks to enhancing customer interactions. However, with the plethora of options available, choosing the right virtual assistant for your business needs can be a daunting task.",
    },
    {
      id: 9,
      url: "nineblog",
      image: Blog9,
      title:
        "Nurturing Your Mind: A Guide to Prioritizing Mental Health in Your Home Office",
      description:
        "As the world shifts towards remote work, the home has become the new office for many. While the benefits of working from home are numerous, it's essential to prioritize mental health in this setting. In this blog, we'll explore practical tips and strategies to care for your mental well-being as you navigate the challenges of the home office",
    },
    {
      id: 10,
      url: "tenblog",
      image: Blog10,
      title:
        "The Human Touch in a Virtual World: Building Personal Connections with Virtual Assistants.",
      description:
        "In an era dominated by technology and virtual interactions, the concept of building personal connections might seem paradoxical. However, as virtual assistants become integral parts of our professional lives, the need for a human touch in our digital interactions becomes increasingly apparent. In this blog post, we will explore the significance of cultivating personal connections with virtual assistants and share strategies to bring a human touch to the virtual world.",
    },
    {
      id: 11,
      url: "elevenblog",
      image: Blog11,
      title:
        "The Rise of AI: Navigating the World of Virtual Assistants",
      description:
        "In the rapidly evolving landscape of technology, artificial intelligence (AI) has become a driving force reshaping the way we work, communicate, and conduct business. One of the most transformative applications of AI is the emergence and proliferation of virtual assistants. ",
    },
    {
      id: 12,
      url: "twelveblog",
      image: Blog12,
      title:
        "Virtual Assistants in Entrepreneurship: Empowering Small Businesses",
      description:
        "In the dynamic landscape of modern entrepreneurship, small businesses often face challenges that demand creative solutions. One such solution gaining prominence is the integration of virtual assistants (VAs) into the daily operations of small enterprises. These digital helpers are not only reshaping how businesses operate but are proving to be powerful tools in enhancing productivity, efficiency, and overall success.        ",
    },
    // Add more blog items as needed
  ];

  const[data,setData]=useState([])

  useEffect(()=>{
    setLoading(true)
   axios.get(`${process.env.REACT_APP_URL}/api/getBlog`).then(res => {console.log(res.data.data,"----"); setData(res.data.data);setLoading(false)})
      
  //  axios.get(`${process.env.REACT_APP_URL}/api/getBlog`).then(res => {console.log(res.data.data,"----"); setData(res.data.data)})
  },[])

  const [BlogDescription, setBlogDescription] = useState("");

  useEffect(() => {
    if (BlogDescription !== '') {
      const contentBlock = htmlToDraft(BlogDescription);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks,
          contentBlock.entityMap
        );
        setEditorState(EditorState.createWithContent(contentState));
      }
    }
  }, [BlogDescription]);


  return (
    <div style={{ background: "#89c8ff" }}>
      <div
        className="profit-main"
        style={{
          paddingTop: "50px",
          paddingBottom: "6vw",
        }}
      >
        <div className="profit-second" style={{ width: "90%" }}>
          <div className="blog-section">
             {data.map((blog) => (
              <div key={blog._id} className="box w-100 d-flex flex-column " style={{position: 'relative'}} >
              <div className="" style={{marginBottom:'70px'}}>
              <div className="box-image">
                <img src={blog.screenshot} alt={blog.name} />
              </div>
              
              <h5 className="my-3">{blog.name}</h5>
              <div className="box-details d-flex "  >
                <p
                  style={{
                    // height: "125px",
                    marginTop: "0px",
                    marginBottom: "auto",
                    textAlign: "justify",
                    textJustify: "inter-word",
                  }}
                  className="pb-5"
                >
                
                  {htmlConverterReact(blog.summary).slice(0, 500)}
                </p>
            
              </div>
              </div>
              <div className=" mt-auto d-flex justify-content-center mx-auto mb-3" style={{  position:'absolute' ,bottom: '0', left: '50%', transform: 'translateX(-50%)'  }}>
             
              <Link
                  to={`/getblogIndi/${blog._id}`}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    textDecoration: "none",
                  }}
                >
                  <button className="learn-more" style={{ marginTop: "0px" }}>
                    Read More
                  </button>
                </Link>

         </div>
            
            </div>              
            ))}

             {/* {data.map((blog) => (
              <div key={blog._id} className="box d-flex flex-column " style={{position: 'relative'}} >
              <div className="" style={{marginBottom:'70px'}}>
              <div className="box-image">
                <img src={blog.screenshot} alt={blog.name} />
              </div>
              <h5 className="my-3">{blog.name}</h5>
              <div className="box-details d-flex "  >
                <p
                  style={{
                    // height: "125px",
                    marginTop: "0px",
                    marginBottom: "auto",
                    textAlign: "justify",
                    textJustify: "inter-word",
                  }}
                  className="pb-5"
                >
                  {blog.description[0].substring(0, 500)}
                </p>
            
              </div>
              </div>
              <div className=" mt-auto d-flex justify-content-center mx-auto mb-3" style={{  position:'absolute' ,bottom: '0', left: '50%', transform: 'translateX(-50%)'  }}>
             
             <Link
                  to={`/getblogIndi/${blog._id}`}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    textDecoration: "none",
                  }}
                >
                  <button className="learn-more" style={{ marginTop: "0px" }}>
                    Read More
                  </button>
                </Link>

         </div>
            
            </div>
            ))} */}
          </div>
          <div className='flex items-center justify-center mx-auto mt-4'>
  {loading ? (
    <ThreeDots type="Oval" className='mx-auto' color="rgb(0,159,227)" height={50} width={50} />
  ) : (
    ''
  )}
</div>
        </div>
      </div>
    </div>
  );
};

export default BlogSection;
