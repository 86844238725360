import React from 'react'
import './NewBanner.css'

const NewBanner = () => {
    return (
        <div className='divp' >
            {/* <h1 className='siz text-center py-lg-5 mx-2 py-3 ' style={{ lineHeight:'55px'}}>AI Virtual <span className='h1bubble px-4 py-0 rounded-full'>Ops</span></h1> */}
            <h1 className='siz text-center  mx-2 py-0 ' ><span className='h1bubble px-4 py-0 mx-1 rounded-full'>AI </span> enhanced <span className='h1bubble2 px-4 py-0 mx-1 rounded-full'> Virtual Assistants </span><br/> for running Your <br/><span className='h1bubble3 mx-1 px-4 py-0 rounded-full'>Business</span> & <span className='h1bubble3 mx-1 px-4 py-0 rounded-full'> Operations</span></h1>
            <div className='py-lg-5 py-2 mx-2'>
                <p className='sizp text-center text-gray-500'>Elevate your business with our high-end virtual assistant services, designed to seamlessly support your administrative and operational needs, ensuring efficiency and excellence.</p>
            {/* <p className='sizp text-center text-gray-500'>Elevating Businesses with AI-Powered Virtual Assistance</p> */}
            {/* <p className='sizp text-center text-gray-500'>
                – Where Success Takes Flight
            </p> */}
            </div>
        </div>
    )
}

export default NewBanner