import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Footer from '../Home/Footer';
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { FaBloggerB } from "react-icons/fa";
import { toast } from 'react-toastify'
import { ThreeDots } from 'react-loader-spinner';

import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML, convertFromRaw } from 'draft-js'; // could be the reason
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from 'draft-convert'; // could be the reason

import { useNavigate } from "react-router-dom";

import htmlToDraft from 'html-to-draftjs';// could be the reason

const EditBlog = () => {

  const navigate = useNavigate();
  // const [editorState, setEditorState] = useState(
  //   () => EditorState.createEmpty(),
  // );

  const defaultContent = 'Your default blog description goes here...';

  // Use state to manage the editor state
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(ContentState.createFromText(defaultContent))
  );

  const [convertedContent, setConvertedContent] = useState(null);
  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(html);
    setBlogDescription(html)
  }, [editorState]);
  console.log(convertedContent);

  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };


  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)

  useEffect(() => {
    // Scroll to the top when the component mounts (page loads)
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams()

  const [BlogName, setBlogName] = useState("");
  // const [BlogLogo, setBlogLogo] = useState(null);
  const [screenshot, setScreenshot] = useState(null);
  // const [BlogTags, setBlogTags] = useState("");
  // const [BlogTag, setBlogTag] = useState("");
  const [BlogDescription, setBlogDescription] = useState("");
  const [blogSummary, setBlogSummary] = useState('')
  // const [BlogLink, setBlogLink] = useState("");

  const convertTobase = (e) => {
    console.log(e, "==44");
    let render = new FileReader();
    render.readAsDataURL(e.target.files[0]);
    render.onload = () => {
      console.log(render.result);
      setScreenshot(render.result);
    };
    render.onerror = (error) => {
      console.log("error", error);
    };
  };

  useEffect(() => {
    if (BlogDescription !== '') {
      const contentBlock = htmlToDraft(BlogDescription);
      if (contentBlock && convertedContent === '<p>Your default blog description goes here...</p>') {
        console.log(convertedContent )
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks,
          contentBlock.entityMap
        );
        setEditorState(EditorState.createWithContent(contentState));
        console.log(EditorState.createWithContent(contentState),"===========80")
        // setBlogDescription(EditorState.createWithContent(contentState))
      }

    }
  }, [BlogDescription]);

  useEffect(() => {
    setLoading(true)

    axios
      .get(`${process.env.REACT_APP_URL}/api/getBlog/${id}`)
      .then((res) => {
        setBlogName(res.data.data.name);
        setBlogDescription(res.data.data.description.join('/n'))
        setScreenshot(res.data.data.screenshot)
        setBlogSummary(res.data.data.summary)

        console.log(convertFromHTML(res.data.data.description[0]), "$$$$$$$$$", res.data.data.description); setLoading(false) // could be the reason
      })
      .catch((err) => {
        console.log(err); setLoading(false)
      });
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading2(true)


    // Handle form submission logic here
    // You can use the form data (BlogName, BlogLogo, etc.) as needed
    console.log("Form submitted:", {
      BlogName,
      // BlogLogo,
      // screenshot,
      // BlogTags,
      // BlogTag,
      BlogDescription,
      blogSummary,
      // BlogLink,
    });
    if (blogSummary.length >= 600) {
      setLoading(false)
      toast.error("Summary Should be less than 600 characters !!!")
      return
      }

      const formData = new FormData();
      formData.append('image', file);
      formData.append('BlogName', BlogName);
      formData.append('BlogDescription', BlogDescription);
      formData.append('blogSummary', blogSummary);

    const data = await axios
      .post(
        `${process.env.REACT_APP_URL}/api/editBlog/${id}`,
                  formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
      )
      .then((res) => {
        console.log(res.data); toast.success("Edited Successfully", {
          position: toast.POSITION.TOP_CENTER,
        }); setLoading2(false); setBlogName('');setBlogDescription('');setBlogSummary('')
      })
      .catch((err) => {
        console.log("err in api", err); setLoading2(false)
      });
    setTimeout(() => {
      navigate('/getallBlog')
    }, 2000);

  };





  return (
    <>
      <div style={{ backgroundColor: '#89C8FF', minHeight: '80vh', maxHeight: '100%' }} >

        <div>
          <div
            style={{
              width: "100%",
              height: "60px",
              background: "#009fe3",
              display: "flex",
              alignItems: "center",
              marginTop: "75px",
              justifyContent: "center",
            }}
          >
            <span
              style={{
                color: "white",
                fontWeight: "bold",

                fontSize: "1.5rem",
              }}
            >
              Edit Blog
            </span>
          </div>
        </div>
        <div className='mx-3 py-5'>
          <div style={{ backgroundColor: 'whitesmoke' }} className="max-w-2xl mx-auto p-6  rounded-md shadow-md mt-20 grid ">
            <div className="">
              {/* <h2 className="text-2xl font-semibold mb-4">Edit Blog</h2> */}
              <FaBloggerB className=" mx-auto mb-4 text-7xl" />
            </div>
            <div className=' flex justify-center mx-auto mt-4'>
              {loading ? (
                // <div>hello</div>
                <ThreeDots type="Oval" position="top-center" className='d-flex justify-center' color="rgb(0,159,227)" height={50} width={50} />

              ) : (
                ''
              )}
            </div>
            <form className='mx-auto' onSubmit={handleSubmit}>
              <div className="flex flex-wrap mx-auto">
                <div className="mb-4 px-2 w-full md:w-1/2">
                  <label
                    htmlFor="BlogName"
                    className="block text-sm font-medium text-gray-600"
                  >
                    Blog Name<span style={{color:'red'}}> *</span>
                  </label>
                  <input
                    type="text"
                    id="BlogName"
                    value={BlogName}
                    onChange={(e) => setBlogName(e.target.value)}
                    className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-indigo-300"
                    required
                  />
                </div>

                {/* <div className="mb-4 px-2 w-full md:w-1/2">
                  <label
                    htmlFor="screenshot"
                    className="block text-sm font-medium text-gray-600"
                  >
                    Website Screenshot<span style={{color:'red'}}> *</span>
                  </label>
                  <input
                    type="file"
                    id="screenshot"
                    accept="image/*"

                    onChange={convertTobase}
                    className="mt-1 p-2 bg-white w-full border rounded-md focus:outline-none focus:ring focus:border-indigo-300"
                    required
                  />
                </div> */}
                <div className="mb-4 ps-2 w-full md:w-1/2">
        <label
          htmlFor="screenshot"
          className="block text-sm font-medium text-gray-600"
        >
          Website Screenshot<span style={{color:'red'}}> *</span>
        </label>
        <input
          type="file"
          id="screenshot"
          accept="image/*"
          onChange={handleFileChange}
          className="mt-1 p-2 bg-white w-full border rounded-md focus:outline-none focus:ring focus:border-indigo-300"
          required
        />
      </div>
              </div>

              <div className="mb-4 col-span-2">
                <label
                  htmlFor="BlogDescription"
                  className="block text-sm font-medium text-gray-600"
                >
                  Blog Summary<span style={{color:'red'}}> *</span>
                </label>
                <textarea
                  id="BlogSummary"
                  value={blogSummary}
                  onChange={(e) => setBlogSummary(e.target.value)}
                  className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-indigo-300"
                  rows="4"
                  required
                ></textarea>

              </div>

              <div className="mb-4 col-span-2">
                <label
                  htmlFor="BlogDescription"
                  className="block text-sm font-medium text-gray-600"
                >
                  Blog Description <span style={{color:'red'}}> *</span>
                </label>
                <div  style={{backgroundColor:'white'}} className='editorJs px-2'>
                  <Editor
                    style={{backgroundColor:'black'}}
                    toolbar={{
                      options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'emoji', 'remove', 'history'],
                      blockType: {
                        inDropdown: true,
                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
                      },
                      // image: {

                      //   urlEnabled: true,
                      //   uploadEnabled: true,
                      //   alt: { present: false, mandatory: false },
                      //   defaultSize: {
                      //     height: 'auto',
                      //     width: 'auto',
                      //   },
                      // },

                    }}
                    // onChange={(e) => setBlogDescription(e.target.value)}
                    value={BlogDescription}
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                    // onEditorStateChange={handleEditorChange}
                    // onPaste={(event) => this.Copy_Paste_Pictures_PO(event)}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                  />
                </div>
              </div>

              <div className="col-span-2 flex justify-center">
                <button
                  type="submit"
                  style={{ backgroundColor: 'blue' }}
                  className=" bg-indigo-600 text-white p-3 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring focus:border-indigo-300 border-none"
                >
                  Submit
                </button>
              </div>
              <div className=' flex justify-center mx-auto mt-4'>
                {loading2 ? (
                  // <div>hello</div>
                  <ThreeDots type="Oval" position="top-center" className='d-flex justify-center' color="rgb(0,159,227)" height={50} width={50} />

                ) : (
                  ''
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default EditBlog