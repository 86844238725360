import React from "react";

const Chatbot = () => {
  return (
    <div>
      <img
        src="https://static.vecteezy.com/system/resources/thumbnails/007/225/199/small/robot-chat-bot-concept-illustration-vector.jpg"
        alt=""
        style={{
          position: "fixed",
          bottom: "50px",
          right: "50px",
          height: "50px",
          width: "50px",
        }}
      />
    </div>
  );
};

export default Chatbot;
