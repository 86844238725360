// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container-7 {
    padding-left: 150px;
    padding-right: 150px;
}

.container-2 {
    width: 100%;
    display: flex;
    height: max-content;
    background-color: 'light-gray';
}

.left-7 {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.right-2 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.main-7 {
    font-family: sans-serif;
    font-size: 2.5rem;
    /* line-height: '46px'; */
    letter-spacing: 0;
    font-weight: 700;
}

@media screen and (max-width: 1000px) {
    .main-container-7{
        padding-left: 10px;
        padding-right: 20px;
    }
    .container-2 {
        flex-direction: column;
    }

    .left-7 {
        width: 100%;
        margin-top: 30px;
    }

    .right-2 {
        width: 100%;
        align-items: center;
    }

    /* .main-2 {
        padding: 0 100px;
    } */
}`, "",{"version":3,"sources":["webpack://./src/Home/Seven.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,UAAU;IACV,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;IACvB,iBAAiB;IACjB,yBAAyB;IACzB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI;QACI,kBAAkB;QAClB,mBAAmB;IACvB;IACA;QACI,sBAAsB;IAC1B;;IAEA;QACI,WAAW;QACX,gBAAgB;IACpB;;IAEA;QACI,WAAW;QACX,mBAAmB;IACvB;;IAEA;;OAEG;AACP","sourcesContent":[".main-container-7 {\n    padding-left: 150px;\n    padding-right: 150px;\n}\n\n.container-2 {\n    width: 100%;\n    display: flex;\n    height: max-content;\n    background-color: 'light-gray';\n}\n\n.left-7 {\n    width: 50%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n}\n\n.right-2 {\n    width: 50%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n}\n\n.main-7 {\n    font-family: sans-serif;\n    font-size: 2.5rem;\n    /* line-height: '46px'; */\n    letter-spacing: 0;\n    font-weight: 700;\n}\n\n@media screen and (max-width: 1000px) {\n    .main-container-7{\n        padding-left: 10px;\n        padding-right: 20px;\n    }\n    .container-2 {\n        flex-direction: column;\n    }\n\n    .left-7 {\n        width: 100%;\n        margin-top: 30px;\n    }\n\n    .right-2 {\n        width: 100%;\n        align-items: center;\n    }\n\n    /* .main-2 {\n        padding: 0 100px;\n    } */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
