// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.div-short{
    text-align: left;
}
.five-blog{
    display: flex;
    width:80%;
   
}
@media (max-width: 1024px) {
    .five-blog{
        width:100%;
    }
}

@media (max-width: 880px) {
    .div-short{
        text-align: center;
    }
    .five-blog{
        flex-direction: column;
    }
}`, "",{"version":3,"sources":["webpack://./src/Home/Five.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,SAAS;;AAEb;AACA;IACI;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,kBAAkB;IACtB;IACA;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".div-short{\n    text-align: left;\n}\n.five-blog{\n    display: flex;\n    width:80%;\n   \n}\n@media (max-width: 1024px) {\n    .five-blog{\n        width:100%;\n    }\n}\n\n@media (max-width: 880px) {\n    .div-short{\n        text-align: center;\n    }\n    .five-blog{\n        flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
