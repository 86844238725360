// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.widthloadbar{
    width:80%
}
@media (max-width:'600px'){
    .widthloadbar{
        width:100%
    }
}`, "",{"version":3,"sources":["webpack://./src/Price/Skill.css"],"names":[],"mappings":"AAAA;IACI;AACJ;AACA;IACI;QACI;IACJ;AACJ","sourcesContent":[".widthloadbar{\n    width:80%\n}\n@media (max-width:'600px'){\n    .widthloadbar{\n        width:100%\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
