import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const Search = () => {
  return (
    <div style={styles.searchContainer}>
      <div style={styles.iconContainer}>
        <FontAwesomeIcon icon={faSearch} style={styles.searchIcon} />
      </div>
      <input type="text" placeholder="Search..." style={styles.input} />
    </div>
  );
};

const styles = {
  searchContainer: {
    display: "flex",
    alignItems: "center",
    width: "30%",
    border: "1px dotted #000",
    padding: 5,
    marginTop: 40,
    marginLeft: 40,
  },
  iconContainer: {
    padding: 5,
  },
  searchIcon: {
    color: "#666",
  },
  input: {
    border: "none",
    outline: "none",
    fontSize: 16,
    width: "100%",
  },
};

export default Search;
