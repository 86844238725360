// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../Images//aivo lp 4.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.background-container {
    width: 100%;
    position: relative;
}

.background-image {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.background-video {
    position: relative;
    /* height: 100vh; */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    min-height: 100px;

}

.background-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
    object-fit: contain;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.content-container {
    text-align: center;
    color: #ffffff;
    /* max-width: 800px; */
}



.horizontal-line {
    border-top: 1px solid #ffffff;
    margin: 20px 0;
}

.bottom-content {
    margin-top: 20px;
}
.h1-home{
    font-size: 8rem;
}
@media (max-width:800px) {
    .h1-home{
        font-size: 4rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/Home/BackgroundImage.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,yDAAmD;IACnD,sBAAsB;IACtB,2BAA2B;IAC3B,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;IAChB,iBAAiB;;AAErB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,sBAAsB;AAC1B;;;;AAIA;IACI,6BAA6B;IAC7B,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB;AACA;IACI,eAAe;AACnB;AACA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".background-container {\n    width: 100%;\n    position: relative;\n}\n\n.background-image {\n    background-image: url(\"../Images//aivo\\ lp\\ 4.png\");\n    background-size: cover;\n    background-position: center;\n    height: 100vh;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n.background-video {\n    position: relative;\n    /* height: 100vh; */\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    overflow: hidden;\n    min-height: 100px;\n\n}\n\n.background-video video {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    /* object-fit: cover; */\n    object-fit: contain;\n}\n\n.overlay {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.5);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.content-container {\n    text-align: center;\n    color: #ffffff;\n    /* max-width: 800px; */\n}\n\n\n\n.horizontal-line {\n    border-top: 1px solid #ffffff;\n    margin: 20px 0;\n}\n\n.bottom-content {\n    margin-top: 20px;\n}\n.h1-home{\n    font-size: 8rem;\n}\n@media (max-width:800px) {\n    .h1-home{\n        font-size: 4rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
