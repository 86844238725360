import React from "react";
import "./Whoweserve.css";

const Whoweserve = () => {
  return (
    <div className="profit-main" style={{ background: "#89c8ff",display:'flex',flexWrap:'wrap' }}>
      <div className="profit-second">
        <h2
          style={{
            fontWeight: "bold",
            textAlign: "center",
            fontSize: "4rem",
            lineHeight:'4rem'
          }}
          className="mb-10"
        >
          Who We Serve
        </h2>
        
      </div>
      <div className="who-we-container mx-2  c">
          <div className="who-we-first">
            <div>
              <div style={{ display: "flex" }}>
                {/* <img
                  src="https://static.wixstatic.com/media/183c7c_742b883ea0b343ea8c4059dd539a68d7~mv2.png/v1/fill/w_125,h_125,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/icon-defense-dark.png"
                  alt=""
                /> */}
                <h2 style={{ marginBottom: "20px", marginTop: "0px" }}>
                  Startup Companies
                </h2>
              </div>
              <p
                style={{
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                AI Virtual Ops specializes in serving startup companies by
                offering exceptional operations assistance. Our mission is to
                contribute to the success and growth of startups through the
                strategic application of AI optimizations and automations. We
                understand the unique challenges startups face, and our tailored
                solutions are designed to enhance speed, productivity, and
                overall efficiency.

              </p>
             
              <ul className="mt-5 ms-5" style={{ paddingBottom:'50px', marginBottom:'25px'  }}>
                <li>Email Management</li>
                <li>Appointment Scheduler</li>
                <li>Social Media Management</li>
                <li>Bookeeping</li>
                <li>Data Entry</li>
              </ul>
              
            </div>
            {/* <div style={{   display: "flex", justifyContent: "center", position:'absolute' ,bottom: '10px', left: '50%', transform: 'translateX(-50%)', marginTop:'30px'  }}>
                <button className="learn-more" style={{ marginTop: "0px" }}>
                  Learn More
                </button>
              </div> */}
          </div>
          <div className="who-we-first">
            <div>
              <div style={{ display: "flex"}}>
                {/* <img
                  src="https://static.wixstatic.com/media/183c7c_742b883ea0b343ea8c4059dd539a68d7~mv2.png/v1/fill/w_125,h_125,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/icon-defense-dark.png"
                  alt=""
                /> */}
                <h2 style={{ marginBottom: "20px", marginTop: "0px" }}>
                  AI Companies
                </h2>
              </div>
              <p
                style={{
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                AI Virtual Ops is committed to serving a variety of AI industry
                partners through strategic collaborations. We provide crucial
                backend operations assistance to support their growth, ensuring
                a seamless operational foundation. Additionally, we serve as a
                dynamic marketing assistant, acting as affiliates and promoting
                their tools to our extensive community. AI Virtual Ops plays a
                pivotal role in enhancing the visibility and success of our
                esteemed AI partners by bridging connections and leveraging our
                network.
              </p>
              <ul className="mt-5 ms-5" style={{ paddingBottom:'50px', marginBottom:'25px'   }}>
                <li>Operations Assistance</li>
                <li>Affiliate Marketing</li>
              </ul>
              
            </div>
            {/* <div style={{ display: "flex", justifyContent: "center", position:'absolute' ,bottom: '10px', left: '50%', transform: 'translateX(-50%)', marginTop:'30px', paddingTop:'30px'  }}>
                <button className="learn-more" style={{ marginTop: "30px" }}>
                  Learn More
                </button>
              </div> */}
          </div>
        </div>
    </div>
  );
};

export default Whoweserve;
