import React from "react";
import "./Ourvalue.css";

const Ourvalue = () => {
  return (
    // <div className="profit-main" style={{}}>
    //   <div
    //     className="mission-main flex flex-wrap"
    //     style={{
    //       // width: "90%",
    //       // gap: "10vw",
    //       justifyContent:'space-between',
    //       marginTop: "2vw",
    //     }}
    //   >
    //     <div className="first-value-our second-value-second">
    //       <h1>Our Core Values</h1>
    //       <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
    //         At AI Virtual Ops, we are an Employee Owned company which puts in
    //         client satisfaction and business success over company profits and
    //         each client is considered a partner and a part of our family.
    //       </p>
    //     </div>
    //     <div className="second-value flex flex-wrap">
    //       <div className="second-value-main">
    //         <div className="second-value-first">
    //           <img
    //             src="https://static.wixstatic.com/media/947530_1fa83b6188a24f71aa68ce672921cdaf~mv2.png/v1/fill/w_63,h_63,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Passionate%20for%20the%20Public%20Mission.png"
    //             alt=""
    //           />
    //           <h3>Passionate for Customer Success</h3>
    //           <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
    //             Our KPI's to benchmark ourselves is how many customer's
    //             businesses have we positively impacted over the previous
    //             quarter.
    //           </p>
    //         </div>
    //         <div className="second-value-first" style={{ marginTop: "20px" }}>
    //           <img
    //             src="https://static.wixstatic.com/media/947530_a5f9303a342b453f8239026a05327072~mv2.png/v1/fill/w_63,h_63,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Collaborate%20and%20Evevate.png"
    //             alt=""
    //           />
    //           <h3>Collaborate & Elevate</h3>
    //           <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
    //             We strive to utilize AI to elevate business potential. This
    //             means eagerly collaborating with clients and our associates,
    //             along with our technology partners to achieve holistic customer
    //             success.
    //           </p>
    //         </div>
    //       </div>
    //       <div className="second-value-main-sec">
    //         <div className="second-value-first" style={{ marginTop: "20px" }}>
    //           <img
    //             src="https://static.wixstatic.com/media/947530_f9ccbb33373646c580cbec6cbb7c52b0~mv2.png/v1/fill/w_63,h_63,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Innovate%20What's%20Next.png"
    //             alt=""
    //           />
    //           <h3>Employee Owned and Focused</h3>
    //           <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
    //             As an employee-owned enterprise, we place equal importance on
    //             the well-being of our staff and the satisfaction of our clients.
    //             Our commitment to long-term growth is paralleled by our
    //             dedication to maintaining robust remuneration ladders. This
    //             approach ensures high levels of employee satisfaction and
    //             fosters a culture of shared success and ownership
    //           </p>
    //         </div>
    //         <div className="second-value-first" style={{ marginTop: "20px" }}>
    //           <img
    //             src="https://static.wixstatic.com/media/947530_2ea11ba508594e26ba594aa8bba09004~mv2.png/v1/fill/w_63,h_63,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Fast%20and%20Forward.png"
    //             alt=""
    //           />
    //           <h3>Move Fast and Learn Fast</h3>
    //           <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
    //             We move quickly here. We take ownership of our projects and make
    //             decisions rapidly. And if we fail fast, learn fast, move
    //             forward, and own the result.
    //           </p>
    //         </div>
    //         <div className="second-value-first" style={{ marginTop: "20px" }}>
    //           <img
    //             src="https://static.wixstatic.com/media/947530_27c31eb5b40a4b70b184091b1373fa23~mv2.png/v1/fill/w_63,h_63,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Embrace%20Differences.png"
    //             alt=""
    //           />
    //           <h3>Celebrate Success</h3>
    //           <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
    //             We celebrate success of all kinds. Our clients spread across the
    //             globe identify their true North Star and our associates works
    //             hard to achieve that in quick time and celebrate the same when
    //             "true north" is achieved.
    //           </p>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div className="profit-main" style={{}}>
    <div
      className="mission-main flex flex-wrap"
      style={{
        // width: "90%",
        // gap: "10vw",
        justifyContent:'space-between',
        marginTop: "2vw",
      }}
    >
      <div className="first-value-our second-value-second">
        <h1>Our Core Values</h1>
        <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
          At AI Virtual Ops, we are an Employee Owned company which puts in
          client satisfaction and business success over company profits and
          each client is considered a partner and a part of our family.
        </p>
      </div>
      <div className="first-value-our ">
      <div className="second-value-first">
            <img
              src="https://static.wixstatic.com/media/947530_1fa83b6188a24f71aa68ce672921cdaf~mv2.png/v1/fill/w_63,h_63,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Passionate%20for%20the%20Public%20Mission.png"
              alt=""
            />
            <h3>Passionate for Customer Success</h3>
            <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
              Our KPI's to benchmark ourselves is how many customer's
              businesses have we positively impacted over the previous
              quarter.
            </p>
          </div>
          <div className="second-value-first" style={{ marginTop: "20px" }}>
            <img
              src="https://static.wixstatic.com/media/947530_a5f9303a342b453f8239026a05327072~mv2.png/v1/fill/w_63,h_63,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Collaborate%20and%20Evevate.png"
              alt=""
            />
            <h3>Collaborate & Elevate</h3>
            <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
              We strive to utilize AI to elevate business potential. This
              means eagerly collaborating with clients and our associates,
              along with our technology partners to achieve holistic customer
              success.
            </p>
          </div>
      </div>
      <div className="first-value-our ">
      <div className="second-value-first" style={{ marginTop: "20px" }}>
            <img
              src="https://static.wixstatic.com/media/947530_f9ccbb33373646c580cbec6cbb7c52b0~mv2.png/v1/fill/w_63,h_63,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Innovate%20What's%20Next.png"
              alt=""
            />
            <h3>Employee Owned and Focused</h3>
            <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
              As an employee-owned enterprise, we place equal importance on
              the well-being of our staff and the satisfaction of our clients.
              Our commitment to long-term growth is paralleled by our
              dedication to maintaining robust remuneration ladders. This
              approach ensures high levels of employee satisfaction and
              fosters a culture of shared success and ownership
            </p>
          </div>
          <div className="second-value-first" style={{ marginTop: "20px" }}>
            <img
              src="https://static.wixstatic.com/media/947530_2ea11ba508594e26ba594aa8bba09004~mv2.png/v1/fill/w_63,h_63,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Fast%20and%20Forward.png"
              alt=""
            />
            <h3>Move Fast and Learn Fast</h3>
            <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
              We move quickly here. We take ownership of our projects and make
              decisions rapidly. And if we fail fast, learn fast, move
              forward, and own the result.
            </p>
          </div>
          <div className="second-value-first" style={{ marginTop: "20px" }}>
            <img
              src="https://static.wixstatic.com/media/947530_27c31eb5b40a4b70b184091b1373fa23~mv2.png/v1/fill/w_63,h_63,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Embrace%20Differences.png"
              alt=""
            />
            <h3>Celebrate Success</h3>
            <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
              We celebrate success of all kinds. Our clients spread across the
              globe identify their true North Star and our associates works
              hard to achieve that in quick time and celebrate the same when
              "true north" is achieved.
            </p>
          </div>
      </div>
    
    </div>
  </div>

  );
};

export default Ourvalue;
