import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

const Animated = ({ logos }) => {

  const breakpoints = {
    0: {
      slidesPerView: 2,
    },
    500: {
      slidesPerView: 3,
    },
    1200: {
      slidesPerView: 4,
    },
  };

  return (
    <Swiper
      modules={[Navigation, Pagination, Autoplay]}
      slidesPerView={4}
      autoplay={true}
      pagination={{ clickable: true }}
      style={{
        width: "200%",
        display: "flex",
        justifyContent: "space-between",
      }}
      breakpoints={breakpoints}
    >
      {logos.map((logo, index) => (
        <SwiperSlide className="mx-0" key={index}>
          <img
            src={logo}
            alt={`Logo ${index + 1}`}
            className="partners-logo "
            style={{
              marginTop: "50px",
              paddingLeft:'10px',
              paddingRight:'10px',
              width: "150px",
              height: "30%",
            }}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Animated;
