import React, { useState } from "react";
import "./Question.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Question = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className="faq-section animated"
      onMouseEnter={toggleAccordion}
      onMouseLeave={toggleAccordion}
    >
      <div
        className={`faq-header ${isOpen ? "open" : ""}`}
        onClick={toggleAccordion}
      >
        <div className="faq-question">
          What services do your virtual assistants provide?
        </div>
        {/* <div
          className="faq-icon"
          style={{ fontSize: "40px", paddingRight: "20px" }}
        >
          {isOpen ? "-" : "+"}
          <FontAwesomeIcon icon="fa-circle-question" color="black" />
        </div> */}
      </div>
      {isOpen && (
        <div className="faq-answer">
          <span>
            Our team of virtual assistants possesses a vast reservoir of
            knowledge and skill sets to provide comprehensive support to a wide
            spectrum of businesses. Our services encompass a broad range of
            administrative tasks and backend operations, including but not
            limited to email management, document and presentation creation, as
            well as media-related tasks such as video and graphics production.
            Furthermore, we are well-prepared to offer specialized services
            tailored to specific business needs. We welcome the opportunity to
            engage in a detailed conversation with you to explore the limitless
            potential for enhancing and supporting your business.
          </span>
        </div>
      )}
    </div>
  );
};

export default Question;
