// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mission-second{
    width:50%
}
.mission-first{
    width:50%
}
.ulgap{
    gap:20px
}
@media(max-width:1024px){
    .mission-second{
        width:100%
    }
    .mission-first{
        width:100%
    }  
    .ulgap{
        gap:5px
    } 
}`, "",{"version":3,"sources":["webpack://./src/Aboutus.js/Reverse.css"],"names":[],"mappings":"AAAA;IACI;AACJ;AACA;IACI;AACJ;AACA;IACI;AACJ;AACA;IACI;QACI;IACJ;IACA;QACI;IACJ;IACA;QACI;IACJ;AACJ","sourcesContent":[".mission-second{\n    width:50%\n}\n.mission-first{\n    width:50%\n}\n.ulgap{\n    gap:20px\n}\n@media(max-width:1024px){\n    .mission-second{\n        width:100%\n    }\n    .mission-first{\n        width:100%\n    }  \n    .ulgap{\n        gap:5px\n    } \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
