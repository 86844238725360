// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-line{
    width: 35%;
}
@media (max-width:800px) {
    .image-line{
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/Aboutus.js/Maincontent.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;AACA;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":[".image-line{\n    width: 35%;\n}\n@media (max-width:800px) {\n    .image-line{\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
