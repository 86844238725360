import React,{useState,useEffect} from "react";
import "./Maincontact.css";
import design from "../Images/AIVO line.png";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaPinterest } from "react-icons/fa";
import { toast } from "react-toastify";
import axios from "axios";
import {ThreeDots} from 'react-loader-spinner';

const Maincontent = () => {

  const [name,setName]=useState('')
  const [sendMail,setEmail]=useState('')
  const [companyName,setCompanyName]=useState('')
  const [phone,setPhone]=useState('')
  const [subject,setSubject]=useState('')
  const [message,setMessage]=useState('')
  const [subChk,setSubChk]= useState(false)
  const [chk,setChk]=useState(false)
  const[loading,setLoading]= useState(false)

  const handleSubmit=async(e)=>{
    e.preventDefault()
    setLoading(true)

    if( sendMail =='' || name =='' || phone=='' || subject=='' || message=='' || companyName=='' ){
      toast.error("Fill all fields in the Form", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false)
      return;
    }
    if(  chk === false ){
      toast.error("You Need to aggree to T&C", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false)
      return;
    }

    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    if (!emailRegex.test(sendMail)) {
      toast.error("Invalid Email Format", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false)
      return;
    }

    const phoneRegex = /^(?:\d{10})$/;
    if(phone !== ''){
      if (!phoneRegex.test(phone)) {
        toast.error("Invalid Phone Number Format", {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false)
        return;
      }
    }


    const data = await axios
    .post(
      `${process.env.REACT_APP_URL}/api/contactusForm`,
      {name,sendMail,companyName,phone,subject,message },
      {
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      }
    ).then(res =>{if(res.data.error){toast.error(res.data.error, {
      position: toast.POSITION.TOP_CENTER,
    });setLoading(false)}else if(res.data.msg == 'ok'){toast.success("Email Sent Successfully", {
      position: toast.POSITION.TOP_CENTER,
    });setLoading(false)}else{toast.error("Some Error Occured", {
      position: toast.POSITION.TOP_CENTER,
    });setLoading(false)}}).catch(err=> {toast.error("Some Error Occured", {
      position: toast.POSITION.TOP_CENTER,
    });setLoading(false)})

    console.log('1')
    if(subChk === true){
      console.log('2',sendMail)
      const data = await axios
    .post(
      `${process.env.REACT_APP_URL}/api/subscribeFooter`,
      { sendMail},
      {
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      }
    ).then(res =>{if(res.data.error){toast.error(res.data.data, {
      position: toast.POSITION.TOP_CENTER,
    })}else if(res.data.msg == 'ok'){toast.success("Subscribed", {
      position: toast.POSITION.TOP_CENTER,
    })}else{toast.error("Some Error Occured", {
      position: toast.POSITION.TOP_CENTER,
    })}}).catch(err=> toast.error("Some Error Occured", {
      position: toast.POSITION.TOP_CENTER,
    }))

    console.log('3')

    }
    setName(''); setPhone(''); setSubject('');setMessage('');setCompanyName('');setEmail('');setSubChk(false);setChk(false)


  }

  return (
    <div className="profit-main">
      <div className="profit-second" style={{ marginBottom: "4vw" }}>
        <div className="contact-background">
          <h1
            style={{
              textAlign: "center",
              fontSize: "3rem",
              color: "white",
              fontWeight: "bold",
            }}
          >
            The Best Ways To Reach Out To Us
          </h1>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {/* <img src={design} alt="" className="image-line" /> */}
          </div>
        </div>
        {/* main */}
        <div className="main-contact">
          <div className="second-contact">
            <h2 style={{ marginBottom: "0px" }}>Get In Touch</h2>
            {/* <h3>Your contact to Rellify</h3> */}
            <div className="flex-wrap" style={{ display: "flex" }}>
              <div className="input-container w-full lg:w-1/2 px-2"  style={{ marginTop: "30px",
                //  width: "45%"
                 }}>
                <input type="text" required value={name} onChange={(e)=>setName(e.target.value)} className="input-style px-2" placeholder="Name" />
              </div>
              <div className="input-container w-full lg:w-1/2 px-2"  style={{ marginTop: "30px",
                //  width: "45%"
                 }}>
                <input
                  type="text"
                  className="input-style px-2"
                  placeholder="Email Address"
                  required value={sendMail} onChange={(e)=>setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="flex-wrap" style={{ display: "flex"}}>
              <div
                className="input-container  w-full lg:w-1/2 px-2"
                style={{ marginTop: "30px",
                //  width: "45%"
                 }}
              >
                <input
                  type="text"
                  className="input-style dtsi px-2"
                  placeholder="Company Name"
                  required value={companyName} onChange={(e)=>setCompanyName(e.target.value)}
                />
              </div>
              <div
                className="input-container  w-full lg:w-1/2 px-2"
                style={{ marginTop: "30px",
                //  width: "45%" 
                }}
              >
                <input
                  type="text"
                  className="input-style dtsi px-2"
                  placeholder="Phone Number"
                  required value={phone} onChange={(e)=>setPhone(e.target.value)}
                />
              </div>
            </div>
            <div
              className="input-container px-2"
              style={{ marginTop: "30px", width: "100%" }}
            >
              <input
                type="text"
                className="input-style px-2"
                placeholder="Subject"
                required value={subject} onChange={(e)=>setSubject(e.target.value)}
              />
            </div>
            <div
              className="input-container px-2"
              style={{ marginTop: "30px", width: "100%" }}
            >
              {/* <label htmlFor="">
                When do you want to start?
                <span style={{ color: "red" }}>*</span>
              </label> */}
              <textarea
                type="text"
                className="input-style p-2"
                placeholder="Your Message"
                style={{ height: "200px" }}
                required value={message} onChange={(e)=>setMessage(e.target.value)}
              />
            </div>

            <div
              style={{
                display: "flex",
                marginTop: "10px",
                //   alignItems: "flex-start",
                //   justifyContent: "space-between",
              }}
            >
              
              <p
                htmlFor="subscribe"
                style={{ color: "#999", marginLeft: "5px" }}
              >
              <input type="checkbox" checked={subChk} onChange={(e)=>{setSubChk(e.target.checked)}} />  I would love to receive email updates from AI Virtual Ops
                <span style={{ color: "red" }}>*</span>
              </p>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                //   alignItems: "flex-start",
                //   justifyContent: "space-between",
              }}
            >
             
              <p
                htmlFor="subscribe"
                style={{ color: "#999", marginLeft: "5px" }}
              >
               <input type="checkbox" checked={chk} onChange={(e)=>{setChk(e.target.checked)}} />  I have read and agreed to the Privacy policy of AI Virtual Ops
                <span style={{ color: "red" }}>*</span>
              </p>
            </div>
            <a
                className=""
                style={{ color: "white" }}
                onClick={(e)=>handleSubmit(e)}
              >
            <button
              className=" px-2 learn-more mx-auto "
              onClick={(e)=>handleSubmit(e)}
              style={{
                display: "flex",
                cursor: "pointer",
                justifyContent: "center",
                transition: "color 0.3s, transform 0.3s"
                // width: "100%",
              }}
              onMouseEnter={(e) => {
                e.target.style.color = "black";
                e.target.style.transform = "scale(1.05)"; // Increase size by 5%
              }}
              onMouseLeave={(e) => {
                e.target.style.color = "white";
                e.target.style.transform = "scale(1)"; // Return to normal size
              }}
              
            >
             
                Send Email
           
            </button>
            </a>
            <div className=' flex justify-center mx-auto mt-4'>
         {loading ? (
          // <div>hello</div>
            <ThreeDots type="Oval" position="top-center" className='d-flex justify-center' color="rgb(0,159,227)" height={50} width={50} />
           
          ) : (
            ''
          )}
          </div>
          </div>

          <div className="first-contact">
            <h2 style={{ marginBottom: "10px", color: "#0098db" }}>
              AI Virtual Ops
            </h2>
            <div className="firt-con-call flex flex-col lg:flex-row ">
              <div className="xl:w-1/2 w-full">
                <h3>Email:</h3>
                <p>info@aivirtualops.com</p>
              </div>
              <div className="xl:w-1/2 w-full">
                <h3>Call:</h3>
                <p>United States: +1 860 990 2447</p>
                <p>Philippines: +63 917 924 1688</p>
              </div>
            </div>
            <div className="first-con-depart flex flex-col lg:flex-row">
              <div className="xl:w-1/2 w-full">
                <h3>Accounts Department</h3>
                <p>accounts@aivirtualops.com</p>
              </div>
              <div className="xl:w-1/2 w-full ">
                <h3 className=" ">Employment</h3>
                <p>jobs@aivirtualops.com</p>
              </div>
            </div>
            <div className="first-con-depar flex flex-col lg:flex-row">
              <div className='xl:w-1/2 w-full' >
                <h3>Philippines, Iloilo City</h3>
                <p>Balabago, Jaro</p>
                <p>Iloilo City, 5000</p>
              </div>
              <div className='xl:w-1/2 w-full text-left'>
                <h3>United States, Florida</h3>
                <p>8350 Bee Ridge Road,</p>
                <p>Sarasota, FL 34241</p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                gap: "10px",
                marginBottom: "20px",
                marginTop:'10px'
              }}
            >
              <a
                href="https://www.facebook.com/AIVirtualOps"
                style={{ background: "transparent" }}
              >
                <FaFacebookF
                  style={{
                    background: "#0098db",
                    borderRadius: "50%",
                    color: "white",
                    padding: "2px",
                    fontSize: "20px",
                  }}
                />
              </a>
              <FaXTwitter
                style={{
                  background: "#0098db",
                  borderRadius: "50%",
                  color: "white",
                  padding: "2px",
                  fontSize: "20px",
                }}
              />
              <a
                href="https://www.linkedin.com/company/ai-virtual-ops/"
                target="_blank"
              >
                <FaLinkedinIn
                  style={{
                    background: "#0098db",
                    borderRadius: "50%",
                    color: "white",
                    padding: "2px",
                    fontSize: "20px",
                  }}
                />
              </a>
              <FaYoutube
                style={{
                  background: "#0098db",
                  borderRadius: "50%",
                  color: "white",
                  padding: "2px",
                  fontSize: "20px",
                }}
              />
              <a
                href="https://www.instagram.com/ai.virtual.ops/"
                target="_blank"
              >
              <FaInstagram 
                style={{
                  background: "#0098db",
                  borderRadius: "50%",
                  color: "white",
                  padding: "2px",
                  fontSize: "20px",
                }}
              /></a>
            </div>
            <div className="last-box">
              <div
                style={{ fontWeight: "bold", fontSize: "30px", color: "white" }}
              >
                Want to know more?
              </div>
              <div className="last-box-button">
                <div style={{ marginTop: "15px"}}>
                  <p style={{ marginBottom: "0px" }}>Book a chat with our</p>
                  <p style={{ marginTop: "0px" }}>Customer Success team</p>
                </div>

                <button
                  style={{
                    border: "none",
                    textAlign: "center",
                    borderRadius: "30px",
                    background: "white",
                    width: "200px",
                    height: "50px",
                    marginTop: "15px",
                    color: "#0098db",
                    cursor: "pointer",
                  }}
                  className="px-3 py-2"
                >
                  <a
                    href="https://tidycal.com/aivo/discovery-call"
                    target="_blank"
                    style={{ textDecoration: "none", color: "#0098db" }}
                  >
                    Book Now
                  </a>
                </button>
              </div>
            </div>
            {/* <p>
              8350 Bee Ridge Road, <br />
              Sarasota <br /> FL 34241
            </p>
            <p>
              +1 8609902447 <br /> info@aivirtualops.com
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Maincontent;
