import "./App.css";
import Home from "./Home/Home";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Price from "./Price/Price";
import Header from "./Home/header";
import About from "./Aboutus.js/About";
import "react-tooltip/dist/react-tooltip.css";
import Contact from "./Contactus.js/Contact";
import Blog from "./Blog/Blog";
import Toolpage from "./Toolpage/Toolpage";
import BlogFirst from "./Blog/BlogFirst";
import BlogSecond from "./Blog/BlogSecond";
import BlogThird from "./Blog/BlogThird";
import BlogFour from "./Blog/BlogFour";
import BlogFive from "./Blog/BlogFive";
import BlogSix from './Blog/BlogSix'
import BlogSeven from "./Blog/BlogSeven";
import BlogEight from "./Blog/BlogEight";
import BlogNine from "./Blog/BlogNine";
import BlogTen from "./Blog/BlogTen";
import BlogEleven from "./Blog/BlogEleven";
import BlogTwelve from "./Blog/BlogTwelve";

import Terms from "./Terms/Terms";
import Cookie from "./Cookies/Cookies";
import Privacy from "./Privacy/Privacy";
import "react-toastify/dist/ReactToastify.css";
import SignIn from "./SignIn/SignIn";
import Addtool from "./Addtool/Addtool";
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import Showtool from "./ShowList/Showtool";
import ShowBlog from "./ShowListBlog/ShowBlog";
import AddBlog from "./AddBlog/AddBlog";
import BlogIndi from "./BlogIndi/BlogIndi";
import EditBlog from "./EditBlog/EditBlog";
import EditTools from "./EditTool/EditTools";

function App() {
  const userCookie = Cookies.get("user");
  console.log("cookie value", userCookie);
  return (
    <div className="App">
      <Router>
        <Header />
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/price" element={<Price />} />
          <Route path="/Aboutus" element={<About />} />

          <Route path="/Contactus" element={<Contact />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/aiproducts" element={<Toolpage />} />
          <Route path="/blog/firstblog" element={<BlogFirst />} />
          <Route path="/blog/secondblog" element={<BlogSecond />} />
          <Route path="/blog/thirdblog" element={<BlogThird />} />
          <Route path="/blog/fourblog" element={<BlogFour />} />
          <Route path="/blog/fiveblog" element={<BlogFive />} />
          <Route path="/blog/sixblog" element={<BlogSix />} />
          <Route path="/blog/sevenblog" element={<BlogSeven />} />
          <Route path="/blog/eightblog" element={<BlogEight />} />
          <Route path="/blog/nineblog" element={<BlogNine />} />
          <Route path="/blog/tenblog" element={<BlogTen />} />
          <Route path="/blog/elevenblog" element={<BlogEleven />} />
          <Route path="/blog/twelveblog" element={<BlogTwelve />} />







          <Route path="/tos" element={<Terms />} />
          <Route path="/cookie-policy" element={<Cookie />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/signin" element={<SignIn />} />
          <Route
            path="/Addtool"
            element={
              userCookie == "admin" ? <Addtool /> : <Navigate to="/signin" />
            }
          />
           <Route
            path="/AddBlog"
            element={
              userCookie == "admin" ? <AddBlog /> : <Navigate to="/signin" />
            }
          />
          <Route path="/getall" element={<Showtool />} />
          <Route path="/getallblog" element={<ShowBlog />} />
          <Route path="/getblogIndi/:id" element={<BlogIndi />} />
          <Route path="/editTools/:id" element={<EditTools />} />
          <Route path="/editBlog/:id" element={<EditBlog />} />


        </Routes>
      </Router>
    </div>
  );
}

export default App;
