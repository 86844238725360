// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-container{
    display: flex;
    flex-direction: column;
}
.input-style{
    height: 40px;
    border: 1px solid #cbd6e2;
    background-color: #f5f8fa;
    border-radius: 3px;
    margin-top: 10px;
}
.second-inputs{
    display: flex;
    justify-content:space-between;
    margin-top: 30px;
}
.input-width{
    width: calc(50% - 7px);
}
.buy-now-form {
    /* font-weight: bold;
    margin-top: 10px; */
    /* text-align: center; */
    padding: 0 0 20px;
}

.buy-link-form {
    color: black;
    background-color: #f39200;
    padding: 15px 16px 13px;
    text-decoration: none;
    font-size: 12px;
    font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/Price/Form.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,YAAY;IACZ,yBAAyB;IACzB,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,6BAA6B;IAC7B,gBAAgB;AACpB;AACA;IACI,sBAAsB;AAC1B;AACA;IACI;uBACmB;IACnB,wBAAwB;IACxB,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,uBAAuB;IACvB,qBAAqB;IACrB,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".input-container{\n    display: flex;\n    flex-direction: column;\n}\n.input-style{\n    height: 40px;\n    border: 1px solid #cbd6e2;\n    background-color: #f5f8fa;\n    border-radius: 3px;\n    margin-top: 10px;\n}\n.second-inputs{\n    display: flex;\n    justify-content:space-between;\n    margin-top: 30px;\n}\n.input-width{\n    width: calc(50% - 7px);\n}\n.buy-now-form {\n    /* font-weight: bold;\n    margin-top: 10px; */\n    /* text-align: center; */\n    padding: 0 0 20px;\n}\n\n.buy-link-form {\n    color: black;\n    background-color: #f39200;\n    padding: 15px 16px 13px;\n    text-decoration: none;\n    font-size: 12px;\n    font-weight: 700;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
