import React from "react";
import "./Reverse.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Image from "../Images/aivo_website10.png";

const Reverse = () => {
  return (
    <div
      className="profit-main"
      style={{ background: "#89c8ff", paddingTop: "30px" }}
    >
      <div className="profit-second mission-main flex " style={{ width: "90%" }}>
        <div className="mission-second ">
          <img src={Image} className="px-3" alt="" style={{ borderRadius: "20px" }} />
        </div>
        <div className="mission-first mx-2" style={{  }}>
          <h1>Who we Service</h1>
          <p
            style={{
              width: "90%",
              textAlign: "justify",
              textJustify: "inter-word",
            }}
          >
            We are industry agnostic as an organization as our Associates comes
            from all industries and we service and support all industries and
            roles as long as its something which can be learned quickly and can
            be elevated using Generative AI.
          </p>
          <div className="flex-wrap ulgap" style={{ display: "flex" }}>
            <ul style={{ listStyle: "none" }} className="list-item">
              <li>
                <FontAwesomeIcon
                  icon={faCheck}
                  size="1.6x"
                  style={{
                    background: "#89c8ff",
                    borderRadius: "50%",
                    marginRight: "20px",
                  }}
                />
                General Administration
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCheck}
                  size="1.6x"
                  style={{
                    background: "#89c8ff",
                    borderRadius: "50%",
                    marginRight: "20px",
                  }}
                />
                Customer Support
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCheck}
                  size="1.6x"
                  style={{
                    background: "#89c8ff",
                    borderRadius: "50%",
                    marginRight: "20px",
                  }}
                />
                Customer Success
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCheck}
                  size="1.6x"
                  style={{
                    background: "#89c8ff",
                    borderRadius: "50%",
                    marginRight: "20px",
                  }}
                />
                Technical Support
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCheck}
                  size="1.6x"
                  style={{
                    background: "#89c8ff",
                    borderRadius: "50%",
                    marginRight: "20px",
                  }}
                />
                L1-L2 Support
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCheck}
                  size="1.6x"
                  style={{
                    background: "#89c8ff",
                    borderRadius: "50%",
                    marginRight: "20px",
                  }}
                />
                Ecom Support
              </li>
            </ul>
            <ul style={{ listStyle: "none" }} className="list-item">
              <li>
                <FontAwesomeIcon
                  icon={faCheck}
                  size="1.6x"
                  style={{
                    background: "#89c8ff",
                    borderRadius: "50%",
                    marginRight: "20px",
                  }}
                />
                Marketing Ops
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCheck}
                  size="1.6x"
                  style={{
                    background: "#89c8ff",
                    borderRadius: "50%",
                    marginRight: "20px",
                  }}
                />
                Sales Ops
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCheck}
                  size="1.6x"
                  style={{
                    background: "#89c8ff",
                    borderRadius: "50%",
                    marginRight: "20px",
                  }}
                />
                Lead Gen
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCheck}
                  size="1.6x"
                  style={{
                    background: "#89c8ff",
                    borderRadius: "50%",
                    marginRight: "20px",
                  }}
                />
                HR/ Recruitment Support
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCheck}
                  size="1.6x"
                  style={{
                    background: "#89c8ff",
                    borderRadius: "50%",
                    marginRight: "20px",
                  }}
                />
                Startup Ops
              </li>
              <li>
                <FontAwesomeIcon
                  icon={faCheck}
                  size="1.6x"
                  style={{
                    background: "#89c8ff",
                    borderRadius: "50%",
                    marginRight: "20px",
                  }}
                />
                Calendar/ Scheduling Support
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reverse;
