import React from "react";
import "./Feature.css";
import Animated from "./Animated";

const Feature = () => {
  const logos = [
    "https://assets-global.website-files.com/5e6912b3e3a01a5b397ad383/5e6918c34ac2b764c13f2215_REIKI.png",
    "https://assets-global.website-files.com/5e6912b3e3a01a5b397ad383/5e6918fd16b15a2bccfcf8fd_wn-logo.jpg",
    "https://assets-global.website-files.com/5e6912b3e3a01a5b397ad383/5e6919104ac2b75c883f235e_Imali_Logo_PNG.png",
    "https://assets-global.website-files.com/5e6912b3e3a01a5b397ad383/5e6919f04ac2b7ee8f3f3687_WOS-White.png",
    "https://assets-global.website-files.com/5e6912b3e3a01a5b397ad383/5e6919a7abaae7a8700c9f5c_Podcast%20Inc_V2.png",
    "https://assets-global.website-files.com/5e6912b3e3a01a5b397ad383/5e6919c7e245930f980dc8ef_dhfeb2016_511_13-p-500.png",
    // "https://rellify.com/wp-content/uploads/2022/10/tri-arc_logo-600x150-1.png",
    // Add more logo URLs as needed
  ];
  return (
    <div className="section">
      {/* feature section */}
      <div
        style={{
          fontWeight: "bold",
          fontSize: "2.5rem",
          textAlign: "center",
        }}
      >
        We Are Trusted By
      </div>
      <section style={{overflowX:'clip'}}>
        <div className="row-logo">
          <div
          
            style={{
              width: "95%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Animated logos={logos} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Feature;
