// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mission-main{
    display: flex;
    width: 90%;
    margin-bottom: 2vw;
}
.mission-first{
    width: 60%;
    margin-top: 0vw;
   
    
}
.mission-second{
    width: 50%;
   
}
.mission-second img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}
.leadership{
    width: 90%;
}
.list-item li{
      margin-top: 10px;
}
.mission-div{
    width: 90%;
}
@media (max-width: 800px) {
    .mission-main{
        flex-direction: column;
        gap: 20px;
    }
    .mission-first{
        width: 100%;
    }
}

`, "",{"version":3,"sources":["webpack://./src/Aboutus.js/Mission.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,UAAU;IACV,kBAAkB;AACtB;AACA;IACI,UAAU;IACV,eAAe;;;AAGnB;AACA;IACI,UAAU;;AAEd;AACA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;AACvB;AACA;IACI,UAAU;AACd;AACA;MACM,gBAAgB;AACtB;AACA;IACI,UAAU;AACd;AACA;IACI;QACI,sBAAsB;QACtB,SAAS;IACb;IACA;QACI,WAAW;IACf;AACJ","sourcesContent":[".mission-main{\n    display: flex;\n    width: 90%;\n    margin-bottom: 2vw;\n}\n.mission-first{\n    width: 60%;\n    margin-top: 0vw;\n   \n    \n}\n.mission-second{\n    width: 50%;\n   \n}\n.mission-second img{\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n    border-radius: 20px;\n}\n.leadership{\n    width: 90%;\n}\n.list-item li{\n      margin-top: 10px;\n}\n.mission-div{\n    width: 90%;\n}\n@media (max-width: 800px) {\n    .mission-main{\n        flex-direction: column;\n        gap: 20px;\n    }\n    .mission-first{\n        width: 100%;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
