import React from "react";

const Body = () => {
  return (
    <div className="profit-main">
      <div className="profit-second" style={{ marginBottom: "6vw" }}>
        <h1 style={{ textAlign: "center" }}>AIVirtualOps Cookie Policy</h1>
        <p>Updated Nov 21, 2023</p>
        <p>Location Updated Miami FL</p>
        <h3>Introduction</h3>
        <p>
          This cookie policy (“
          <span style={{ fontWeight: "bold" }}>Cookie Policy</span>”) describes
          the cookies and other technologies that AIVirtualOps, LLC (“
          <span style={{ fontWeight: "bold" }}>AIVirtualOps,</span>” “
          <span style={{ fontWeight: "bold" }}>AIVO</span>”) uses on its
          websites and mobile application (the “Site”) and the choices that you
          have related to such tracking. This Cookie Policy forms part of
          our Privacy Policy.
        </p>
        <p>
          Depending on your location, you may be asked to consent to the use of
          cookies and other technologies on the Site in accordance with this
          Cookie Policy when you first visit the Site, and we will store such
          cookies on your computer if you accept.
        </p>
        <p>
          This Cookie Policy includes an overview of cookies and other
          technologies that we use on the Site. The Cookie Policy also provides
          a list of the specific technologies that we use, a description of why
          we use the technology, for example, to improve our analytics and
          provide better services, and an explanation of how to opt-out of the
          cookie if possible. Please reach out to AiVirtualOps
          at support@aivirtualops.com if you have any questions about our
          practices, or you may use the additional form of contact in the
          Contacting AiVirtualOps section of this Policy.
        </p>
        <h3>Cookies and Other Technologies</h3>
        <p>
          A cookie is a piece of information sent to your browser from a website
          and stored on your computer that the website can use to gather
          information. Cookies can help a website recognize repeat users and
          allow a website to see web usage behavior. If you are interested in
          cookies and similar technologies, you can learn more on websites
          like All About Cookies. Cookies can be stored on your computer for
          different periods of time. “Session cookies” are deleted automatically
          once you close your browser. “Persistent cookies” cookies survive
          until a pre-defined expiration date set in the cookie, which is
          determined by the third party that placed the cookie. Persistent
          cookies help recognize your computer when you open your browser and
          browse the internet again. “First party cookies” are placed by a
          website directly and “third-party cookies” are placed by a third-party
          with a website’s permission to do so.
        </p>
        <p>
          Our Site uses first party cookies and third-party cookies, as
          described below. We also use pixel tags from the third parties
          described below to see information about your usage of the Site and
          the Services and your interaction with email or other communications.
          Pixel tags are a technology like cookies that can be embedded in
          online content or within the body of an email for the purpose of
          seeing activity on websites, for example, to know when you have viewed
          particular content or a particular email message.
        </p>
        <h3>Technologies We Use</h3>
        <p>Cookies</p>
        <p>
          Below are the purposes and opt-out methods of each type of cookie.
        </p>
        <div className="div-text">1. Strictly Necessary</div>
        <p className="div-text">Purpose</p>
        <div>
          To provide users with the services available through our Site and to
          use some of its features, such as the ability to log-in and access
          secure areas. AiVirtualOps places these cookies, and they are
          essential for using the Site. Basic functions of our Site would not
          work without these cookies.
        </div>
        <p className="div-text">Opt-out</p>
        <p>
          Because these cookies are strictly necessary to deliver the Site and
          our services, you cannot opt-out of them if you choose to use the
          Site.
        </p>
        <div className="div-text">2. Analytics/Performance</div>
        <p className="div-text">Purpose</p>
        <p>
          To better understand the behavior of the users on our Site, tracks
          bots, and improve our services accordingly:
        </p>
        <ul>
          <li>
            <span style={{ fontWeight: "bold" }}>CrazyEgg.</span> Learn more
            about CrazyEgg’s privacy practices here.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>Datadog.</span> Learn more
            about Datadog’s privacy practices here.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>FullStory.</span>
            Learn more about FullStory’s privacy practices here.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>
              Google Analytics 360 and GA4.
            </span>
            Learn how Google uses data collected on our Site here. Learn more
            about how these cookies expire here.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>Imperva. </span>Learn more
            about Imperva’s privacy practices here.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>LogRocket.</span>
            Learn more about LogRocket’s privacy practices here.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>Microsoft App Store.</span>
            Learn more about Microsoft App Store’s privacy practices here
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>Optimizely.</span>
            Learn more about Optimizely’s privacy practices here.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>Segment.</span>
            Learn more about Segment’s privacy practices here.
          </li>
        </ul>
        <p className="div-text">Opt-out</p>
        <div>
          You can block or delete cookies by changing the browser settings as
          explained under the “Your Choices” section below.
        </div>
        <p>
          The providers listed below also offer direct opt-out functionalities:
        </p>
        <ul>
          <li>
            <span style={{ fontWeight: "bold" }}>CrazyEgg.</span> To opt-out of
            CrazyEgg’s tracking, please visit Opt-Out.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>FullStory.</span>
            To opt-out of Full Story’s tracking, please visit Opt-Out.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>Google Analytics.</span>
            Google Analytics. You can download and install the Google Analytics
            Opt-Out Browser Add-on for your current web browser at Google
            Analytics Opt-Out Browser Add-on.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>Mouseflow.</span>
            To opt-out of Mouseflow’s tracking, please visit Opt-Out.
          </li>
        </ul>
        <div className="div-text">3. Advertising/Targeting</div>
        <p className="div-text">Purpose</p>
        <div>
          To collect information about browsing habits to make advertising more
          relevant:
        </div>
        <ul>
          <li>
            <span style={{ fontWeight: "bold" }}>Criteo.</span>
            Learn more about Criteo’s privacy practices here.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>Demandbase.</span>
             Learn more about Demandbase’s privacy practices here.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>Google AdServices.</span>
            Learn more about Google’s privacy practices here.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>HubSpot.</span>
            Learn more about Hubspot’s privacy practices and the types of
            cookies used by Hubspot here.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>LinkedIn Insights.</span>
            Learn more about LinkedIn’s privacy practices here.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>LiveRamp.</span>
            Learn more about LiveRamp’s privacy practices here.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>Meta.</span>
            Learn more about Meta’s privacy practices here or here.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>Microsoft.</span>
            Learn more about Microsoft’s privacy practices here.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>Quora.</span>
            Learn more about Quora’s privacy practices here.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>Reddit.</span>
            Learn more about Reddit’s privacy practices here.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>Twitter.</span>
            Learn more about Twitter’s privacy practices here.
          </li>
        </ul>
        <div className="div-text">Opt-out</div>
        <p>
          You can block or delete cookies by changing the browser settings as
          explained under the “Your Choices” section below.
        </p>
        <p>
          The providers listed below also offer direct opt-out functionalities:
        </p>
        <ul>
          <li>
            <span style={{ fontWeight: "bold" }}>Meta.</span>
            To opt out, please visit About Facebook Ads.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>Google AdServices.</span>
            To opt out, please visit Ads Settings.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>LinkedIn Insights.</span>
            To opt out, please visit Manage Advertising Preferences.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>LiveRamp.</span>
            To opt-out of LiveRamp’s tracking, please visit Opt-Out.
          </li>
        </ul>
        <p>Your Choices</p>
        <p>
          Please note that if you limit the ability of websites to set cookies,
          you may be unable to access certain parts of the Site and you may not
          be able to benefit from the full functionality of the Site.
        </p>
        <div>
          On most web browsers, you will find a “help” section on the toolbar.
          Please refer to that section for information on how to receive a
          notification when you are receiving a new cookie and how to turn
          cookies off. Please see the links below for guidance on how to modify
          your web browser’s settings on the most popular browsers:
        </div>
        <ul>
          <li>Apple Safari</li>
          <li>Google Chrome</li>
          <li>Microsoft Edge and Internet Explorer</li>
          <li>Mozilla Firefox</li>
        </ul>
        <div>
          If you access the Site on your mobile device, you may not be able to
          control tracking technologies through the settings.
        </div>
        <p>Changes</p>
        <p>
          We may change the type of third-party service providers that place
          cookies on the Site and amend this Cookie Policy at any time by
          posting the amended version on the Site. Unless additional notice or
          consent is required by applicable laws, this will serve as your
          notification of these changes.
        </p>
        <p>Contact Us</p>
        <div>
          If you have any questions or concerns, you may contact us as follows:
          by email
        </div>
        <div>at support@aivirtualops.com, or by post at:</div>
        <div className="div-text">AiVirtualOps, LLC</div>
        <div>Attention: Cookie Policy Query</div>
        <div>8350 Bee Ridge Suite #157</div>
        <div>Sarasota FL, 34241</div>
      </div>
    </div>
  );
};

export default Body;
