import React, { useState, useEffect } from "react";
import "./Six.css";
import Logo from "../Images/aivops logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Image from "../Images/wondershare-filmora7141-removebg-preview.png";
import { toast } from "react-toastify";
import axios from "axios";
import ImageF from "../Images/still-27047b435fcc98d8e119bc13afee525f-removebg-preview.png";
import {ThreeDots} from 'react-loader-spinner';

const Six = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [sendMail, setSendMail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const[loading,setLoading]= useState(false)

  useEffect(() => {
    const handleScroll = () => {
      // Adjust the offset and condition based on your design
      const offset = 1000;
      const currentScrollPos = window.scrollY;

      const hasClosedPopup = sessionStorage.getItem("hasClosedPopup");

      if (!hasClosedPopup && currentScrollPos > offset) {
        setShowPopup(true);
      } else {
        setShowPopup(false);
      }

      // Show popup when reaching the specific section
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
    sessionStorage.setItem("hasClosedPopup", "true");
  };

  const handleSubmit=async(e)=>{
    e.preventDefault()
    setLoading(true)

    if( sendMail.length == 0){
      toast.error("Email is required", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false)
      return;
    }

    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    if (!emailRegex.test(sendMail)) {
      toast.error("Invalid Email Format", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false)
      return;
    }
    const data = await axios
    .post(
      `${process.env.REACT_APP_URL}/api/subscribeFooter`,
      { sendMail, firstName,secondName},
      {
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      }
    ).then(res =>{if(res.data.error){toast.error(res.data.data, {
      position: toast.POSITION.TOP_CENTER,
    });setLoading(false)}else if(res.data.msg == 'ok'){setShowPopup(false);toast.success("Subscribed", {
      position: toast.POSITION.TOP_CENTER,
    });setLoading(false)}else{toast.error("Some Error Occured", {
      position: toast.POSITION.TOP_CENTER,
    });setLoading(false)}}).catch(err=> {toast.error("Some Error Occured", {
      position: toast.POSITION.TOP_CENTER,
    });setLoading(false)})

    setFirstName('');setSecondName('');setSendMail('')

  }

  return (
    <div className="main-container-2" style={{ background: "white", padding:'0' }}>
      <div className="container-2 ">
        {/* right */}
        <h2 className=" text-center " style={{width:'100%'}}>The AI Tools We Use</h2>
        <div className="leftt ">
          <div className="container-six">
            <div className="box-six">
              <a className="flex justify-center" href="https://chat.openai.com/" target="_blank">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/ChatGPT_logo.svg/1024px-ChatGPT_logo.svg.png"
                  alt="ChatGPT"
                  className="logo-six"
                />
              </a>
              <p className="name">ChatGPT</p>
            </div>
            <div className="box-six">
              <a className="flex justify-center" href="https://canva.com/" target="_blank">
                <img
                  src="https://1000logos.net/wp-content/uploads/2023/02/Canva-logo.png"
                  alt="Canva"
                  className="logo-six"
                  style={{ width: "80%" }}
                />
              </a>
              <p className="name">Canva</p>
            </div>
            <div className="box-six">
              <a className="flex justify-center" href="https://filmora.wondershare.com/" target="_blank">
                <img
                  src="https://seeklogo.com/images/W/wondershare-filmora-video-editor-logo-0E17760862-seeklogo.com.png"
                  alt="Wondershare Filmora"
                  className="logo-six"
                  style={{ width: "50%" }}
                />
              </a>
              <p className="name">Wondershare Filmora</p>
            </div>
            <div className="box-six">
              <a className="flex justify-center" href="https://clickup.com/" target="_blank">
                <img
                  src={ImageF}
                  alt="ClickUp"
                  className="logo-six"
                  style={{ width: "80%" }}
                />
              </a>
              <p className="name">ClickUp</p>
            </div>
            <div className="box-six">
              <a className="flex justify-center" href="https://www.adobe.com/" target="_blank">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/40/Adobe_Premiere_Pro_CC_icon.svg/2101px-Adobe_Premiere_Pro_CC_icon.svg.png"
                  alt="Adobe Premier"
                  className="logo-six"
                />
              </a>
              <p className="name">Adobe Premier</p>
            </div>
            <div className="box-six">
              <a className="flex justify-center" href="https://grammarly.com/">
                <img
                  src="https://www.grammarly.com/media-resources/grammarlyicon-png"
                  alt="Grammarly"
                  className="logo-six"
                />
              </a>
              <p className="name">Grammarly</p>
            </div>
            <div className="box-six">
              <a className="flex justify-center" href="https://www.copy.ai/" target="_blank">
                <img
                  src="https://assets-global.website-files.com/628288c5cd3e8411b90a36a4/659e8f4c92a0028e36e42623_logo_kerning-fix.svg"
                  alt="Copy AI"
                  className="logo-six"
                />
              </a>
              <p className="name">Copy AI</p>
            </div>
            <div className="box-six">
              <a className="flex justify-center" href="https://trello.com" target="_blank">
                <img
                  src="https://logos-world.net/wp-content/uploads/2021/03/Trello-Logo.png"
                  alt="Trello"
                  className="logo-six"
                  style={{ width: "80%" }}
                />
              </a>
              <p className="name">Trello</p>
            </div>
            <div className="box-six">
              <a className="flex justify-center" href="https://www.kapwing.com/" target="_blank">
                <img
                  src="https://cdn-useast1.kapwing.com/static/9XE-logo-small.webp"
                  alt="Kapwing"
                  className="logo-six"
                />
              </a>
              <p className="name">Kapwing</p>
            </div>
            <div className="box-six">
              <a className="flex justify-center" href="https://otter.ai/" target="_blank">
                {/* <img
                  src="http://www.w3.org/2000/svg"
                  alt="Otter"
                  className="logo-six"
                  style={{ width: "80%" }}
                /> */}
                <svg  alt="Otter"
                  className="logo-six"
                  style={{ width: "80%" }}
                   viewBox="0 0 76 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.78943 40.6993C4.78943 36.7305 7.56641 33.8018 11.6082 33.8018C15.5039 33.8018 18.4101 36.5788 18.4101 40.6993C18.4101 44.8198 15.5039 47.5911 11.6082 47.5911C7.56079 47.5911 4.78943 44.668 4.78943 40.6993ZM16.0323 40.6993C16.0323 37.9279 14.1154 35.9492 11.6251 35.9492C8.98862 35.9492 7.18416 38.0572 7.18416 40.6993C7.18416 43.3413 8.98862 45.4437 11.6251 45.4437C14.0985 45.4437 15.9929 43.4538 15.9929 40.6993H16.0323Z" fill="#041D34"></path>
<path d="M21.0128 44.5161V39.9739H19.8885V38.1695H21.0128V35.0946L23.1377 34.8696V38.1695H25.8529V39.9739H23.1377V44.308C23.1377 45.258 23.4244 45.5954 24.0315 45.5954C24.5119 45.5716 24.9799 45.4349 25.3975 45.1963L25.9597 47.0401C25.2443 47.4483 24.427 47.6434 23.6043 47.6022C22.0022 47.591 21.0128 46.6016 21.0128 44.5161Z" fill="#041D34"></path>
<path d="M28.0791 44.5161V39.9739H26.9548V38.1695H28.0791V35.0946L30.204 34.8696V38.1695H32.9191V39.9739H30.204V44.308C30.204 45.258 30.4906 45.5954 31.0978 45.5954C31.5781 45.5716 32.0461 45.4349 32.4638 45.1963L33.0259 47.0401C32.3105 47.4483 31.4932 47.6434 30.6705 47.6022C29.0628 47.591 28.0791 46.6016 28.0791 44.5161Z" fill="#041D34"></path>
<path d="M42.8352 43.5099H36.0895C36.1931 44.1414 36.5291 44.7114 37.0315 45.1077C37.5339 45.504 38.1664 45.698 38.8047 45.6517C39.2513 45.664 39.6946 45.5731 40.1003 45.3859C40.5059 45.1987 40.8628 44.9201 41.1432 44.5723L42.6216 45.8428C41.8459 46.849 40.6878 47.5911 38.6866 47.5911C35.8759 47.5911 33.8803 45.7135 33.8803 42.8072C33.8803 39.901 35.8197 38.0403 38.5348 38.0403C41.1376 38.0403 42.9027 39.9966 42.9027 42.6948C42.8954 42.9676 42.8729 43.2397 42.8352 43.5099ZM36.0895 41.8235H40.7272C40.6453 41.2904 40.3716 40.8055 39.9577 40.4599C39.5437 40.1142 39.0178 39.9314 38.4786 39.9459C37.9242 39.9289 37.3819 40.1096 36.9484 40.4556C36.5148 40.8016 36.2184 41.2904 36.112 41.8347L36.0895 41.8235Z" fill="#041D34"></path>
<path d="M45.0952 38.1696H47.2201V39.558H47.2594C47.5835 39.0966 48.0122 38.7186 48.5105 38.4548C49.0089 38.1909 49.5626 38.0489 50.1264 38.0403H50.3962V40.4181C50.1489 40.4181 49.9015 40.3787 49.7497 40.3787C48.1308 40.3787 47.2201 41.5368 47.2201 43.0602V47.4449H45.0952V38.1696Z" fill="#041D34"></path>
<path d="M50.6041 46.1857C50.6041 45.8055 50.7551 45.4408 51.0239 45.172C51.2927 44.9032 51.6573 44.7522 52.0375 44.7522C52.4177 44.7522 52.7823 44.9032 53.0511 45.172C53.3199 45.4408 53.471 45.8055 53.471 46.1857C53.471 46.5659 53.3199 46.9305 53.0511 47.1994C52.7823 47.4682 52.4177 47.6192 52.0375 47.6192C51.6573 47.6192 51.2927 47.4682 51.0239 47.1994C50.7551 46.9305 50.6041 46.5659 50.6041 46.1857Z" fill="#041D34"></path>
<path d="M55.4273 42.8409C55.4273 40.0302 57.3442 38.0402 59.8907 38.0402C60.4338 38.0194 60.9745 38.1229 61.4714 38.3431C61.9683 38.5633 62.4083 38.8943 62.7576 39.3107H62.797V38.1864H64.8994V47.4561H62.8701V46.2419H62.8307C62.4902 46.6961 62.0438 47.06 61.5303 47.302C61.0169 47.544 60.4521 47.6568 59.8851 47.6304C57.3442 47.5911 55.4273 45.7135 55.4273 42.8409ZM62.8925 42.8409C62.8925 41.1882 61.7682 39.9346 60.2505 39.9346C58.7327 39.9346 57.5915 41.059 57.5915 42.8409C57.5632 43.2044 57.6113 43.5698 57.7328 43.9135C57.8544 44.2572 58.0466 44.5716 58.2971 44.8364C58.5477 45.1013 58.8509 45.3106 59.1874 45.451C59.5238 45.5914 59.886 45.6598 60.2505 45.6517C60.6098 45.6514 60.9654 45.578 61.2955 45.4359C61.6256 45.2938 61.9233 45.0859 62.1705 44.825C62.4177 44.5642 62.6092 44.2558 62.7333 43.9186C62.8574 43.5813 62.9116 43.2223 62.8925 42.8635V42.8409Z" fill="#041D34"></path>
<path d="M67.3729 35.3587C67.3773 34.9845 67.5291 34.6271 67.7953 34.364C68.0616 34.1009 68.4209 33.9533 68.7951 33.9534C69.0756 33.9545 69.3493 34.0388 69.5817 34.1956C69.8142 34.3524 69.9949 34.5747 70.1009 34.8343C70.2069 35.0939 70.2335 35.3792 70.1773 35.6539C70.1211 35.9287 69.9846 36.1805 69.7851 36.3776C69.5857 36.5747 69.3322 36.7081 69.0569 36.7611C68.7815 36.814 68.4966 36.784 68.2383 36.6749C67.98 36.5658 67.7598 36.3824 67.6058 36.1481C67.4517 35.9138 67.3707 35.6391 67.3729 35.3587ZM67.7327 38.1694H69.8632V47.4392H67.7102L67.7327 38.1694Z" fill="#041D34"></path>
<path fill-rule="evenodd" clip-rule="evenodd" d="M48.3161 0.4104C49.394 0.4104 50.4278 0.838496 51.19 1.6007C51.9522 2.3629 52.3804 3.39676 52.3804 4.47468V25.1895C52.3413 26.2406 51.8963 27.2355 51.1389 27.9653C50.3815 28.695 49.3707 29.1027 48.3189 29.1027C47.2672 29.1027 46.2564 28.695 45.499 27.9653C44.7416 27.2355 44.2966 26.2406 44.2574 25.1895V4.47468C44.2574 3.39774 44.6849 2.3648 45.4459 1.60276C46.2068 0.840716 47.2392 0.41189 48.3161 0.4104Z" fill="currentColor"></path>
<path fill-rule="evenodd" clip-rule="evenodd" d="M36.9441 0.4104C38.022 0.4104 39.0557 0.838496 39.8179 1.6007C40.5801 2.3629 41.0083 3.39676 41.0083 4.47468V25.1895C40.9692 26.2406 40.5242 27.2355 39.7668 27.9653C39.0094 28.695 37.9986 29.1027 36.9469 29.1027C35.8951 29.1027 34.8843 28.695 34.1269 27.9653C33.3695 27.2355 32.9245 26.2406 32.8854 25.1895V4.47468C32.8854 3.39774 33.3128 2.3648 34.0738 1.60276C34.8348 0.840716 35.8671 0.41189 36.9441 0.4104Z" fill="currentColor"></path>
<path fill-rule="evenodd" clip-rule="evenodd" d="M59.6884 9.75319C60.7663 9.75319 61.8001 10.1814 62.5623 10.9436C63.3245 11.7058 63.7527 12.7395 63.7527 13.8175V16.246C63.773 16.792 63.683 17.3365 63.488 17.847C63.293 18.3575 62.9972 18.8235 62.618 19.217C62.2389 19.6105 61.7842 19.9235 61.2814 20.1373C60.7785 20.3511 60.2376 20.4613 59.6912 20.4613C59.1447 20.4613 58.6039 20.3511 58.101 20.1373C57.5981 19.9235 57.1435 19.6105 56.7643 19.217C56.3852 18.8235 56.0893 18.3575 55.8943 17.847C55.6994 17.3365 55.6094 16.792 55.6297 16.246V13.8118C55.6297 12.7349 56.0571 11.702 56.8181 10.9399C57.5791 10.1779 58.6114 9.74905 59.6884 9.74756" fill="currentColor"></path>
<path fill-rule="evenodd" clip-rule="evenodd" d="M71.0829 5.69458C72.1598 5.69458 73.1928 6.12197 73.9548 6.88296C74.7168 7.64394 75.1457 8.67629 75.1472 9.75323V19.9056C75.1675 20.4516 75.0775 20.9961 74.8826 21.5066C74.6876 22.0171 74.3917 22.483 74.0125 22.8766C73.6334 23.2701 73.1787 23.5831 72.6759 23.7969C72.173 24.0107 71.6322 24.1209 71.0857 24.1209C70.5392 24.1209 69.9984 24.0107 69.4955 23.7969C68.9927 23.5831 68.538 23.2701 68.1589 22.8766C67.7797 22.483 67.4838 22.0171 67.2889 21.5066C67.0939 20.9961 67.0039 20.4516 67.0242 19.9056V9.75323C67.0242 8.67681 67.4518 7.64452 68.213 6.88337C68.9741 6.12222 70.0065 5.69458 71.0829 5.69458Z" fill="currentColor"></path>
<path d="M14.8237 21.8674C16.2359 21.8685 17.6168 21.4507 18.7916 20.6669C19.9663 19.8831 20.8823 18.7685 21.4235 17.4641C21.9647 16.1597 22.1069 14.724 21.832 13.3387C21.5572 11.9535 20.8777 10.6809 19.8795 9.68194C18.8813 8.68294 17.6092 8.00244 16.2242 7.72652C14.8391 7.45059 13.4034 7.59161 12.0985 8.13179C10.7937 8.67198 9.67839 9.58705 8.89367 10.7612C8.10896 11.9354 7.6901 13.3159 7.6901 14.7282C7.68936 15.6655 7.87335 16.5937 8.23152 17.4598C8.58968 18.326 9.11502 19.1129 9.77751 19.7759C10.44 20.4389 11.2267 20.9649 12.0925 21.3237C12.9584 21.6826 13.8864 21.8674 14.8237 21.8674ZM14.8237 29.6475C11.8918 29.6475 9.02582 28.778 6.58808 27.1491C4.15034 25.5203 2.25037 23.2051 1.1284 20.4965C0.00642523 17.7878 -0.287137 14.8073 0.284839 11.9317C0.856815 9.05623 2.26864 6.41485 4.34177 4.34172C6.41491 2.26859 9.05621 0.856832 11.9317 0.284857C14.8072 -0.287119 17.7878 0.00637444 20.4965 1.12834C23.2051 2.25031 25.5203 4.15035 27.1491 6.5881C28.778 9.02584 29.6474 11.8919 29.6474 14.8237C29.6474 18.7552 28.0856 22.5257 25.3056 25.3057C22.5256 28.0857 18.7552 29.6475 14.8237 29.6475Z" fill="currentColor"></path>
</svg>
              </a>
              <p className="name">Otter AI</p>
            </div>
          </div>
        </div>
      </div>
      {/* {showPopup && (
        <div className="popup-box md:w-1/3 sm:w-1/2  w-3/4" >
          <FontAwesomeIcon
            icon={faTimes}
            className="close-icon"
            onClick={handleClosePopup}
          />
          <img
            src={Logo} // Replace with the actual path to your logo
            alt="Logo"
            className="popup-logo"
          />
          <div
            style={{
              display: "flex",
              // gap: "20px",
              flexWrap:'wrap'
              // paddingLeft: "10px",
              // paddingRight: "10px",
            }}
          >
            <div className="md:w-1/2 w-full  flex justify-start">
            <input
              type="Name"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value.replace(/[^A-Za-z ]/g, ''))}
              className="w-full  md:w-11/12"
            />
            </div>
            <div className="md:w-1/2 w-full flex justify-end ">
            <input
              type="Name"
              placeholder="Last Name"
              value={secondName}
              onChange={(e) => setSecondName(e.target.value.replace(/[^A-Za-z ]/g, ''))}
              className="w-full  md:w-11/12"
              
            />
            </div>
          </div>
          <input
            type="email"
            placeholder="Enter your email"
            value={sendMail}
            onChange={(e) => setSendMail(e.target.value)}
            className="w-full"
          />
          <button
            onClick={(e) => handleSubmit(e)}
            className="learn-more"
            style={{ background: "#8cd22d" }}

          >
            Yes! I want to be updated with AI news!
          </button>
          <div className=' flex justify-center mx-auto mt-4'>
         {loading ? (
          // <div>hello</div>
            <ThreeDots type="Oval" position="top-center" className='d-flex justify-center' color="rgb(0,159,227)" height={50} width={50} />
           
          ) : (
            ''
          )}
          </div>
        </div>
      )} */}
      {showPopup && (
  <div className="popup-wrapper">
    <div style={{minWidth:'300px',maxWidth:'300px'}} className="popup-box ">
      <FontAwesomeIcon
        icon={faTimes}
        className="close-icon"
        onClick={handleClosePopup}
      />
      <img
        src={Logo} // Replace with the actual path to your logo
        alt="Logo"
        style={{height:'60px',width:'60px'}}
        className="popup-logo"
      />
      <div
        style={{
          display: "flex",
          flexWrap: 'wrap'
        }}
      >
        <div className="md:w-1/2 w-full flex justify-start">
          <input
            type="text"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value.replace(/[^A-Za-z ]/g, ''))}
            className="w-full md:w-11/12"
          />
        </div>
        <div className="md:w-1/2 w-full flex justify-end">
          <input
            type="text"
            placeholder="Last Name"
            value={secondName}
            onChange={(e) => setSecondName(e.target.value.replace(/[^A-Za-z ]/g, ''))}
            className="w-full md:w-11/12"
          />
        </div>
      </div>
      <input
        type="email"
        placeholder="Enter your email"
        value={sendMail}
        onChange={(e) => setSendMail(e.target.value)}
        className="w-full"
      />
      <button
        onClick={(e) => handleSubmit(e)}
        className="learn-more"
        style={{ background: "#8cd22d" }}
      >
        Yes! I want to be updated with AI news!
      </button>
      <div className='flex justify-center mx-auto mt-4'>
        {loading ? (
          <ThreeDots type="Oval" position="top-center" className='d-flex justify-center' color="rgb(0,159,227)" height={50} width={50} />
        ) : (
          ''
        )}
      </div>
    </div>
  </div>
)}
    </div>
  );
};

export default Six;
