// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section {
    z-index: 110;
    position: relative;
    width: 100%;
    background: #89c8ff;
    margin-top: 30px;
    padding-top: 30px;
}



.row-logo {
    /* padding-top: "30px"; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    /* background: #f9f9f9; */
}

.partners-logo {
    width: 100px;
    height: 50px;
}

@media (max-width:768px) {
    .partners-logo {
        width: 50px;
        height: 30px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Home/Feature.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,WAAW;IACX,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;AACrB;;;;AAIA;IACI,yBAAyB;IACzB,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI;QACI,WAAW;QACX,YAAY;IAChB;AACJ","sourcesContent":[".section {\n    z-index: 110;\n    position: relative;\n    width: 100%;\n    background: #89c8ff;\n    margin-top: 30px;\n    padding-top: 30px;\n}\n\n\n\n.row-logo {\n    /* padding-top: \"30px\"; */\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    text-align: center;\n    /* background: #f9f9f9; */\n}\n\n.partners-logo {\n    width: 100px;\n    height: 50px;\n}\n\n@media (max-width:768px) {\n    .partners-logo {\n        width: 50px;\n        height: 30px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
