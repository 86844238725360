import React from "react";

const Body = () => {
  return (
    <div className="profit-main">
      <div className="profit-second" style={{ marginBottom: "6vw" }}>
        <h1 style={{ textAlign: "center" }}>AIVirtualOps Privacy Policy</h1>
        <p>Last Modified on November 21, 2023</p>
        <p>Modified at Miami FL</p>
        <div className="div-text" style={{ fontSize: "25px" }}>
          1) Introduction
        </div>
        <p>
          Thank you for using AI Virtual Ops website, marketplace, AI Tools
          directory and all additional consulting and ancillary services all of
          which come under the umbrella of AIVirtualOps will link to this
          Privacy Policy, including aivirtualops.com (the “Sites”).
          AIVirtualOps, LLC (“AIVirtualOps,” “AI Virtual Ops,” “AIVO,” “we,”
          “us,” and/or “our”) operates these Sites to provide our users (“you”
          and “your”) with tools and information about AIVirtualOps and our
          services.
        </p>
        <p>
          AIVirtualOps sources and matches the world’s top AI Trained Virtual
          Assistants, Executive Assistants, Administrative Resources and
          Operational resources (“AIVO Associates”) in a variety of fields,
          industries and domains with clients who need the skills and expertise
          (“Clients”). AIVirtualOps also operates AI tools listing, marketplace
          and hiring solutions and consulting across our Sites that support AIVO
          Associates and Clients, such as our AIVO Copilot (an AI based
          operational automation tool) and many other similar widgets. Our
          tools, sourcing and matching service, and other offerings, such as
          matching Clients with appropriate AIVO Associates or providing
          outcomes-based deliverables, are referred as the “Services” for this
          policy. AIVirtualOps screens and trains AIVO Associates with skills in
          areas such as operations, regular admin work, sales operations,
          marketing operations, industry specific administrative work
          (healthcare, real estate, cyber security, manufacturing) to curate a
          proprietary network of highly skilled AIVO Associates and AIVO
          Community (the “Network”). We call all resources within our
          employee-owned company as AIVO Associates and all the leadership as
          AIVO Members, which is the origin of the name AIVO which stands for
          Artificial Intelligence Virtual Operations.
        </p>
        <p>
          AIVirtualOps values your privacy rights, and we take our
          responsibilities and obligations about your privacy rights seriously.
          We want you to know that AIVirtualOps is not in the business of
          selling your information. This privacy policy (“Privacy Policy”)
          explains our data practices in depth, so that you know how and why we
          collect and use your information in compliance with applicable data
          regulations.
        </p>
        <div className="div-text" style={{ fontSize: "25px" }}>
          2) Scope
        </div>
        <p>
          This Privacy Policy explains how we collect, retain, and use
          information, including personal data (“Personal Data”), about AIVO
          Associates, AIVO Members, Clients, potential Clients, AI Tools
          Partners, AI Tools with Affiliate partnership, Site visitors, vendors,
          blog readers, and AIVO Community job applicants, and others in
          accordance with applicable data protection laws and regulations as
          described below.
        </p>
        <div className="div-text" style={{ fontSize: "25px" }}>
          3) Collection and Use of Personal Data
        </div>
        <p>
          The information we receive and how we use it varies based on our
          relationship with you. The sections below explain our data practices
          based on our different relationships with users. Use these links to
          quickly navigate to the section relevant to you:
        </p>
        <ul>
          <li>AIVO Associates and AIVO Members;</li>
          <li>Clients and Prospective Clients;</li>
          <li>Job Applicants;</li>
          <li>Site Visitors;</li>
          <li>Software Users; and</li>
          <li>Event Attendees.</li>
        </ul>
        <div>a) AIVO Associates and AIVO Members</div>
        <p style={{ fontWeight: "bold" }}>i) Information We Collect</p>
        <p style={{ fontWeight: "bold" }}>
          1. Information That You Provide to Us
        </p>
        <p>
          We collect information from applicants and during our comprehensive
          screening process, we need to evaluate your qualifications such as
          your language skills, personality, domain-specific knowledge,
          competence, professionalism, knowledge of AI Tools and platforms and
          operational skills. If we admit you as a AIVO Associate or AIVO
          Member, we may also collect this information throughout your time in
          the Community. We may ask you to provide information about yourself,
          which could include your:
        </p>
        <ul>
          <li>Name;</li>
          <li>Contact information;</li>
          <li>Address;</li>
          <li>Location;</li>
          <li>Work experience;</li>
          <li>Education;</li>
          <li>References;</li>
          <li>Photo;</li>
          <li>Government-issued identification;</li>
          <li>Social network information; and</li>
          <li>Billing information.</li>
        </ul>
        <p>
          During the screening process or your time as a AIVO Associate or AIVO
          Member, we may also record your audio and video calls with us or with
          Clients to help document your skills or to be used for our quality
          assurance or training purposes. AIVO provides Associates and Members
          and our internal team members the use of a AIVirtualOps.com email
          address. AIVO stores and processes information sent to and from all
          AIVirtualOps.com email addresses.
        </p>
        <h3>2. Biometric Data and Retention</h3>
        <p>
          We may request that you provide information to verify and confirm your
          identity. As part of this identity verification, we may ask you to
          take a photo of yourself and of your government- issued identification
          through an application on your mobile phone (the “Verification Data”).
          The Verification Data is provided to our biometric technology service
          provider (a third party), who uses facial recognition technology to
          analyze the geometry of your face through the photographs you provide
          and extract biometric information or biometric identifiers relating to
          you (collectively, “Biometric Data”), as well as validate the
          legitimacy of your government- issued identification to confirm your
          identity. Our third party sends us scores that indicate whether they
          were able to verify your identity, a copy of the photo you submitted,
          and your name from your government-issued identification for reference
          and fraud investigation purposes pursuant to our legitimate interests.
          Our Third Party deletes the copy of your government-issued
          identification 180 days after it sends us the results of the
          verification in accordance with our agreement with Third Party.
        </p>
        <p>
          Our Third Party also uses the Verification Data to improve the
          accuracy, reliability, usability, and functionality of its identity
          verification technology, for fraud prevention and incident
          investigation efforts.
        </p>
        <p>
          Our Third Party does not sell or retain and permanently deletes your
          Biometric Data after sending us the information on whether your
          identity and government-issued identification have been verified. As
          noted above, AIVirtualOps does not receive the Biometric Data. In no
          event does our Third Party retain Biometric Data for longer than the
          purpose described above, or three years from your last interaction
          with AIVirtualOps, whichever is earlier unless required by law.
        </p>
        <h3>3. Background Checks</h3>
        <p>
          Based on your potential role as a Associate or Member, we may request
          a background check that will collect additional data about you through
          additional third-party service provider. If applicable to you, your
          express written consent to the collection of such additional data by
          the third-party service provider will be solicited at such time,
          pursuant to applicable laws.
        </p>
        <h3>4. Profile Creation</h3>
        <p>
          If we select you to be a AIVO Associate or Member, we may use some of
          your Personal Data to enable you to create a profile for us to display
          within the Services, which may be viewed by Clients seeking Ops
          resource. We may also display some of your information on public
          sections of AIVirtualOps.com, marketing materials, and presentations
          to showcase your skill sets or to demonstrate the value in the
          Network.
        </p>
        <h3>5. Client Feedback</h3>
        <p>
          If you become an AIVO Associate or Member, Clients you engage with
          will always provide us information about your performance.
        </p>
        <h3>6. Information Obtained from Third Parties</h3>
        <p>
          We may collect information, such as your name, contact information,
          address, work experience, and education from publicly available
          sources, third-party data providers, or services. This includes
          sources such as professional job boards or social media websites, like
          LinkedIn. We use this information to supplement the information that
          you provide us or to assess if you may be a good fit for the
          Community. We also collect information about you that you provide to
          our affiliates, such as AIVirtualOps AI Tool partners, or any of the
          future partners we may engage with.
        </p>
        <h3>7. Automatically Collected Data</h3>
        <p>
          When you interact with AIVirtualOps through the Site or services, we
          may automatically collect certain information. Please read the Site
          Visitors section below for more information.
        </p>
        <h3>ii) How We Use the Information</h3>
        <h3>1. To Provide the Service and Respond to Requests</h3>
        <p>
          We use your Personal Data to process your request to become a AIVO
          Associate or Member and administer our contracts with you and your
          engagements if you become a member of the Community.
        </p>
        <h3>2. For Certain Legitimate Interests</h3>
        <p>
          We also may use your Personal Data for certain business interests
          deemed legitimate under applicable law, including:
        </p>
        <ul>
          <li>
            To send you administrative information, for example, information
            regarding the Site and changes to our terms, conditions, and
            policies.
          </li>
          <li>
            To conduct analytics on how you use the Site and our Service for our
            internal purposes and to better understand your needs and interests
            to personalize your experience with our Services.
          </li>
          <li>
            To maintain our Services, including our standards of integrity and
            excellence in our Network.
          </li>
          <li>
            To market our Services and showcase Talent to actual and potential
            Clients.
          </li>
          <li>
            To help prevent fraud, criminal activity, and misuses of our
            products or Services and to ensure the security of our IT systems,
            architecture, and networks.
          </li>
          <li>
            For legal reasons, including to comply with legal obligations and
            legal processes, such as our compliance with sanctions about
            specific regions or specifically designated individuals, respond to
            requests from public and governmental authorities, enforce our
            agreements, protect our operations, protect our rights, privacy,
            safety or property, and/or that of you or others, and allow us to
            pursue available remedies or limit the damages that we may sustain.
          </li>
          <li>
            If you ask us to delete your data and we are required to fulfill
            your request, we will keep basic data to identify you to prevent
            fraud or further unwanted processing.
          </li>
        </ul>
        <h3>3. With Your Consent</h3>
        <p>
          We may also ask for your consent to use your Personal Data for a
          purpose that is not listed on this Privacy Policy, and we will use
          your Personal Data for that purpose to the extent that you freely
          provide your informed consent.
        </p>
        <p>
          We may monitor, record, and transcribe audio or video calls with you
          during the application process or your time as a Associate or Member.
          Consenting is voluntary, and you may withdraw your consent at any
          time. These recordings allow AiVirtualOps team members to review
          applications to the Community and Clients, to better review your
          suitability for a Client engagement, or for internal quality assurance
          and training purposes. Additionally, we may provide your interviews
          with Clients for specific engagements to Client stakeholders to review
          the call.
        </p>
        <p>
          We may collect your voice, image, in-meeting messages, and any other
          Personal Data you choose to share during such calls. In addition,
          other participants in a video conference may be able to view your
          display name, email address and profile picture. Any third-party
          software we use for these audio or video calls may also collect and
          use your Personal Data. Please review the terms of any third-party
          site for their specific privacy practices.
        </p>
        <p>
          For individuals in the EU and the UK, please see the EU and UK section
          below for information on our legitimate interests and your rights. For
          individuals that reside in California, please see the California
          section below for information on your rights.
        </p>
        <h3>b) Clients and Prospective Clients</h3>
        <h3>i) Information We Collect</h3>
        <h3>1. Information That You Provide to Us</h3>
        <p>
          We collect Personal Data from Clients and Prospective Clients
          (collectively “Clients”) when a Client visits our Sites and begins to
          use our Services as well as through requests from a Client to engage
          our Talent. This information may include, among other kinds of data:
        </p>
        <ul>
          <li>Names;</li>
          <li>Contact information;</li>
          <li>Job titles;</li>
          <li>Payment information;</li>
          <li>Address;</li>
          <li>Location; and</li>
          <li>
            Company information, including the type of operational resource(s)
            the Client is looking to engage and other information related to the
            Client’s needs.
          </li>
        </ul>
        <p>
          AIVirtualOps provides Associates and our internal team members the use
          of a AIVirtualOps.com email address. AIVirtualOps stores and processes
          information sent to and from all AIVirtualOps.com email addresses.
        </p>
        <h3>2. Information Obtained from Third Parties</h3>
        <p>
          We may collect additional information about you and your company from
          publicly available or from third-party databases or services that
          provide information about business contacts and companies (including
          individuals’ names, job titles, business contact information, and
          company information).
        </p>
        <h3>3. Automatically Collected Data</h3>
        <p>
          When you interact with AiVirtualOps through the Site or Services, we
          automatically collect certain information. Please read the “Site
          Visitors” section below for more information.
        </p>
        <h3>ii) How We Use the Information</h3>
        <h3>1. To Provide the Services and Respond to Requests</h3>
        <p>
          AiVirtualOps uses the Personal Data you provide consistent with this
          Privacy Policy. If you provide Personal Data for a certain reason, if
          we use the Personal Data, we will do so in connection with the reason
          for which you provided it. If you provide Personal Data in order to
          obtain access to the Services, we will use your Personal Data through
          our performance of our contract with you to provide you with access to
          such Services, maintain your account, contact you regarding your use
          of the Services, performance of our Associates and/or the Sites or to
          notify you of important changes to the Services and/or the Sites, and
          to monitor your use of such Services.
        </p>
        <h3>2. For Marketing Purposes</h3>
        <p>
          We may use your contact details to tell you about Services, upcoming
          events, and other promotions, AI Tools launch that we believe you will
          be interested in. If we do so, each marketing communication we send
          you will contain instructions permitting you to “opt out” of receiving
          future marketing communications. You cannot opt out of some
          administrative communications that are reasonably necessary to the
          Services, such as billing or Service notifications, as those are not
          considered marketing communications. In addition, if at any time you
          wish not to receive any future marketing communications or you wish
          for us to delete your contact information from our mailing lists,
          please contact us at support@aivirtualops.com or at our mailing
          address in the Contacting AIVirtualOps section below.
        </p>
        <p>
          Where required by applicable law, we will only send you marketing
          information by email if you consented to our use of your Personal Data
          for such communications.
        </p>
        <h3>3. For Certain Legitimate Interests</h3>
        <p>
          We may use your Personal Data for the certain business interests
          deemed legitimate under applicable law, including:
        </p>
        <ul>
          <li>
            To send you administrative information, for example, information
            regarding the Site and changes to our terms, conditions, and
            policies.
          </li>
          <li>
            To respond to your inquiries and fulfill your requests, such as to
            send you requested materials, newsletters and information and
            materials regarding our products and Services.
          </li>
          <li>
            To conduct analytics on how you use the Site and our Services for
            our internal purposes, for example, for maintaining, benchmarking,
            and improving our offerings, identifying usage trends, and
            determining the effectiveness of our promotional campaigns, and to
            inform our marketing strategy and personalize our communications
            with you.
          </li>
          <li>
            To supplement the information that we collected from you with
            information obtained from third parties (described above) to update,
            expand, and analyze our records, identify new Clients, and provide
            products and services that may be of interest to you.
          </li>
          <li>
            To prevent fraud, criminal activity, and misuses of our products or
            services and to ensure the security of our IT systems, architecture,
            and networks.
          </li>
          <li>
            For legal reasons, including to comply with legal obligations and
            legal process, respond to requests from public and government
            authorities including public and government authorities outside your
            country of residence, enforce our agreements with you, protect our
            operations, protect our rights, privacy, safety or property, and/or
            that of you or others, and allow us to pursue available remedies or
            limit the damages that we may sustain.
          </li>
          <li>
            If you ask us to delete your data and we are required to fulfill
            your request, to keep basic data to identify you and to prevent
            future fraud or further unwanted processing.
          </li>
        </ul>
        <h3>4. With Your Consent</h3>
        <p>
          We may also ask for your consent to use your Personal Data for a
          purpose that is not listed on this Privacy Policy, and we will use
          your Personal Data for that purpose to the extent that you freely
          provide your informed consent.
        </p>
        <p>
          We may monitor, record, and transcribe audio or video calls with you
          during your time as a Client. Consenting is voluntary, and you may
          withdraw it at any time. These actions allow multiple AIVirtualOps
          Associates and Members to review your requirements for sourcing or
          matching correct operational resources, specifics about a particular
          engagement, or for internal quality assurance and training purposes.
          Additionally, your interviews with Talent for specific engagements may
          be recorded for internal purposes and, upon request, provided to you
          to review the call.
        </p>
        <p>
          For individuals in the EU and the UK, please see the “EU and UK
          Individuals” section below for information on our legitimate interests
          and your rights. For individuals that reside in California, please see
          the California section below for information on your rights.
        </p>
        <h3>5. Credit Checks</h3>
        <p>
          We may request a credit check through a third-party service prior to
          providing a Client our Service, which will collect additional data
          about the Client.
        </p>
        <h3>c) Job Applicants</h3>
        <h3>i) Information We Collect</h3>
        <h3>1. Application and Assessment Process</h3>
        <p>
          When you apply for a position with AIVirtualOps, you will provide
          Personal Data in your application, resume, cover letter, or in other
          formats. While you may decide what information to include, for
          instance most applications include your:
        </p>
        <ul>
          <li>Name;</li>
          <li>Contact information;</li>
          <li>Qualifications;</li>
          <li>Recommendation information;</li>
          <li>Employment history;</li>
          <li>Education;</li>
          <li>Salary history; and</li>
          <li>Salary expectations.</li>
        </ul>
        <p>
          If we invite you to undertake further assessments in connection with
          your application and you participate in such assessments, we will
          collect the additional Personal Data that you provide to us as part of
          that process. If you do not provide us with certain information when
          requested, it may impact our ability to assess your suitability for a
          role with us or we may not be able to offer you a position with
          AIVirtualOps. With your prior consent, we may record your audio or
          video interview(s) to better evaluate your candidacy and for training
          purposes. Your consent is voluntary, and you may withdraw your consent
          at any time, including during the interview. If you withdraw your
          consent or do not consent, it will not impact your candidacy.
        </p>
        <p>
          Throughout the recruitment process, we may create Personal Data in
          connection with the assessment of your application, such as through
          your interviewer’s evaluation of your suitability for the role(s) that
          you applied for.
        </p>
        <h3>2. If Your Application is Successful</h3>
        <p>
          If your application is successful, we may collect further Personal
          Data about you in connection with conducting reference and background
          checks where required and permitted by applicable law, including, if
          applicable to you, special categories of Personal Data, which we will
          solicit your consent to collect at the time. We may also collect
          copies of relevant identification documents from you, such as your
          passport or driving license, proof of address, a copy of your work
          permit, a photograph, and a copy of your signature through your
          executed acceptance.
        </p>
        <p>
          Please see the “Region Specific Information” section below for
          information on your rights in relation to the Personal Data we hold
          about you.
        </p>
        <h3>3. Automatically Collected Data</h3>
        <p>
          When you interact with AIVirtualOps through our Site in relation to
          your application, we automatically collect certain information. Please
          read the “Site Visitors” section below for more information.
        </p>
        <h3>ii) How We Use Your Information</h3>
        <p>We have a legitimate interest in:</p>
        <ul>
          <li>Facilitating the interview process with you;</li>
          <li>Offering you roles to work for us;</li>
          <li>Making informed recruitment decisions;</li>
          <li>Selecting suitable candidates for roles with us;</li>
          <li>Improving our recruiting and hiring processes;</li>
          <li>Informing you of current and future career opportunities;</li>
          <li>Complying with immigration requirements;</li>
          <li>
            Verifying your identity for our own internal security purposes; and
          </li>
          <li>
            Complying with our legal obligations and for the performance of your
            contract with us.
          </li>
        </ul>
        <p>
          If you are engaged to work for AIVirtualOps, your Personal Data will
          be used as part of your team member record under our team member
          privacy policies and may be shared with third- party service providers
          assisting us in completing our obligations to you, such as payroll
          providers.
        </p>
        <h3>d) Site Visitors</h3>
        <p>
          When you interact with AIVirtualOps through the Site or the Services,
          we and service providers acting on our behalf will automatically
          collect information about you through cookies and other technologies.
          Please review the AIVirtualOps.com Cookie Policy or relevant cookie
          policy for one of our other websites to learn more about how we use
          cookies and other technologies on the specific Site you are using.
        </p>
        <p>
          As a Site Visitor, our servers record information (“log data”),
          including information that your browser automatically sends whenever
          you visit the Site. This log data includes your Internet Protocol
          (“IP”) address from which we understand the country you are connecting
          from at the time you visit the Site, browser type and settings, and
          the date and time of your request.
        </p>
        <p>
          We use this automatically collected data for our legitimate interests
          in maintaining the safe operation of our Site, maintaining our legal
          obligations and our legitimate interest in learning how Site visitors
          interact with our Site to improve your use of the Site.
        </p>
        <h3>e) Software Users</h3>
        <p>
          AIVirtualOps offers and maintains a variety of widgets, tools and
          sub-platforms for Associates, Members, Third Party Vendors and
          Affiliate Partners and Clients to use, including:
        </p>
        <ul>
          <li>Ops CoPilot</li>
          <li>AI Tools Directory and Affiliates</li>
          <li>AIVO Headhunting</li>
        </ul>
        <p>
          When you use AIVirtualOps software, we collect account level data,
          including your name, email, and password, to facilitate your access,
          storage of historical data, and operation of software. If you create
          an account by using credentials from a third-party service or sign-in
          services such as your Google account, these services will authenticate
          your identity and provide you with the option to share certain
          Personal Data, such as name and email address(es), with us. Use of
          account level data is necessary to perform a contract with us in
          relation to your use of the software or it is in our legitimate
          interest to respond to your requests and ensure we provide our
          Services in the best way that we can.
        </p>
        <p>
          When you use AIVirtualOps software, we may collect usage statistics
          for analytics and to support and troubleshoot the software to provide
          a better user experience. We have a legitimate interest in conducting
          usage statistics to ensure our software is working as intended and to
          make improvements to our Services. We use service providers to assist
          in authentication of users and cloud hosting the storage and retrieval
          of screenshots.
        </p>
        <p>
          AIVirtualOps software includes online components and cookies that are
          required for full functionality. For more information about the Sites
          cookie usage please see AIVirtual Ops cookie policy.
        </p>
        <h3>f) Event Attendees</h3>
        <p>
          We may collect Personal Data from you when you attend one of our
          events, for instance at business conferences and trade shows. The data
          we collect includes information that you voluntarily give to us
          directly or through granting access to your Personal Data through the
          event, such as contact details on a business card. We will use this
          information to develop a potential business relationship with you
          including marketing our Services to you consistent with the purpose
          for which you gave the information.
        </p>
        <p>
          Where required by applicable law, we will ask your consent before or
          when sending you marketing information by email. When you provide us
          with your consent to be contacted for marketing purposes, you have the
          right to withdraw your consent at any time by following the
          instructions to “opt-out” of receiving marketing communication in each
          marketing email we send you or by contacting us
          at support@aivirtualops.com or by mail at the address in the
          Contacting AIVirtualOps section below.
        </p>
        <h3>4) Disclosing Your Personal Data</h3>
        <p>
          AiVirtualOps is not in the business of selling your information. We
          consider information about you and our interactions to be a vital part
          of our relationship with you. There are, however, certain
          circumstances in which we may share your Personal Data with certain
          third parties, as explained below:
        </p>
        <div className="div-text">1. Business Transfers</div>
        <p>
          As we develop our business, we might acquire businesses or assets. In
          the event of a corporate sale, merger, reorganization, dissolution or
          similar event, Personal Data may be part of the transferred assets.
        </p>
        <div className="div-text">
          2. Agents, Consultants and Other Service Providers
        </div>
        <p>
          AiVirtualOps, like many businesses, hires third parties to perform
          certain business-related functions. These third parties include
          website analytics companies, providers of digital advertising
          services, our hosting and cloud computing service providers, providers
          of customer relationship management software, marketing and sales
          software solutions, providers of billing and processing payments
          functions and providers of background check services. When we engage
          another company to perform a function of this nature, we only provide
          them with the information that they need to perform their specific
          function. Pursuant to our instructions, these parties may access,
          process, or store Personal Data in the course of performing their
          duties to us and solely in order to perform the services we hired them
          to provide.
        </p>
        <p>
          If you enter financial information into one of the Sites to process a
          payment, Stripe, our payment processor, will receive the information
          you enter and process the payment in accordance with Stripe’s Privacy
          Policy.
        </p>
        <div className="div-text">3. Legal Requirements</div>
        <p>
          AiVirtualOps may disclose your Personal Data if required to do so by
          law, for example, as part of our compliance with sanctions about
          specific regions and specifically designated individuals, or in the
          good faith belief that such action is necessary to (i) comply with a
          legal obligation, (ii) protect and defend the rights or property of
          AIVirtualOps, (iii) act in urgent circumstances to protect the
          personal safety of users of the Site or the public, or (iv) protect
          against legal liability.
        </p>
        <h3>5) Data Retention</h3>
        <p>
          Our policy is to keep your Personal Data only for as long as is
          reasonably necessary to fulfill the purposes for which it was
          collected and processed, including for the purposes of satisfying any
          legal, regulatory, accounting or reporting requirements. If you
          elected to receive marketing communications from us, we retain
          information about your marketing preferences until you opt out of
          receiving these communications and in accordance with our policies.
        </p>
        <p>
          To determine the appropriate retention period for your Personal Data,
          we will consider the amount, nature, and sensitivity of the Personal
          Data, the potential risk of harm from unauthorized use or disclosure
          of your Personal Data, the purposes for which we use your Personal
          Data and whether we can achieve those purposes through other means,
          and the applicable legal requirements. In some circumstances we may
          anonymize your Personal Data so that it can no longer be associated
          with you, in which case it is no longer Personal Data.
        </p>
        <h3>6) Region Specific Information</h3>
        <p>
          You may be able to exercise certain rights and access certain
          information depending on your location. Please review the following
          sections for more details. You can reach out
          to support@aivirtualops.com with questions about your information.
        </p>
        <h3>a) EU and UK Individuals</h3>
        <div className="div-text">(1) Scope</div>
        <p>
          This section applies solely to individuals in the United Kingdom and
          European Union, provided however that reference to the EU also
          includes certain non-EU countries including, where applicable,
          Switzerland and the European Economic Area countries of Iceland,
          Liechtenstein, and Norway. Our Privacy Policy describes why and how
          AiVirtualOps collects, uses, and stores your Personal Data, the lawful
          basis on which your Personal Data is processed, and what your rights
          and our obligations are in relation to such processing (please see
          “Your Rights” section below).
        </p>
        <div className="div-text">(2) Data Controller</div>
        <p>
          AiVirtualOps is the data controller for processing your Personal Data.
          The data controller is responsible for deciding how Personal Data
          about you is used. Please contact us with any questions
          at support@aivirtualops.com or at the address provided in the
          Contacting AiVirtualOps section below, which also provides the contact
          details of our representative in the EU and the UK for purposes of the
          General Data Protection Regulation and the United Kingdom’s Data
          Protection Act 2018.
        </p>
        <div className="div-text">(3) Your Rights</div>
        <p>
          Subject to applicable EU and UK law and where possible, you have the
          following rights in relation to your Personal Data:
        </p>
        <ul>
          <li>
            <span className="div-text">Right of access.</span>
             You may ask us to confirm whether we are processing your Personal
            Data and, if so, provide you with a copy of that Personal Data along
            with certain other details. This may include the purposes of the
            processing, the categories of Personal Data, the recipients of the
            Personal Data, the right to lodge a complaint, and available
            information on the source of the Personal Data. If you require
            additional copies, we may charge a reasonable fee.
          </li>
          <li>
            <span className="div-text">Right to rectification.</span>
             If your Personal Data is inaccurate or incomplete, you may ask that
            we erase, amend, or rectify it. If we shared your Personal Data with
            others, we will tell them about the correction where possible. If
            you ask us, we will also tell you with whom we shared your Personal
            Data so you can contact them directly.
          </li>
          <li>
            <span className="div-text">Right to erasure.</span>
             You may ask us to delete or remove your Personal Data in certain
            circumstances, such as where our processing is based on your
            consent. We may retain certain information consistent with
            applicable law, such as to prevent fraud, or where we have a
            compelling legitimate ground to do so. If we shared your data with
            others, we will tell them about the erasure where possible. If you
            ask us, we will also tell you with whom we shared your Personal Data
            with so you can contact them directly.
          </li>
          <li>
            <span className="div-text">Right to restrict processing.</span>
             You may ask us to restrict or ‘block’ the processing of your
            Personal Data in certain circumstances. We will tell you before we
            lift any restriction on processing. If we shared your Personal Data
            with others, we will tell them about the restriction where possible.
            If you ask us, we will also tell you with whom we shared your
            Personal Data so you can contact them directly.
          </li>
          <li>
            <span className="div-text">Right to data portability.</span>
             You may ask to obtain the Personal Data that you consented to give
            us or that was provided to us as necessary in connection with our
            contract with you. We will give you your Personal Data in a
            structured, commonly used, and machine-readable format.
          </li>
          <li>
            <span className="div-text">Right to object.</span>
             You may ask us at any time to stop processing your Personal Data,
            and we will do so unless we have a compelling legitimate ground for
            the processing. We will comply with requests to stop processing your
            Personal Data as your requests apply to direct marketing.
          </li>
          <li>
            <span className="div-text">
              Rights in relation to automated decision-making and profiling.
            </span>
             You have the right to be free from decisions based solely on
            automated processing of your Personal Data, including profiling,
            which produce a significant legal effect on you, unless such
            profiling is necessary for entering into, or the performance of, a
            contract between you and us, or with your explicit consent. We use
            automated decision-making to ensure that we comply with various laws
            and regulations restricting what regions, individuals, and entities
            we are able to work with. We also use automated decision- making to
            ensure applications from Freelancers and Job Applicants meet our
            minimum necessary requirements. You may contact us
            at support@aivirtualops.com to obtain an explanation of the decision
            or for a personal review, if possible.
          </li>
          <li>
            <span className="div-text">Right to withdraw consent.</span>
             If we rely on your consent to process your Personal Data, you have
            the right to withdraw that consent at any time, but this will not
            affect any data that we already processed.
          </li>
          <li>
            <span className="div-text">
              Right to lodge a complaint with the data protection authority.
            </span>
             If you have a concern about our privacy practices, including the
            way we handled your Personal Data, you can report it to the data
            protection authority that is authorized to hear those concerns.
          </li>
        </ul>
        <p>
          You may exercise your rights by contacting us as indicated under the
          “Contacting AiVirtualOps“ section below.
        </p>
        <div className="div-text">(4) Legitimate Interest</div>
        <p>
          “Legitimate interests” means our interests in conducting and managing
          our organization and delivering the best Services to you. This Privacy
          Policy describes when we process your Personal Data for our legitimate
          interests, what these interests are and your rights. We will not use
          your Personal Data for activities where the impact on you outweighs
          our interests unless we have your consent or those activities are
          otherwise required or permitted by law. You have the right to object
          to processing that is based on our legitimate interests. For more
          information on your rights, please see the “Your Rights” section
          above.
        </p>
        <div className="div-text">(5) Data Transfers</div>
        <p>
          AIVirtualOps is based in the United States. When you apply as a
          Associate, use our Services as a Client, or otherwise use our Sites,
          your Personal Data will be transmitted to servers in the United States
          as necessary to provide you with the Services that you requested,
          administer our contract with you or to respond to your requests as
          described in this Privacy Policy, and your Personal Data may be
          transmitted to our service providers supporting our business
          operations (described above). The United States may have data
          protection laws less stringent than or otherwise different from the
          laws in effect in the country in which you are located. Where we
          transfer your Personal Data across the border of the European Economic
          Area or United Kingdom, we will take steps to ensure that your
          Personal Data receives an adequate level of protection where it is
          processed and your rights continue to be protected.
        </p>
        <h3>b) California</h3>
        <p>
          Our Privacy Policy describes above why and how AiVirtualOps collects,
          uses, and stores your Personal Data, the lawful basis on which your
          Personal Data is processed, and what your rights and our obligations
          are in relation to such processing.
        </p>
        <p>
          If you are a California resident, you, or a person verifiably
          authorized to act on your behalf, have certain rights under the
          California Consumer Privacy Act and its successor legislation, the
          California Privacy Rights Act, collectively the “CCPA.” For the
          purpose of this section, certain words, including “sale,” “share,”
          “business purpose,” and “personal information” have the meanings
          defined in the CCPA. For the purpose of this Privacy Policy, we use
          “Personal Data” to cover “personal information” as defined in the
          CCPA.
        </p>
        <p>
          We update our Privacy Policy based on our current practices. As
          required by the CCPA, the information in this section is relevant for
          the last twelve-month time period. During that time, we collected the
          categories of Personal Data listed in the relevant section above
          (i.e., Freelancers, Clients, Job Applicants, or Site Visitors) from
          the sources listed in those sections (i.e., from you, third parties,
          or automatically). During the last twelve-month period, we have not
          sold Personal Data.
        </p>
        <p>
          During the last twelve-month period, we disclosed for business
          purposes the following categories of Personal Data:
        </p>
        <ul>
          <li>
            Identifiers (i.e., names, email addresses, or online identifiers and
            IP addresses).
          </li>
          <li>Biometric information (i.e., video and voice recordings).</li>
          <li>
            Internet activity (i.e., information regarding your interaction with
            our websites or advertisements).
          </li>
          <li>
            Geolocation data (i.e., information regarding where you access our
            site from).
          </li>
          <li>
            Professional or employment information (i.e., job title, employer,
            or prior titles and employers).
          </li>
        </ul>
        <p>
          For information about who we may share or disclose your Personal Data
          to, please review the relevant information in the above Freelancers,
          Clients, Job Applicants, or Site Visitors sections.
        </p>
        <p>
          Your rights under the CCPA include the following, which you may
          execute by contacting us at support@aivirtualops.com where possible
          and allowed by law:
        </p>
        <ul>
          <li>
            <span className="div-text">
              Right to request deletion of your Personal Data.
            </span>
             You may ask us to delete or remove your Personal Data. If we shared
            your Personal Data with others, we will tell them about the deletion
            where possible. If you ask us, we will also tell you with whom we
            shared your Personal Data with so you can contact them directly.
          </li>
          <li>
            <span className="div-text">
              Right to request AiVirtualOps correct inaccurate Personal Data.
            </span>
             If your Personal Data is inaccurate or incomplete, you are entitled
            to ask that we correct or complete it. If we shared your Personal
            Data with others, we will tell them about the correction where
            possible. If you ask us, we will also tell you with whom we shared
            your Personal Data so you can contact them directly.
          </li>
          <li>
            <span className="div-text">
              Right to know what Personal Data is sold or shared and with whom.
            </span>
            AiVirtualOps is not in the business of selling your Personal Data.
            You may ask us to clarify what Personal Data, if any, is shared and
            with whom we share it, along with certain additional details.
          </li>
          <li>
            <span className="div-text">
              Right to opt out of sale or sharing of Personal Data for
              behavioral advertising and right to not be retaliated against for
              opting out.
            </span>
            If you would like to opt out of AiVirtualOps sharing your Personal
            Data with third parties for cross-context behavioral advertising,
            you have the right to do so. To opt out:
          </li>
          <ul>
            <li>Email your request to support@aivirtualops.com; and</li>
            <li>
              Disable the use of analytics/advertising cookies as we describe in
              our Cookie Policy. You must complete this step on each of our
              websites from each browser and on each device that you use.
            </li>
            <li>
              If you block cookies, we may be unable to comply with your opt out
              request with respect to device data that we automatically collect
              and disclose to third parties online using cookies, pixels, and
              other tracking technologies. If you clear your cookies, you may
              need to disable the use of all advertising cookies again.
            </li>
          </ul>
          <li>
            <span className="div-text">
              Right to access your Personal Data.
            </span>
            If you ask us, we will confirm whether we are processing your
            Personal Data and, if so, provide you with a copy of that Personal
            Data along with certain other details. If you require additional
            copies, we may charge a reasonable fee.
          </li>
          <li>
            <span className="div-text">Shine the Light Requests.</span>
            You may also request that we provide you with a list of the
            categories of Personal Data, if any, that we disclosed to third
            parties for their direct marketing purposes during the immediately
            preceding calendar year and the identity of those third parties.
          </li>
          <li>
            <span className="div-text">Right to Non-Discrimination.</span>
            AiVirtualOps will not discriminate against you if you exercise any
            of your rights under the CCPA.
          </li>
        </ul>
        <p>
          If you would like to exercise your rights or have questions about your
          rights or information, please contact us at support@aivirtualops .com
          or at the post addresses included in the Contacting AIVirtualOps
          section below.
        </p>
        <h3>7) Your Choices</h3>
        <p>
          You can use the Sites without providing any Personal Data. If you
          choose not to provide any Personal Data, you may not be able to use
          certain Services.
        </p>
        <h3>8) Exclusions</h3>
        <p>
          This Privacy Policy does not apply to any Personal Data collected by
          AiVirtualOps other than Personal Data collected through the Sites,
          Services, events, third parties or as otherwise stated in this Privacy
          Policy. This Privacy Policy shall not apply to any unsolicited
          information you provide to AiVirtualOps through this Site or through
          any other means (without prejudice to your rights under the applicable
          law). This includes, but is not limited to, information posted to any
          public areas of the Sites, such as forums (collectively, “Public
          Areas”), any ideas for new products or modifications to existing
          products, and other unsolicited submissions (collectively,
          “Unsolicited Information”). All Unsolicited Information shall be
          deemed to be non-confidential and AiVirtualOps shall be free to
          reproduce, use, disclose, distribute, and exploit such Unsolicited
          Information without limitation or attribution.
        </p>
        <h3>9) Children</h3>
        <p>
          AIVirtualOps does not knowingly collect Personal Data from children
          under the age of 16. If you are under the age of 16, please do not
          submit any Personal Data through the Sites. We encourage parents and
          legal guardians to monitor their children’s Internet usage and to help
          enforce our Privacy Policy by instructing their children never to
          provide Personal Data on these Sites without their permission. If you
          have reason to believe that a child under the age of 16 provided
          Personal Data to AIVirtualOps through this Sites, please contact us,
          and we will endeavor to delete that information from our databases.
        </p>
        <h3>10) Links to Other Websites</h3>
        <p>
          The Sites may contain links to other websites not operated or
          controlled by AIVirtualOps (“Third Party Sites”). The information that
          you share with Third Party Sites will be governed by the specific
          privacy policies and terms of service of the Third-Party Sites and not
          by this Privacy Policy. By providing these links we do not imply that
          we endorse or review the Third-Party Sites. We suggest contacting
          those sites directly for information on their privacy practices and
          policies.
        </p>
        <h3>11) Security</h3>
        <p>
          AIVirtualOps takes reasonable and appropriate steps to protect the
          Personal Data provided via the Sites or the services from loss,
          misuse, and unauthorized access, disclosure, alteration, or
          destruction. However, no Internet, email or other electronic
          transmission is ever fully secure or error free, so you should take
          special care in deciding what information you send to us in this way.
        </p>
        <h3>12) Other Terms and Conditions</h3>
        <p>
          Your access to and use of aivirtualops.com is subject to our Website
          Terms &amp; Conditions. Your access to and use of other Sites may be
          subject to different terms and conditions. Please review those Sites
          for more information.
        </p>
        <h3>13) Changes to AIVirtualOps Privacy Policy</h3>
        <p>
          The Sites and our business may change from time to time. As a result,
          at times it may be necessary for AIVirtualOps to make changes to this
          Privacy Policy. AiVirtualOps reserves the right to update or modify
          this Privacy Policy at any time and from time to time without prior
          notice, unless otherwise required by the applicable law. Please review
          this policy periodically, and especially before you provide any
          Personal Data. Your continued use of the Site after any changes or
          revisions to this Privacy Policy shall indicate your agreement with
          the terms of such revised Privacy Policy, without prejudice to your
          rights under the applicable law.
        </p>
        <h3>14) Contacting AIVirtualOps</h3>
        <p>
          Please contact us if you have any questions about AIVirtualOps Privacy
          Policy, the information practices of the Sites, or if you have
          questions about your rights.
        </p>
        <p>
          We have a team responsible for overseeing questions regarding this
          Privacy Policy. You may contact the AIVirtualOps Privacy Team as
          follows: by email at support@aivirtualops.com, or by mail at:
        </p>
        <div>AiVirtualOps, LLC</div>
        <div>Attention: Cookie Policy Query</div>
        <div>8350 Bee Ridge Suite #157</div>
        <div>Sarasota FL, 34241</div>
      </div>
    </div>
  );
};

export default Body;
