import React, { useEffect } from "react";
import Maincontent from "./Maincontent";
import Body from "./Body";
import Footer from "../Home/Footer";

const Privacy = () => {
  useEffect(() => {
    // Scroll to the top when the component mounts (page loads)
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Maincontent />
      <Body />
      <Footer />
    </div>
  );
};

export default Privacy;
