// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-container {
    display: inline-block;
    position: relative;
}

.dropdown-header {
    display: flex;
    align-items: center;
    width: 300px;
    margin-left: 40px;
    margin-top: 30px;
    padding: 10px;
    border: 1px solid #ccc;
    cursor: pointer;
}

.dropdown-header-arrow {
    margin-left: auto;
    transform: rotate(0deg);
    transition: transform 0.3s;
}

.dropdown-header-arrow.open {
    transform: rotate(180deg);
}

.dropdown-options {
    position: absolute;
    list-style: none;
    padding: 0;
    margin-left: 40px;
    margin-top: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    width: 300px;
    z-index: 99;
}

.dropdown-options li {
    padding: 10px;
    cursor: pointer;
}

.dropdown-options li:hover {
    background-color: #f0f0f0;
}
@media (max-width:600px) {
    .dropdown-header{
        width: 200px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Toolpage/Dropdown.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,iBAAiB;IACjB,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,uBAAuB;IACvB,0BAA0B;AAC9B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,UAAU;IACV,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,sBAAsB;IACtB,gBAAgB;IAChB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;AACA;IACI;QACI,YAAY;IAChB;AACJ","sourcesContent":[".dropdown-container {\n    display: inline-block;\n    position: relative;\n}\n\n.dropdown-header {\n    display: flex;\n    align-items: center;\n    width: 300px;\n    margin-left: 40px;\n    margin-top: 30px;\n    padding: 10px;\n    border: 1px solid #ccc;\n    cursor: pointer;\n}\n\n.dropdown-header-arrow {\n    margin-left: auto;\n    transform: rotate(0deg);\n    transition: transform 0.3s;\n}\n\n.dropdown-header-arrow.open {\n    transform: rotate(180deg);\n}\n\n.dropdown-options {\n    position: absolute;\n    list-style: none;\n    padding: 0;\n    margin-left: 40px;\n    margin-top: 0;\n    background-color: #fff;\n    border: 1px solid #ccc;\n    border-top: none;\n    width: 300px;\n    z-index: 99;\n}\n\n.dropdown-options li {\n    padding: 10px;\n    cursor: pointer;\n}\n\n.dropdown-options li:hover {\n    background-color: #f0f0f0;\n}\n@media (max-width:600px) {\n    .dropdown-header{\n        width: 200px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
