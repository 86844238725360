// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog-section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3vw;
}

.blog-item {
    width: 100%;
    border: 5px solid blue;
    border-radius: 8px;
    padding: 20px;
}

.blog-item img {
    width: 100%;
    border-radius: 8px;
    height:'200px'
}
.bii {
    width: 100%;
    border-radius: 8px;
    height:'200px'
}

.blog-item h3 {
    margin: 10px 0;
}

.blog-item p {
    color: #555;
}

/* .blog-item button {
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #6C63FF;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
} */
@media (max-width:1280px) {
    .blog-section{
        grid-template-columns: repeat(3,1fr);
    }
}
@media (max-width:1080px) {
    .blog-section{
        grid-template-columns: repeat(2,1fr);
    }
}
@media (max-width:840px) {
    .blog-section{
        grid-template-columns: repeat(2,1fr);
    }
}
@media (max-width:600px) {
    .blog-section{
        grid-template-columns: repeat(1,4fr);
    }
}

.box{
    min-width: 100%;
}
.box:hover {
    /* background-color: rgba(255, 255, 0, 0.9); */
    transform:  scale(1.03);
    transition: transform 0.4s;
}`, "",{"version":3,"sources":["webpack://./src/Blog/BlogSection.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qCAAqC;IACrC,QAAQ;AACZ;;AAEA;IACI,WAAW;IACX,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB;AACJ;AACA;IACI,WAAW;IACX,kBAAkB;IAClB;AACJ;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,WAAW;AACf;;AAEA;;;;;;;;GAQG;AACH;IACI;QACI,oCAAoC;IACxC;AACJ;AACA;IACI;QACI,oCAAoC;IACxC;AACJ;AACA;IACI;QACI,oCAAoC;IACxC;AACJ;AACA;IACI;QACI,oCAAoC;IACxC;AACJ;;AAEA;IACI,eAAe;AACnB;AACA;IACI,8CAA8C;IAC9C,uBAAuB;IACvB,0BAA0B;AAC9B","sourcesContent":[".blog-section {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    gap: 3vw;\n}\n\n.blog-item {\n    width: 100%;\n    border: 5px solid blue;\n    border-radius: 8px;\n    padding: 20px;\n}\n\n.blog-item img {\n    width: 100%;\n    border-radius: 8px;\n    height:'200px'\n}\n.bii {\n    width: 100%;\n    border-radius: 8px;\n    height:'200px'\n}\n\n.blog-item h3 {\n    margin: 10px 0;\n}\n\n.blog-item p {\n    color: #555;\n}\n\n/* .blog-item button {\n    margin-top: 10px;\n    padding: 8px 16px;\n    background-color: #6C63FF;\n    color: #fff;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n} */\n@media (max-width:1280px) {\n    .blog-section{\n        grid-template-columns: repeat(3,1fr);\n    }\n}\n@media (max-width:1080px) {\n    .blog-section{\n        grid-template-columns: repeat(2,1fr);\n    }\n}\n@media (max-width:840px) {\n    .blog-section{\n        grid-template-columns: repeat(2,1fr);\n    }\n}\n@media (max-width:600px) {\n    .blog-section{\n        grid-template-columns: repeat(1,4fr);\n    }\n}\n\n.box{\n    min-width: 100%;\n}\n.box:hover {\n    /* background-color: rgba(255, 255, 0, 0.9); */\n    transform:  scale(1.03);\n    transition: transform 0.4s;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
