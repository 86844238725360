import React, { useState } from "react";
import "./Faq.css";
import Question from "./Question";
import Questions from "./Questions";
import Questiont from "./Questiont";
import Questionf from "./Questionf";
import Questioni from "./Questioni";
import Questionx from "./Questionx";
import Question7 from "./Question7";
import Question8 from "./Question8";
import Question9 from "./Question9";

const Faq = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="profit-main" style={{}}>
      <div className="profit-second" style={{ marginBottom: "4vw" }}>
        <h1 className="mt-8 mb-5" style={{ textAlign: "center", fontSize: "2rem" }}>FAQ</h1>
        <h3 className="mt-8 mb-5" style={{ textAlign: "center" }}>
          Answers to frequently asked questions about AI Virtual Ops
        </h3>
        <div
          // style={{ marginLeft: "100px", marginRight: "100px" }}
          className="question-section"
        >
          <Question />
          <Questions />
          <Questiont />
          <Questionf />
          <Questioni />
          <Questionx />
          <Question7 />
          <Question8 />
          <Question9 />
          {/* <Questions />
          <Questiont /> */}
        </div>
      </div>
    </div>
  );
};

export default Faq;
