import React, { useState, useEffect } from "react";
import "./Boxes.css";
import { faCheck, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Boxes = () => {
  const [scrollY, setScrollY] = useState(0);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const translateY = `translateY(-${scrollY * 0.08}px)`;
  return (
    <div style={{ width: "100%", background: "#f9f9f9" }}>
      <h1 style={{ textAlign: "center", paddingTop: "4vw" , paddingBottom: "1vw"}}>Our Packages</h1>
      <div className="inside-package-box">
        <div
          className="pricing-container"
          style={{
            // transform: translateY,
            transition: "transform 0.1s ease-in-out",
          }}
        >
          <div className="pricing-box-start"
           
          >
            <h3
              style={{
                color: "#e30613",
                textAlign: "center",
                fontFamily: "Pancetta Pro",
              }}
            >
              Starter Sprint
            </h3>
            <p className="htp" style={{ marginTop: "25px", 
            // height: "100px" 
            }}>
              This package is best for individuals who are only looking for
              assistance in finding the best matched VA for his or her needs.
            </p>
            <h3
              style={{
                textAlign: "center",
                display: "block",
              }}
              className="py-4"
            >
              TBD
            </h3>
            <p className="buy-now">
              <a
                href="https://calendly.com/sassy-aivo/30min?month=2023-11"
                target="_blank"
                className="learn-more"
                style={{ textDecoration: "none" }}
              >
                CONTACT US
              </a>
            </p>

            <h6 className="disclaimer" style={{ display: "block" }}>
              <strong>* 1 month free of charge</strong>
              <br />
              <strong>with annual payment</strong>
            </h6>
            <div className="features">
              <p style={{ textAlign: "center" }}>
                <strong>Features</strong>
              </p>
              <ul>
                <li>
                  <div className="first-box ">
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="second-checkbox "
                    />
                    AI Virtual Ops will match the client with an appropriate VA
                  </div>
                </li>
                <li>
                  <div className="first-box">
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="second-checkbox"
                    />
                    AI Virtual Ops will understand your needs and find the best match for
                    your specific needs
                  </div>
                </li>
                <li>
                  <div className="first-box">
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="second-checkbox"
                    />
                    One-time payment only
                  </div>
                </li>
                {/* <li>1 project</li>
                <li>Drag-and-drop smart briefs</li>
                <li>Automated text generation</li>
                <li>End-to-end content workflow</li>
                <li>...and many more features!</li> */}
              </ul>
            </div>
          </div>
          <div className="pricing-box-pro">
            <h3
              style={{
                color: "#009640",
                textAlign: "center",
                fontFamily: "Pancetta Pro",
              }}
            >
              Team Thrives
            </h3>
            <p
            className="htp" 
              style={{
                marginTop: "25px",
                // height: "100px",
              }}
            >
              This package is best for small business owners looking for
              assistance in specific areas.
            </p>
            <h3
              style={{
                textAlign: "center",
                display: "block",
              }}
              className="py-4"
            >
              TBD
            </h3>
            <p className="buy-now">
              <a
                href="https://calendly.com/sassy-aivo/30min?month=2023-11"
                target="_blank"
                className="learn-more"
                style={{ textDecoration: "none" }}
              >
                CONTACT US
              </a>
            </p>
            <h6 className="disclaimer">
              <strong>* 1 month free of charge</strong>
              <br />
              <strong>with annual payment</strong>
            </h6>
            <div className="features">
              <p style={{ textAlign: "center" }}>
                <strong>Features</strong>
              </p>
              <ul>
                <li>
                  <div className="first-box">
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="second-checkbox"
                    />
                    Customized service depending on your needs
                  </div>
                </li>
                <li>
                  <div className="first-box">
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="second-checkbox"
                    />
                    AI Virtual Ops will train the best matched Operations
                    Assistant based on your business requirements
                  </div>
                </li>
                <li>
                  <div className="first-box">
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="second-checkbox"
                    />
                    Assistance to one business only
                  </div>
                </li>
                <li>
                  <div className="first-box">
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="second-checkbox"
                    />
                    Ideally part-time needs come under this package
                  </div>
                </li>
                {/* <li>Automated text generation</li>
                <li>End-to-end content workflow</li>
                <li>...and many more features!</li> */}
              </ul>
            </div>
          </div>
          <div className="pricing-box-business">
            <h3
              style={{
                color: "#009fe3",
                textAlign: "center",
                fontFamily: "Pancetta Pro",
              }}
            >
              Pro Pulse
            </h3>
            <p className="htp"  style={{ marginTop: "25px",
            //  height: "100px" 
             }}>
              This package is best for individuals who are looking for a
              done-for-you, end-to-end operations assistance, with multiple
              businesses.
            </p>
            <h3 style={{ textAlign: "center" }}  className="py-4">TBD</h3>
            <p className="buy-now">
              <a
                href="https://calendly.com/sassy-aivo/30min?month=2023-11"
                target="_blank"
                className="learn-more"
                style={{ textDecoration: "none" }}
              >
                CONTACT US
              </a>
            </p>
            <h6 className="disclaimer">
              <strong>* 1 month free of charge</strong>
              <br />
              <strong>with annual payment</strong>
            </h6>
            <div className="features">
              <p style={{ textAlign: "center" }}>
                <strong>Features</strong>
              </p>
              <ul>
                <li>
                  <div className="first-box">
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="second-checkbox"
                    />
                    All-time high quality service operations assistance
                  </div>
                </li>
                <li>
                  <div className="first-box">
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="second-checkbox"
                    />
                    AI Virtual Ops will find the best match Operations Assistant
                    for you and your business
                  </div>
                </li>
                <li>
                  <div className="first-box">
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="second-checkbox"
                    />
                    AI Virtual Ops will train the Operations Assistants based on
                    your business requirements
                  </div>
                </li>
                <li>
                  <div className="first-box">
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="second-checkbox"
                    />
                    Discounted AI Tool Subscriptions based on our partnerships
                  </div>
                </li>
                <li>
                  <div className="first-box">
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="second-checkbox"
                    />
                    Ideally suited for full-time resource needs
                  </div>
                </li>
                {/* <li>...and many more features!</li> */}
              </ul>
            </div>
          </div>
          <div className="pricing-box-enterprise">
            <h3
              style={{
                color: "#f39200",
                textAlign: "center",
                fontFamily: "Pancetta Pro",
              }}
            >
              Enterprise Elevate
            </h3>
            <p
            className="htp" 
              style={{
                fontSize: "1rem",
                marginTop: "25px",
                // height: "100px",
              }}
            >
              This package is great for big business and companies looking for a
              more customized service.
            </p>
            <div>
              <h3 style={{ textAlign: "center" }}  className="py-4">call for quote</h3>
              <p className="buy-now">
                <a
                  href="https://calendly.com/sassy-aivo/30min?month=2023-11"
                  target="_blank"
                  className="learn-more"
                  style={{ textDecoration: "none" }}
                >
                  CONTACT US
                </a>
              </p>
            </div>
            <h6 className="disclaimer">
              <strong>* 1 month free of charge</strong>
              <br />
              <strong>with annual payment</strong>
            </h6>
            <div className="features">
              <p>
                <strong>Expandable and Customized Features</strong>
              </p>
              <ul>
                <li>
                  <div className="first-box">
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="second-checkbox"
                    />
                    All-time high quality service operations assistance
                  </div>
                </li>
                <li>
                  <div className="first-box">
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="second-checkbox"
                    />
                    AI Virtual Ops will find the best match Operations Assistant
                    for you and your business
                  </div>
                </li>
                <li>
                  <div className="first-box">
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="second-checkbox"
                    />
                    AI Virtual Ops will train the Operations Assistants based on
                    your business requirements
                  </div>
                </li>
                <li>
                  <div className="first-box">
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="second-checkbox"
                    />
                    Free AI tool subscription
                  </div>
                </li>
                <li>
                  <div className="first-box">
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="second-checkbox"
                    />
                    Assistance with the business team
                  </div>
                </li>
                <li style={{ marginLeft: "20px" }}>and more</li>
              </ul>
            </div>
          </div>
          {/* Add more PricingBox components for other packages */}
        </div>
        <div className="bottom-box">
          <h3 className="boxes-second-part me-3">
            Get your AI Virtual Ops Assistant now!
          </h3>
          
        </div>
        <div className="bottom-box-2">
     
          <button style={{backgroundColor:'#8CD22D',color:'white'}} className="buy-now1 px-4 py-3 mt-4 mb-8 rounded-md">
            <a className="">START YOUR FREE TRIAL NOW</a>
            {/* <button className="learn-more">START YOUR FREE TRIAL NOW</button> */}
          </button>
        </div>
      </div>
    </div>
  );
};

const PricingBox = ({ title, color, description, link, features }) => {
  const [showDetails, setShowDetails] = useState(false);

  const handleBoxClick = () => {
    setShowDetails(!showDetails);
  };

  return (
    <div
      className={`pricing-box ${showDetails ? "active" : ""}`}
      style={{ cursor: "pointer" }}
      onClick={handleBoxClick}
    >
      <h3 style={{ color: color, textAlign: "center" }}>{title}</h3>
      {!showDetails && <p style={{ marginTop: "25px" }}>{description}</p>}
      {showDetails && (
        <>
          <h3
            style={{ textAlign: "center", marginTop: "40px", display: "block" }}
          >
            TBD
          </h3>
          <p className="buy-now">
            <a href={link} className="buy-link">
              CONTACT US
            </a>
          </p>
          <h6 className="disclaimer">
            <strong>* 1 month free of charge</strong>
            <br />
            <strong>with annual payment</strong>
          </h6>
          <div className="features">
            <p style={{ textAlign: "center" }}>
              <strong>Features</strong>
            </p>
            <ul>
              {features.map((feature, index) => (
                <li key={index}>
                  <div className="first-box">
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="second-checkbox"
                    />
                    {feature}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default Boxes;
