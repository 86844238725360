import React from "react";

const Header = () => {
  return (
    <div>
      <div
        style={{
          width: "100%",
          height: "60px",
          // background: "#009fe3",
          display: "flex",
          alignItems: "center",
          marginTop: "75px",
          justifyContent: "center",
        }}
      >
        <span
          style={{
            color: "white",
            fontWeight: "bold",

            fontSize: "1.5rem",
          }}
        >
          {/* Contact Us */}
        </span>
      </div>
    </div>
  );
};

export default Header;
