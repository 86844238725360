import React from "react";
import "./Whatwedo.css";
import Video4 from "../Images/Homepage - AI Animation 1.mp4";
import Video from "../Images/Engineering.mp4";
import Video2 from "../Images/support.mp4";
import Vidoe3 from "../Images/startup.mp4";
import { Link } from "react-router-dom";

const Whatwedo = () => {
  return (
    <div class="container-what">
      <h1
        style={{
          position: "sticky",
          zIndex: "100",
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "4rem",
          lineHeight:'4rem',
          color: '#ff9102'
        }}
      >
        What we do
      </h1>
      {/* <ul className="d-flex " id="cards">
        <li className="card-what " id="card1">
          <div class="card-body">
            <div className="second-what-container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "426px",
                  height: "424px",
                  position: "relative",
                }}
              >
                <video
                  autoPlay
                  muted
                  loop
                  style={{
                    borderRadius: "10%",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                >
                  <source src={Video4} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="inside-contain">
                <div className="inside-contain-first">
                  <p
                    style={{ textAlign: "justify", textJustify: "inter-word" }}
                  >
                    AI Virtual Ops transforms operations assistance with AI
                    augmentation, enhancing business operations through seamless
                    integration of advanced technology. Our skilled team employs
                    cutting-edge AI to boost productivity, automate tasks, and
                    excel in diverse operational scenarios. Expect a tailored
                    approach that surpasses traditional methods, utilizing AI to
                    optimize processes, automate tasks, and elevate overall
                    business performance. Welcome to a future where efficiency
                    meets innovation at AI Virtual Ops.
                  </p>
                </div>
                <div className="inside-contain-second">
                  <h2>Artificial Intelligence Augmentation</h2>
                </div>
                <div className="inside-contain-third">
                  <button className="learn-more" style={{ marginTop: "0px" }}>
                    Learn More
                  </button>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li className="card-what " id="card2">
          <div class="card-body">
            <div className="second-what-container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "426px",
                  height: "424px",
                  position: "relative",
                }}
              >
                <video
                  autoPlay
                  muted
                  loop
                  style={{
                    borderRadius: "10%",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                >
                  <source src={Video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="inside-contain">
                <div className="inside-contain-first">
                  <p
                    style={{ textAlign: "justify", textJustify: "inter-word" }}
                  >
                    AI Virtual Ops is your strategic partner in the dynamic
                    world of artificial intelligence. Through partnerships with
                    leading AI companies, we stay at the forefront of
                    innovation, ensuring our team is equipped with the latest
                    advancements. Our mission extends beyond staying current; we
                    actively promote the widespread use of AI in businesses,
                    leveraging our expertise to drive transformative solutions
                    for clients globally.
                  </p>
                </div>
                <div className="inside-contain-second">
                  <h2>Partner in Artificial Intelligence Use</h2>
                </div>
                <div className="inside-contain-third">
                  <button className="learn-more" style={{ cursor: "pointer" }}>
                    Learn More
                  </button>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li className="card-what " id="card3" >
          <div class="card-body">
            <div className="second-what-container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "426px",
                  height: "424px",
                  position: "relative",
                }}
              >
                <video
                  autoPlay
                  muted
                  loop
                  style={{
                    borderRadius: "10%",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                >
                  <source src={Video2} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="inside-contain">
                <div className="inside-contain-first">
                  <p
                    style={{ textAlign: "justify", textJustify: "inter-word" }}
                  >
                    AI Virtual Ops is a community-centric platform dedicated to
                    supporting virtual assistants and job seekers. We provide
                    free job-seeking assistance, AI tools training, and foster
                    collaboration for growth and development.
                  </p>
                </div>
                <div className="inside-contain-second">
                  <h2>Community Support</h2>
                </div>
                <div className="inside-contain-third">
                  <button className="learn-more" style={{ cursor: "pointer" }}>
                    Learn More
                  </button>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li className="card-what " id="card4">
          <div class="card-body">
            <div className="second-what-container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "426px",
                  height: "424px",
                  position: "relative",
                }}
              >
                <video
                  autoPlay
                  muted
                  loop
                  style={{
                    borderRadius: "10%",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                >
                  <source src={Vidoe3} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="inside-contain">
                <div className="inside-contain-first">
                  <p
                    style={{ textAlign: "justify", textJustify: "inter-word" }}
                  >
                    AI Virtual Ops, an employee-owned company, prioritizes its
                    team's well-being by offering sensible benefits, competitive
                    compensation, and a platform where every voice matters. We
                    invest in the financial and mental health support of our
                    employees, believing that their happiness is the key to
                    success, creating a positive and dynamic work environment.
                  </p>
                </div>
                <div className="inside-contain-second">
                  <h2>Employee-owned Startup Company</h2>
                </div>
                <div className="inside-contain-third">
                  <button className="learn-more" style={{ cursor: "pointer" }}>
                    Learn More
                  </button>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul> */}

      <ul className="flex px-0" id="cards">
        <li className="card-what " id="card1">
          <div class="card-body">
            <div className="second-what-container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // width: "426px",
                  // height: "424px",
                  minWidth:'250px',
                  minHeight:'250px',
                  position: "relative",
                }}
              >
                <video
                  autoPlay
                  muted
                  loop
                  style={{
                    borderRadius: "20px",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    // maxWidth:'300px',
                    height: "100%",
                    objectFit: "cover",
                  }}
                >
                  <source src={Video4} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="inside-contain">
                <div className="inside-contain-first">
                 <h4 style={{marginTop:'10px',marginBottom:'5px'}}>AI Virtual Ops: Streamlining business operations and boosting productivity through seamless AI integration
                </h4>
                  <ul
                    style={{ textAlign: "justify", textJustify: "inter-word" }}
                  >
                   <li>Seamless Integration of Advanced Technology:
                   </li>
                   <li>Boost Productivity
                   </li>
                   <li>Automate Tasks
                   </li>
                   <li>Tailored Approach
                   </li>
                   <li>Elevate Business Performance
                   </li>
                  </ul>
                </div>
                <div className="inside-contain-second">
                  <h2>Enhancing Operations with AI </h2>
                 
        
                </div>
                {/* <div className="inside-contain-third mt-2 mb-4">
                  <button className="learn-more " style={{ cursor: "pointer",marginTop:'0px',transition: "color 0.3s, transform 0.3s" }}
                   onMouseEnter={(e) => {
                    e.target.style.color = "black";
                    e.target.style.transform = "scale(1.05)"; // Increase size by 5%
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.color = "white";
                    e.target.style.transform = "scale(1)"; // Return to normal size
                  }}>
                  
                    Learn More
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </li>
        <li className="card-what " id="card2">
          <div class="card-body">
            <div className="second-what-container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // width: "426px",
                  // height: "424px",
                  minWidth:'250px',
                  minHeight:'250px',
                  position: "relative",
                }}
              >
                <video
                  autoPlay
                  muted
                  loop
                  style={{
                    borderRadius: "20px",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                >
                  <source src={Video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="inside-contain">
                <div className="inside-contain-first">
                  <h4 style={{marginTop:'10px',marginBottom:'5px'}}>AI Virtual Ops: Your strategic partner in the dynamic world of artificial intelligence.
                  </h4>
                  <ul
                    style={{ textAlign: "justify", textJustify: "inter-word" }}
                  >
                   <li>Leading the Way in AI Innovation</li>
                   <li>Equipped with Cutting-Edge Knowledge</li>
                   <li>Promoting Widespread AI Adoption
                   </li>
                   <li>Driving Transformative Solutions</li>
                   <li>Global Impact
                   </li>
                  </ul>
                </div>
                <div className="inside-contain-second">
                  <h2>Partner in Artificial Intelligence Use
                  </h2>
                </div>
                {/* <div className="inside-contain-third mt-2 mb-4">
                <button className="learn-more " style={{ cursor: "pointer",marginTop:'0px',transition: "color 0.3s, transform 0.3s" }}
                   onMouseEnter={(e) => {
                    e.target.style.color = "black";
                    e.target.style.transform = "scale(1.05)"; // Increase size by 5%
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.color = "white";
                    e.target.style.transform = "scale(1)"; // Return to normal size
                  }}>
                    Learn More
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </li>
        <li className="card-what " id="card3" >
          <div class="card-body">
            <div className="second-what-container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // width: "426px",
                  // height: "424px",
                  minWidth:'250px',
                  minHeight:'250px',
                  position: "relative",
                }}
              >
                <video
                  autoPlay
                  muted
                  loop
                  style={{
                    borderRadius: "20px",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                >
                  <source src={Video2} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="inside-contain">
                <div className="inside-contain-first">
                   <h4 style={{marginTop:'10px',marginBottom:'5px'}}>AI Virtual Ops: Empowering Virtual Assistants and Job Seekers
                  </h4>
                  <ul
                    style={{ textAlign: "justify", textJustify: "inter-word" }}
                  >
                    <li>Free Job-Seeking Assistance</li>
                    <li>AI Tools Training
                    </li>
                    <li>Community-Centric Approach
                    </li>
                    <li>Growth and Development</li>
                    <li>Supportive Environment
                    </li>

                  </ul>
                </div>
                <div className="inside-contain-second">
                  <h2>Community support</h2>
                </div>
                {/* <div className="inside-contain-third mt-2 mb-4">
                <button className="learn-more " style={{ cursor: "pointer",marginTop:'0px',transition: "color 0.3s, transform 0.3s" }}
                   onMouseEnter={(e) => {
                    e.target.style.color = "black";
                    e.target.style.transform = "scale(1.05)"; // Increase size by 5%
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.color = "white";
                    e.target.style.transform = "scale(1)"; // Return to normal size
                  }}>
                    Learn More
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </li>
        <li className="card-what " id="card4">
          <div class="card-body">
            <div className="second-what-container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // width: "426px",
                  // height: "424px",
                  minWidth:'250px',
                  minHeight:'250px',
                  position: "relative",
                }}
              >
                <video
                  autoPlay
                  muted
                  loop
                  style={{
                    borderRadius: "20px",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                >
                  <source src={Vidoe3} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="inside-contain">
                <div className="inside-contain-first">
                   <h4 style={{marginTop:'10px',marginBottom:'5px'}}>AI Virtual Ops: Empowering Employees for a Brighter Future
                  </h4>
                  <ul
                    style={{ textAlign: "justify", textJustify: "inter-word" }}
                  >
                   <li>Employee-Owned Structure</li>
                   <li>Sensible Benefits</li>
                   <li>Competitive Compensation</li>
                   <li>Financial and Mental Health Support</li>
                   <li>Positive and Dynamic Work Environment</li>
                  </ul>
                </div>
                <div className="inside-contain-second">
                  <h2>Employee-owned Startup Company</h2>
                </div>
                {/* <div className="inside-contain-third mt-2 mb-4">
                <button className="learn-more " style={{ cursor: "pointer",marginTop:'0px',transition: "color 0.3s, transform 0.3s" }}
                   onMouseEnter={(e) => {
                    e.target.style.color = "black";
                    e.target.style.transform = "scale(1.05)"; // Increase size by 5%
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.color = "white";
                    e.target.style.transform = "scale(1)"; // Return to normal size
                  }}>
                    Learn More
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Whatwedo;
