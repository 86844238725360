import React from "react";
import "./Seven.css";

const Seven = () => {
  return (
    <div
      className="main-container-7"
      style={{ background: "#fff", marginTop: "50px" }}
    >
      <div className="container-2">
        {/* right */}
        <div className="right-2">
          <h2 className="main-2">Hire Your New AI Team Member Now</h2>
          <img
            src="https://rellify.com/wp-content/uploads/2023/09/rellify-farbbalken_500x13-300x8.png"
            alt=""
            width="300"
            style={{ marginBottom: "30px" }}
          />
          <div style={{ marginBottom: "30px", fontSize: "20px" }}>
            One AI-guided process. Less work. Outstanding performance.
          </div>
          <div style={{ marginBottom: "30px", fontWeight: "bold" }}>
            Try our Article Intelligence application now!
          </div>
        </div>
        <div className="left-7">
          <div className="main-7">30 Days. No risk.</div>
          <div className="main-7">No charge.</div>
          <button
            style={{
              padding: "20px",
              width: "200px",
              background: "orange",
              marginTop: "100px",
              marginBottom: "50px",
            }}
          >
            Get Started Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default Seven;
