import React, { useEffect, useState, useRef } from "react";
import "./Profit.css";

const Profit = () => {
  const [percentage, setPercentage] = useState(0);
  const [first, setfirst] = useState(0);
  const [third, setthird] = useState(48);
  const [second,setSecond] = useState(48)
  const [four, setfour] = useState(0);
  const [animationfour, setanimationfour] = useState(false);
  const [animationthird, setanimationthird] = useState(false);
  const [animationHasRun, setAnimationHasRun] = useState(false);
  const [animationfirst, setanimation] = useState(false);
  const animatedRef = useRef(null);
  const animated = useRef(null);
  const animate = useRef(null);
  const animat = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !animationHasRun) {
          // Run the animation when the component becomes visible
          animateFill();
          setAnimationHasRun(true);
          observer.unobserve(animatedRef.current);
        }
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    if (animatedRef.current) {
      observer.observe(animatedRef.current);
    }

    return () => {
      if (animatedRef.current) {
        observer.unobserve(animatedRef.current);
      }
    };
  }, [animationHasRun]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !animationfirst) {
          // Run the animation when the component becomes visible
          animateFirst();
          setanimation(true);
          observer.unobserve(animated.current);
        }
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    if (animated.current) {
      observer.observe(animated.current);
    }

    return () => {
      if (animated.current) {
        observer.unobserve(animated.current);
      }
    };
  }, [animationfirst]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !animationthird) {
          // Run the animation when the component becomes visible
          animatethird();
          setanimationthird(true);
          observer.unobserve(animate.current);
        }
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    if (animate.current) {
      observer.observe(animate.current);
    }

    return () => {
      if (animate.current) {
        observer.unobserve(animate.current);
      }
    };
  }, [animationthird]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !animationfour) {
          // Run the animation when the component becomes visible
          animatefour();
          setanimationfour(true);
          observer.unobserve(animat.current);
        }
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    if (animat.current) {
      observer.observe(animat.current);
    }

    return () => {
      if (animat.current) {
        observer.unobserve(animat.current);
      }
    };
  }, [animationfour]);

  const animateFill = () => {
    const animationDuration = 2000;
    const startTime = Date.now();

    const animationFrame = () => {
      const currentTime = Date.now();
      const elapsed = currentTime - startTime;

      if (elapsed < animationDuration) {
        const newPercentage = (elapsed / animationDuration) * 20;
        setPercentage(newPercentage);
        requestAnimationFrame(animationFrame);
      } else {
        setPercentage(20);
      }
    };

    animationFrame();
  };
  const animateFirst = () => {
    const animationDuration = 2000;
    const startTime = Date.now();

    const animationFrame = () => {
      const currentTime = Date.now();
      const elapsed = currentTime - startTime;

      if (elapsed < animationDuration) {
        const newPercentage = (elapsed / animationDuration) * 5;
        setfirst(newPercentage);
        requestAnimationFrame(animationFrame);
      } else {
        setfirst(5);
      }
    };

    animationFrame();
  };
  const animatethird = () => {
    const animationDuration = 2000;
    const startTime = Date.now();

    const animationFrame = () => {
      const currentTime = Date.now();
      const elapsed = currentTime - startTime;

      if (elapsed < animationDuration) {
        const newPercentage = 48 - (elapsed / animationDuration) * 48;
        setthird(newPercentage);
        requestAnimationFrame(animationFrame);
      } else {
        setthird(0);
      }
    };

    animationFrame();
  };
  const animatefour = () => {
    const animationDuration = 2000;
    const startTime = Date.now();

    const animationFrame = () => {
      const currentTime = Date.now();
      const elapsed = currentTime - startTime;

      if (elapsed < animationDuration) {
        const newPercentage = (elapsed / animationDuration) * 36;
        setfour(newPercentage);
        requestAnimationFrame(animationFrame);
      } else {
        setfour(36);
      }
    };

    animationFrame();
  };
  return (
    <div className="profit-main" style={{}}>
      <div className="profit-second">
        <h2 className="my-8" style={{ textAlign: "center", fontSize: "2rem" }}>
          Advantages of working with AI Virtual Ops
        </h2>
        <div
          className="pricing-container"
          style={{ width: "100%", marginBottom: "50px" }}
        >
          <div className="pricing-box">
            <h3 className="box-font" ref={animatedRef}>
              + {Math.round(percentage)}%
            </h3>
            <p style={{ textAlign: "center" }}>Reduce Monthly Costs</p>
          </div>
          <div className="pricing-box" ref={animated}>
            <h3 className="box-font"> {Math.round(first)} Hours</h3>
            <p style={{ textAlign: "center" }}>Saved PM hours per week</p>
          </div>
          <div className="pricing-box" ref={animate}>
            <h3 className="box-font"> {Math.round(third)} Hours</h3>
            <p style={{ textAlign: "center" }}>
              New VA Training and Onboarding
            </p>
          </div>
          <div className="pricing-box" ref={animat}>
            <h3 className="box-font">+ {Math.round(four)}%</h3>
            <p style={{ textAlign: "center" }}>Efficiency and Productivity</p>
          </div>
          {/* <div className="pricing-box">
            <h3 className="box-font">+ 50 %</h3>
            <p style={{ textAlign: "center" }}>Content ROI</p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Profit;
