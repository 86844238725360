import React, { useState } from "react";
import Logo from "../Images/aivops logo.png";
import "./Header.css";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import design from "../Images/AIVO line.png";


const Header = () => {
  const [showButtons, setShowButtons] = useState(true);

  const toggleButtons = () => {
    setShowButtons(!showButtons);
  };
  return (
    <>
      {/* <div
        style={{
          width: "100%",
          height: "60px",
          background: "#009fe3",
          display: "flex",
          alignItems: "center",
          marginTop: "75px",
          justifyContent: "center",
        }}
      >
        <span
          style={{
            color: "white",
            fontWeight: "bold",
            fontSize: "1.5rem",
          }}
        >
          AI Tools
        </span>
      </div> */}
      <div className="main-tool-div " style={{ marginTop: "60px" }}>
        <div className="container-tool-head">
          {/* <img src={Logo} alt="" className="logo-tool-box" /> */}
          <div className="title-tool-box" style={{ marginTop: "90px" }} >
            The AI Tools We Currently Use In Our Work
          </div>
          {/* <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={design} alt="" className="image-line" />
          </div> */}
      {/* <img src={design} alt="" style={{ width: "35%" }} /> */}

        </div>
      </div>
    </>
  );
};

export default Header;
