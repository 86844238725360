import React from "react";
import "./Four.css";
import Line from "../Images/AIVO line.png";

const Four = () => {
  const areas = [
    "General Admin",
    "Executive Assistance",
    "Real Estate",
    "Healthcare",
    "Startup",
    "Customer Support",
    "Events and Conference Support",
    "L1-L2 Support",
    "Research",
    "Customer Support",
    "Events and Conference Support",
    "Social Media Management",
  ];
  return (
    <div className="container-style" style={{}}>
      <div className="container-3">
        {/* right */}
        <div className="">
          <h2 className="main-3 mx-2 text-center" style={{ color: "white", fontSize: "3rem",paddingRight:'0px',paddingLeft:'0px' }}>
            Empowering Success in Every Area
          </h2>
          {/* <img src={Line} alt="" style={{ width: "35%" }} /> */}
          <div
            style={{
              marginTop: "30px",
              fontSize: "30px",
              textAlign: "center",
              color: "white",
            }}
          >
            We offer exceptional support in these areas,
          </div>
          <div
            style={{
              fontSize: "30px",
              marginBottom: "30px",
              textAlign: "center",
              color: "white",
            }}
          >
            while also extending our capabilities beyond them
          </div>
          <div
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "30px",
              textAlign: "center",
              color: "white",
            }}
          >
            If Your Niche Isn't Listed, Let's Chat and Get Creative!
          </div>
        </div>
      </div>
      <div className="main-container-2">
        <div className="container-2">
          {/* right */}
          <div className="grid-container-4" style={{ marginBottom: "50px" }}>
            {areas.map((area, index) => (
              <div key={index}  className="box-4">
                {area}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Four;
