import React, { useState, useEffect } from "react";
import axios from "axios";
import Footer from "../Home/Footer";
import { FaBloggerB } from "react-icons/fa";
import { toast } from 'react-toastify'
import { ThreeDots } from 'react-loader-spinner';

import { Editor } from "react-draft-wysiwyg";
import { EditorState, AtomicBlockUtils, convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from 'draft-convert';
import draftToHtml from "draftjs-to-html";


const AddBlog = () => {


  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );

  const [convertedContent, setConvertedContent] = useState(null);

  // const [imgtest, setImgtest] = useState([]);

  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());

    console.log(html, "-----------25")
    setConvertedContent(html);
    setBlogDescription(html)


    // const tempElement = document.createElement('div');
    // tempElement.innerHTML= draftToHtml(convertToRaw(editorState.getCurrentContent()))
    // const imgTags = Array.from(tempElement.getElementsByTagName('img'));
    // const imgAttributesArray = imgTags.map(img => ({
    //   src: img.getAttribute('src'),
    // }));

    // setImgtest(imgAttributesArray)

  }, [editorState]);

  console.log(convertedContent);




  const [loading, setLoading] = useState(false)

  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const [BlogName, setBlogName] = useState("");
  // const [BlogLogo, setBlogLogo] = useState(null);
  const [screenshot, setScreenshot] = useState(null);
  // const [BlogTags, setBlogTags] = useState("");
  // const [BlogTag, setBlogTag] = useState("");
  const [BlogDescription, setBlogDescription] = useState("");
  const [blogSummary, setBlogSummary] = useState('')


  useEffect(() => {
    // Scroll to the top when the component mounts (page loads)
    window.scrollTo(0, 0);
  }, []);

  // const convertTobase64 = (e) => {
  //   console.log(e);
  //   let render = new FileReader();
  //   render.readAsDataURL(e.target.files[0]);
  //   render.onload = () => {
  //     console.log(render.result);
  //     setBlogLogo(render.result);
  //   };
  //   render.onerror = (error) => {
  //     console.log("error", error);
  //   };
  // };

  const convertTobase = (e) => {
    console.log(e);
    let render = new FileReader();
    render.readAsDataURL(e.target.files[0]);
    render.onload = () => {
      console.log(render.result);
      setScreenshot(render.result);
    };
    render.onerror = (error) => {
      console.log("error", error);
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)

    // let initialBlogDescription = BlogDescription
    // imgtest.forEach((imgObj, index) => {
    //   const imgSrc = imgObj.src;
    //   const figureRegex = new RegExp(`<figure>\\s*<\\/figure>`);

    //   initialBlogDescription = initialBlogDescription.replace(figureRegex, `<img src="${imgSrc}" style="margin-top: 30px; width: 60%; min-width: 300px; border-radius: 20px;"}}/>`);
    // });

    // setBlogDescription(initialBlogDescription);

    // console.log('Updated Blog Description:', initialBlogDescription);


    // toast.error("blog description is changesd,early return")
    // return

    // Handle form submission logic here
    // You can use the form data (BlogName, BlogLogo, etc.) as needed
    console.log("Form submitted:", {
      BlogName,
      // BlogLogo,
      // screenshot,
      // BlogTags,
      // BlogTag,
      BlogDescription,
      blogSummary,
      // BlogLink,
    });

    if (blogSummary.length >= 600) {
      setLoading(false)
      toast.error("Summary Should be less than 600 characters !!!")
      return
    }
    const formData = new FormData();
    formData.append('image', file);
    formData.append('BlogName', BlogName);
    // formData.append('BlogDescription', initialBlogDescription);
    formData.append('BlogDescription', BlogDescription);
    formData.append('blogSummary', blogSummary);

    const data = await axios
      .post(
        `${process.env.REACT_APP_URL}/api/addBlog`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then((res) => {
        console.log(res.data); toast.success("Added Successfully", {
          position: toast.POSITION.TOP_CENTER,
        }); setLoading(false)
      })
      .catch((err) => {
        console.log("err in api", err); setLoading(false)
      });
    setBlogName("");

    setScreenshot("");
    setBlogDescription("");
    setBlogSummary('');
    setTimeout(() => {
      window.location.reload()
    }, 2000);

  };

  useEffect(() => {
    console.log('Blo-----', BlogDescription)
  }, [BlogDescription])


  return (
    <>
      <div style={{ backgroundColor: '#89C8FF', minHeight: '80vh', maxHeight: '100%' }} >

        <div>
          <div
            style={{
              width: "100%",
              height: "60px",
              background: "#009fe3",
              display: "flex",
              alignItems: "center",
              marginTop: "75px",
              justifyContent: "center",
            }}
          >
            <span
              style={{
                color: "white",
                fontWeight: "bold",

                fontSize: "1.5rem",
              }}
            >
              Add Blog
            </span>
          </div>
        </div>
        <div className="mx-3 py-5">
          <div style={{ backgroundColor: 'whitesmoke' }} className=" max-w-3xl mx-auto p-6  rounded-md shadow-md  grid gap-4 mt-20">
            <div className="col-span-2">
              {/* <h2 className="text-2xl font-semibold mb-4">Add Blog</h2> */}
              <FaBloggerB className=" mx-auto mb-4 text-7xl" />
            </div>
            <form className=" col-span-2" onSubmit={handleSubmit}>
              <div className="flex flex-wrap ">
                <div className="mb-4 pe-2 w-full md:w-1/2">
                  <label
                    htmlFor="BlogName"
                    className="block text-sm font-medium text-gray-600"
                  >
                    Blog Name<span style={{ color: 'red' }}> *</span>
                  </label>
                  <input
                    type="text"
                    id="BlogName"
                    value={BlogName}
                    onChange={(e) => setBlogName(e.target.value)}
                    className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-indigo-300"
                    required
                  />
                </div>

                {/* <div className="mb-4 ps-2 w-full md:w-1/2">
              <label
                htmlFor="screenshot"
                className="block text-sm font-medium text-gray-600"
              >
                Website Screenshot<span style={{color:'red'}}> *</span>
              </label>
              <input
                type="file"
                id="screenshot"
                accept="image/*"
                onChange={convertTobase}
                className="mt-1 p-2 bg-white w-full border rounded-md focus:outline-none focus:ring focus:border-indigo-300"
                required
              />
            </div>
          </div> */}

                <div className="mb-4 ps-2 w-full md:w-1/2">
                  <label
                    htmlFor="screenshot"
                    className="block text-sm font-medium text-gray-600"
                  >
                    Website Screenshot<span style={{ color: 'red' }}> *</span>
                  </label>
                  <input
                    type="file"
                    id="screenshot"
                    accept="image/*"
                    onChange={handleFileChange}
                    className="mt-1 p-2 bg-white w-full border rounded-md focus:outline-none focus:ring focus:border-indigo-300"
                    required
                  />
                </div>
              </div>

              <div className="mb-4 col-span-2">
                <label
                  htmlFor="BlogSummary"
                  className="block text-sm font-medium text-gray-600"
                >
                  Blog Summary <span style={{ color: 'red' }}> *</span>
                </label>
                <textarea
                  id="BlogSummary"
                  value={blogSummary}
                  onChange={(e) => setBlogSummary(e.target.value)}
                  className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-indigo-300"
                  rows="4"
                  required
                ></textarea>
              </div>

              <div className="mb-4 col-span-2">
                <label
                  htmlFor="BlogDescription"
                  className="block text-sm font-medium text-gray-600"
                >
                  Blog Description <span style={{ color: 'red' }}> *</span>
                </label>
                {/* <textarea
              id="BlogDescription"
              value={BlogDescription}
              onChange={(e) => setBlogDescription(e.target.value)}
              className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-indigo-300"
              rows="4"
              required
            ></textarea> */}
                <div style={{ backgroundColor: 'white' }} className='editorJs px-2'>
                  <Editor
                    value={BlogDescription}
                    toolbar={{
                      options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'emoji', 'remove', 'history'],
                      blockType: {
                        inDropdown: true,
                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
                      },
                      // image: {

                      //   urlEnabled: true,
                      //   uploadEnabled: true,
                      //   alt: { present: false, mandatory: false },
                      //   defaultSize: {
                      //     height: 'auto',
                      //     width: 'auto',
                      //   },
                      // },

                    }}



                    // onChange={(e) => setBlogDescription(e.target.value)}
                    editorState={editorState}
                    // onPaste={(event) => this.Copy_Paste_Pictures_PO(event)}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={setEditorState}
                  />
                 
                </div>

              </div>




              <div className="col-span-2 flex justify-center">
                <button
                  type="submit"
                  style={{ backgroundColor: 'blue' }}
                  className=" bg-indigo-600 text-white p-3 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring focus:border-indigo-300 border-none"
                >
                  Submit
                </button>
              </div>
              <div className=' flex justify-center mx-auto mt-4'>
                {loading ? (
                  // <div>hello</div>
                  <ThreeDots type="Oval" position="top-center" className='d-flex justify-center' color="rgb(0,159,227)" height={50} width={50} />

                ) : (
                  ''
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AddBlog