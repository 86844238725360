import React, { useState } from "react";
import "./Dropdown.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const Dropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSecond, setIsOpensecond] = useState(false);
  const [isThird, setIsOpenthird] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Name");
  const [secondselected, Setsecond] = useState("Category");
  const [thirdselected, Setthird] = useState("Price");

  // const options = ["Option 1", "Option 2", "Option 3", "Option 4"];
  const second = [
    "Generative AI",
    "Web-builder AI",
    "Web Development AI",
    "Resume Builder AI",
    "Workspace AI",
    "AI-text Detection",
  ];
  const third = ["Option 1", "Option 2", "Option 3", "Option 4"];

  const toggleDropdownsecond = () => {
    setIsOpensecond(!isSecond);
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const toggleDropdownthird = () => {
    setIsOpenthird(!isThird);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };
  const handleOptionsecond = (option) => {
    Setsecond(option);
    setIsOpensecond(false);
  };
  const handleOptionthird = (option) => {
    Setthird(option);
    setIsOpenthird(false);
  };
  return (
    <>
      <div style={{ marginLeft: "50px" }}>
        <div className="dropdown-container">
          <div style={styles.searchContainer}>
            <div style={styles.iconContainer}>
              <FontAwesomeIcon icon={faSearch} style={styles.searchIcon} />
            </div>
            <input type="text" placeholder="Search..." style={styles.input} />
          </div>
        </div>
        <div className="dropdown-container">
          <div className="dropdown-header" onClick={toggleDropdownsecond}>
            <div className="dropdown-header-name">{secondselected}</div>
            <div className={`dropdown-header-arrow ${isSecond ? "open" : ""}`}>
              ▼
            </div>
          </div>
          {isSecond && (
            <ul className="dropdown-options">
              {second.map((option, index) => (
                <li key={index} onClick={() => handleOptionsecond(option)}>
                  {option}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="dropdown-container">
          <div className="dropdown-header" onClick={toggleDropdownthird}>
            <div className="dropdown-header-name">{thirdselected}</div>
            <div className={`dropdown-header-arrow ${isThird ? "open" : ""}`}>
              ▼
            </div>
          </div>
          {isThird && (
            <ul className="dropdown-options">
              {third.map((option, index) => (
                <li key={index} onClick={() => handleOptionthird(option)}>
                  {option}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </>
  );
};

const styles = {
  searchContainer: {
    display: "flex",
    alignItems: "center",
    width: "90%",
    border: "1px dotted #000",
    padding: 5,
    marginTop: 40,
    marginLeft: 40,
  },
  iconContainer: {
    padding: 5,
  },
  searchIcon: {
    color: "#666",
  },
  input: {
    border: "none",
    outline: "none",
    fontSize: 16,
    width: "100%",
  },
};

export default Dropdown;
