import React from "react";
import "./Imagecontainer.css";
import AboutUsMid from '../../src/Images/about_us_photo.png'

const Imagecontainer = () => {
  return (
    <>
      <div className="Imagecontainer-main">
        {/* <div className="main-image-one">
          <img
            src="https://static.wixstatic.com/media/c8c9a4_2a334b882ac146849f6aaf7babc5a448~mv2.jpg/v1/fill/w_649,h_816,fp_0.59_0.51,q_85,usm_0.66_1.00_0.01,enc_auto/iStock-1320214068.jpg"
            alt=""
            className="Image-main-first"
          />
        </div> */}
        <div className="main-image-second">
          <img
            // src="https://static.wixstatic.com/media/c8c9a4_c1a74e3e649f4065b1045c00c5382d18~mv2.jpg/v1/fill/w_718,h_913,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/!%C2%A9%20Sarah%20Duke%20Photography-08.jpg"
            src={AboutUsMid}
            alt=""
            className="Image-main-second"
          />
        </div>
        {/* <div className="main-image-third">
          <img
            src="https://static.wixstatic.com/media/c8c9a4_ef0b42e076784efeb23c2a7698386a33~mv2.jpg/v1/fill/w_649,h_816,fp_0.35_0.49,q_85,usm_0.66_1.00_0.01,enc_auto/ThinkstockPhotos-177746440.jpg"
            alt=""
            className="Image-main-third"
          />
        </div> */}
      </div>
      <div
        className="main-box-con"
        style={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        <div className="info-box"  style={{ flexWrap:'wrap', display: "flex", justifyContent: "center" }}>
          <div className="p-2 xl:w-1/4 md:w-1/2 w-full">
            <div className="content-boxes">100+</div>
            <div className="content-text">Team Members across the globe</div>
          </div>
          <div className="p-2 xl:w-1/4 md:w-1/2 w-full">
            <div className="content-boxes">15+</div>{" "}
            <div className="content-text">
              Industries Covered in VA, EA and BPO
            </div>
          </div>
          <div className="p-2 xl:w-1/4 md:w-1/2 w-full">
            <div className="content-boxes">18+</div>{" "}
            <div className="content-text">AI Native Tools Experience</div>
          </div>
          <div className="p-2 xl:w-1/4 md:w-1/2 w-full">
            <div className="content-boxes">90%</div>{" "}
            <div className="content-text">
              Been VA's for 5+ years at the minimum
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Imagecontainer;
