import React from "react";

const Body = () => {
  return (
    <div className="profit-main">
      <div className="profit-second" style={{ marginBottom: "6vw" }}>
        <h1
          style={{
            textAlign: "center",
          }}
        >
          AIVirtualOps Terms Of Service
        </h1>
        <p>Updated on November 21, 2023</p>
        <p>Updated at Miami FL</p>
        <h3>AIVirtualOps Website.</h3>
        <p>
          These Website Terms &amp; Conditions (“T&amp;Cs”) apply to your access
          and use of www.aivirtualops.com (the “Site”), including all software,
          data, reports, text, images, sounds, video, and content made available
          through any portion of the Site (collectively, the “Content”). Content
          includes all such elements, as well as individual elements and
          portions thereof.
        </p>
        <h3>Acceptance of Terms.</h3>
        <p>
          AIVirtualOps permits you (“User” or “you” or “your”) to access and use
          the Site and Content, subject to these T&amp;Cs. By accessing or using
          any portion of the Site, you acknowledge that you have read,
          understood, and agree to be bound by these T&amp;Cs. If you are
          entering into these T&amp;Cs on behalf of a company or other legal
          entity (“User Entity”), you must have the legal authority to
          contractually bind such User Entity to these T&amp;Cs, in which case
          the terms “you” or “your” or “User” will refer to such User Entity. If
          you lack such legal authority to contractually bind or you do not
          agree with these T&amp;Cs, you must not accept these T&amp;Cs or
          access or use the site or content.
        </p>
        <h3>T&amp;Cs Updates.</h3>
        <p>
          AIVirtualOps reserves the right, at its sole discretion, to change or
          modify portions of these T&amp;Cs at any time. AIVirtualOps will post
          the changes to these T&amp;Cs on the Site and will indicate at the top
          of this page the date these terms were last revised. It is your
          responsibility to check the T&amp;Cs periodically for changes. Your
          continued use of the Site and Content after the date any such changes
          become effective constitutes your acceptance of the new or revised
          T&amp;Cs.
        </p>
        <h3>General Conditions/Access and Use.</h3>
        <p>
          <span className="myspan">
            Authorization to Access and Use Site and Content.
          </span>
           Subject to your compliance with these T&amp;Cs and the provisions
          hereof, you may access or use the Site and Content solely for the
          purpose of your evaluation of AIVirtualOps and AIVirtiualOps
          marketplace, directories, products and services. You may only link to
          the Site or Content, or any portion thereof, as expressly permitted by
          AIVirtualOps.
        </p>
        <p>
          <span className="myspan">Ownership and Restrictions.</span>
           All rights, title and interest in and to the Site and Content will
          remain with and belong exclusively to AiVirtualOps. You will not (a)
          sublicense, resell, rent, lease, transfer, assign, time share or
          otherwise commercially exploit or make the Site and any Content
          available to any third party, (b) use the Site and Content in any
          unlawful manner (including without limitation in violation of any
          data, privacy or export control laws) or in any manner that interferes
          with or disrupts the integrity or performance of the Site and Content
          or their related components, or (c) modify, adapt or hack the Site and
          Content to, or try to, gain unauthorized access to the restricted
          portions of the Site and Content or related systems or networks (i.e.,
          circumvent any encryption or other security measures, gain access to
          any source code or any other underlying form of technology or
          information, and gain access to any part of the Site and Content, or
          any other products or services of AIVirtualOps that are not readily
          made available to the general public).
        </p>
        <p>
          You are not permitted to copy, modify, frame, repost, publicly perform
          or display, sell, reproduce, distribute, or create derivative works of
          the Site and Content, except that you may download, display, and print
          one copy of the publicly available materials (i.e., the Content that
          does not require an Account name or password to access) on any single
          computer solely for your personal, non-commercial use, provided that
          you do not modify the material in any way and you keep intact all
          copyright, trademark, and other proprietary notices. You agree not to
          access the Site or Content by any means other than through the
          interface that is provided by AIVirtulOps to access the same. You may
          not use any “page-scrape,” “deep-link,” “spider,” or “robot or other
          automatic program, device, algorithm or methodology, or any similar
          manual process, to access, copy, acquire, or monitor any portion of
          the Site or any Content, or in any way reproduce or circumvent the
          presentation or navigational structure of the Site or any Content, to
          obtain or attempt to obtain any Content or other information through
          any means not made generally available through the Site by
          AIVirtualOps. AIVirtualOps reserves the right to take any lawful
          measures to prevent any such activity. You may not forge headers or
          otherwise manipulate identifiers in order to disguise the origin of
          any message or transmittal you send to AIVirtualOps on or through the
          Site or any service offered on or through the Site. You may not
          pretend that you are, or that you represent, someone else, or
          impersonate any other individual or entity.
        </p>
        <p>
          <span className="myspan">Responsibility for Your Data.</span>
           You are solely responsible for all data, information and other
          content, that you upload, post, or otherwise provide or store
          (hereafter “post(ing)”) in connection with or relating to the Site.
        </p>
        <p>
          <span className="myspan">Reservation of Rights.</span>
           AIVirtualOps and its partners and licensors each own and retain their
          respective rights in and to all logos, company names, marks,
          trademarks, copyrights, trade secrets, know-how, patents and patent
          applications that are used or embodied in or otherwise related to the
          Site and Content. AiVirtualOps grants no rights or licenses (implied,
          or otherwise) whatsoever to you under these T&amp;Cs.
        </p>
        <h3>Use of Intellectual Property.</h3>
        <p>
          <span className="myspan">Rights in User Content.</span>
           By posting your information and other content (“User Content”) on or
          through the Site and Content, you grant AIVirtualOps a worldwide,
          non-exclusive, perpetual, irrevocable, royalty-free, fully paid,
          sublicensable and transferable license to use, modify, reproduce,
          distribute, display, publish and perform User Content in connection
          with the Site and Content. AIVirtualOps has the right, but not the
          obligation, to monitor the Site and Content and User Content.
          AIVirtualOps may remove or disable any User Content at any time for
          any reason, or for no reason at all.
        </p>
        <p>
          <span className="myspan">
            Unsecured Transmission of User Content.
          </span>
           You understand that the operation of the Site and Platform, including
          User Content, may be unencrypted and involve transmission to
          AIVirtualOps third party vendors and hosting partners to operate and
          maintain the Site and Content. Accordingly, you acknowledge that you
          bear sole responsibility for adequate security, protection and backup
          of User Content. AIVirtualOps will have no liability to you for any
          unauthorized access or use of any of User Content, or any corruption,
          deletion, destruction or loss of any of User Content.
        </p>
        <p>
          <span className="myspan">Feedback.</span>
           You may submit ideas, suggestions, or comments (“Feedback”) regarding
          the Site and Content or AiVirtualOps business, products or services.
          By submitting any Feedback, you acknowledge and agree that (a) your
          Feedback is provided by you voluntarily and AIVirtualOps may, without
          any obligations or limitation, use and exploit such Feedback in any
          manner and for any purpose, (b) you will not seek and are not entitled
          to any money or other form of compensation, consideration, or
          attribution with respect to your Feedback regardless of whether
          AIVirtualOps considered or used your Feedback in any manner, and (c)
          your Feedback is not the confidential or proprietary information of
          you or any third party.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>
            Your Representations and Warranties.
          </span>
           You represent and warrant to AiVirtualOps that your activity on the
          Site and AIVirtualOps possession and use of User Content as
          contemplated in these T&amp;Cs do not and will not violate, infringe,
          or misappropriate any third party’s copyright, trademark, right of
          privacy or publicity, or other personal or proprietary right, nor does
          User Content contain any matter that is defamatory, obscene, unlawful,
          threatening, abusive, tortious, offensive or harassing.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>
            Termination of Access Due to Violations.
          </span>
           AiVirtualOps may, in its sole discretion and without prior notice,
          terminate your access to the Site and/or block your future access to
          the Site if we determine that you have violated these T&amp;Cs or
          other agreements or guidelines which may be associated with your use
          of the Site. You also agree that any violation by you of these
          T&amp;Cs will cause irreparable harm to AIVirtualOps, for which
          monetary damages would be inadequate, and you consent to AiVirtualOps
          obtaining any injunctive or equitable relief that AIVirtualOps deems
          necessary or appropriate in such circumstances, without limiting
          AIVirtualOps other available remedies. Further, AiVirtualOps may, in
          its sole discretion and without prior notice, terminate your access to
          the Site, for cause, which includes (but is not limited to) (1)
          requests by law enforcement or other government agencies, (2)
          discontinuance or material modification of the Site or any service
          offered on or through the Site, or (3) unexpected technical issues or
          problems.
        </p>
        <h3>NO WARRANTIES AND DISCLAIMER BY AIVIRTUALOPS.</h3>
        <p>
          THE SITE AND CONTENT, AND ALL SERVER AND NETWORK COMPONENTS, ARE
          PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITH ALL ERRORS AND
          DEFECTS AND WITHOUT ANY WARRANTIES OF ANY KIND, AND AIVIRTUALOPS
          EXPRESSLY DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, INCLUDING ANY
          IMPLIED WARRANTIES OF ACCURACY, COMPLETENESS, MERCHANTABILITY, FITNESS
          FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT, AND ANY REPRESENTATIONS
          OR WARRANTIES ARISING FROM COURSE OF DEALING, COURSE OF PERFORMANCE OR
          USAGE OF TRADE. YOU ACKNOWLEDGE THAT AIVIRTUALOPS DOES NOT WARRANT
          THAT YOUR ACCESS OR USE OR BOTH OF THE SITE AND CONTENT WILL BE
          UNINTERRUPTED, TIMELY, SECURE, ERROR-FREE OR VIRUS-FREE, AND
          AIVIRTUALOPS DOES NOT MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE
          OBTAINED FROM USE OF THE SITE AND CONTENT. NO INFORMATION, ADVICE OR
          SERVICES OBTAINED BY YOU FROM AIVIRTUALOPS OR THROUGH THE SITE WILL
          CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THESE T&amp;Cs AND YOU
          SHOULD NOT RELY ON THE SITE AND THE GENERAL CONTENT ALONE AS THE BASIS
          FOR YOUR BUSINESS DECISIONS.
        </p>
        <p>
          AIVirtualOps reserves the right to do any of the following, at any
          time, without notice: (1) to modify, suspend or terminate operation of
          or access to the Site, or any portion of the Site, for any reason; (2)
          to modify or change the Site, or any portion of the Site, for any
          reason; and (3) to interrupt the operation of the Site, or any portion
          of the Site, as necessary to perform routine or non-routine
          maintenance, error correction, or other changes.
        </p>
        <h3>LIMITED LIABILITY.</h3>
        <p>
          <span className="myspan">
            Exclusion of Damages and Limitation of Liability.
          </span>
           AIVirtualOps does not charge fees for you to access and use the Site
          and Content pursuant to these T&amp;Cs. The Site does not conduct
          commercial transactions; the Content is comprised of general
          information and describes some of the basic elements of the
          AIVirtualOps service. As consideration for your free access and use of
          the Site and Content pursuant to these T&amp;Cs, you further agree
          that AIVIRTUALOPS WILL NOT BE LIABLE TO YOU FOR ANY INCIDENTAL,
          CONSEQUENTIAL, INDIRECT, SPECIAL, PUNITIVE OR EXEMPLARY DAMAGES
          (INCLUDING DAMAGES FOR LOSS OF BUSINESS, LOSS OF PROFITS OR THE LIKE)
          ARISING OUT OF OR RELATING TO THIS T&amp;Cs, INCLUDING WITHOUT
          LIMITATION, YOUR USE OR INABILITY TO USE THE SITE, PLATFORM, MATCHING
          SERVICES, CONTENT, PROPRIETARY INFORMATION, OR ANY INTERRUPTION OR
          DISRUPTION OF SUCH USE, EVEN IF AIVIRTUALOPS HAS BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES AND REGARDLESS OF THE CAUSE OF ACTION
          (WHETHER IN CONTRACT, TORT, BREACH OF WARRANTY OR OTHERWISE). THE
          AGGREGATE LIABILITY OF AIVIRTUALOPS WITH REGARD TO THIS T&amp;Cs WILL
          IN NO EVENT EXCEED USD$1.00.
        </p>
        <p>
          <span className="myspan">Jurisdictional Limitations.</span>
           Some states and other jurisdictions do not allow the exclusion of
          implied warranties or limitation of liability for incidental or
          consequential damages, which means that some of the above limitations
          may not apply to you. IN THESE JURISDICTIONS, AIVIRTUALOPS LIABILITY
          WILL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW. Dispute
          Resolution; Jury Waiver. THESE T&amp;Cs ARE MADE UNDER, AND WILL BE
          CONSTRUED AND ENFORCED IN ACCORDANCE WITH, THE LAWS OF FLORIDA
          APPLICABLE TO AGREEMENTS MADE AND TO BE PERFORMED SOLELY THEREIN,
          WITHOUT GIVING EFFECT TO PRINCIPLES OF CONFLICTS OF LAW. In any action
          between or among any of the parties, whether arising out of these
          T&amp;Cs or otherwise, each of the parties irrevocably and
          unconditionally (a) consents and submits to the exclusive jurisdiction
          and venue of the state courts located in Tallahassee Florida; and (b)
          WAIVES ANY AND ALL RIGHT TO TRIAL BY JURY IN ANY LEGAL PROCEEDING
          ARISING OUT OF OR RELATED TO THIS AGREEMENT OR ANY TRANSACTIONS
          CONTEMPLATED HEREBY.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>No Reliance; Due Inquiry.</span>
           You affirm and agree that, given the general and “as-is” nature of
          the Site and the Content you are not specifically relying on any
          statements, or materials contained on the Site or in the Content to
          make any business decisions. Resource allocation and staffing matters
          are inherent complex and unique. Should you choose to continue your
          inquiry into whether AIVirtualOps service is right for you, you will
          have an opportunity to make additional inquiry of AIVirtualOps and you
          should make an independent investigation of the suitability of using
          AIVirtualOps for your particular business needs. Only after such
          appropriate due diligence, thorough review of AIVirtualOps service
          offering including the terms and conditions of AIVirtualOps service
          agreements, and a through screening of any freelancer AIVirtualOps
          proposes to you should you come to any conclusions or make any
          decisions about whether AIVirtualOps, or any freelancer proposed by
          AIVirtualOps is right for you and your specific project needs or
          requirements.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>Miscellaneous.</span>
           These T&amp;Cs, and any additions, changes, edits and/or
          modifications made thereto by AIVirtualOps, together with AIVirtualOps
          Privacy Policy located here, constitute the entire agreement between
          the parties with respect to the portions of the Site available without
          an account ID or password. Access to certain password-restricted
          portions of the Site, and any subsequent procurement and use of
          AIVirtualOps ‘s services is subject to different, additional
          agreement(s).
        </p>
        <p>
          AIVirtualOps does not conduct business with or seek any commercial
          transactions with: (x) any entity, citizen or resident of a geographic
          area in which access to or use of the AIVirtualOps services is
          prohibited by applicable law, decree, regulation, treaty, or
          administrative act; (y) an entity, citizen or resident of, or located
          in, a geographic area that is subject to U.S. or other sovereign
          country sanctions or embargoes; or (z) an individual, or an individual
          employed by or associated with an entity, identified on the U.S.
          Department of Commerce’s Denied Persons or Entity List, the U.S.
          Department of Treasury’s Specially Designated Nationals or Blocked
          Persons Lists, or the U.S. Department of State’s Debarred Parties List
          or otherwise ineligible to receive items subject to U.S. export
          control laws and regulations or other economic sanction rules of any
          sovereign nation (collectively, a “Prohibited Counterparty”). By
          accessing the Site and the Content you represent and warrant that you
          are not a Prohibited Counterparty, and you will not seek or (attempt
          to) obtain services from or a commercial relationship with
          AIVirtualOps.
        </p>
        <p>
          These T&amp;Cs and any additions, changes, edits and/or modifications
          made thereto by AIVirtualOps cannot be amended except by AIVirtualOps
          as set forth above. The failure of AIVirtualOps to exercise or enforce
          any right or provision of these T&amp;Cs will not be a waiver of that
          right.
        </p>
        <p>
          Any notices to AIVirtualOps in connection with this agreement will be
          made by email transmitted to support@aivirtualops.com if you also send
          a copy of such notice via nationally recognized carrier to
          AiVirtualOps, LLC, 8350 Bee Rdige Road #157 Sarasota FL 34241, Attn:
          Legal Department. If any provision of these T&amp;Cs will be
          determined to be illegal or unenforceable, that provision will be
          first revised to give the maximum permissible effect to its original
          intent or, if such revision is not permitted, that specific provision
          will be eliminated so that these T&amp;Cs will otherwise remain in
          full force and effect and enforceable.
        </p>
      </div>
    </div>
  );
};

export default Body;
