import React, { useState } from "react";

const Questiont = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className="faq-section animated"
      onMouseEnter={toggleAccordion}
      onMouseLeave={toggleAccordion}
    >
      <div
        className={`faq-header ${isOpen ? "open" : ""}`}
        onClick={toggleAccordion}
      >
        <div className="faq-question">
          Can I choose a virtual assistant with specific skills or expertise?
        </div>
        {/* <div
          className="faq-icon"
          style={{ fontSize: "40px", paddingRight: "20px" }}
        >
          {isOpen ? "-" : "+"}
          <FontAwesomeIcon icon="fa-circle-question" color="black" />
        </div> */}
      </div>
      {isOpen && (
        <div className="faq-answer">
          <span>
            Certainly, you have the option to select a virtual assistant
            tailored to meet your specific business needs. Subsequently, we
            conduct thorough training sessions to equip them with the necessary
            skills. We also place a strong emphasis on precise client-virtual
            assistant profile matching for a seamless alignment of expertise and
            requirements.
          </span>
        </div>
      )}
    </div>
  );
};

export default Questiont;
