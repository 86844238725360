import React from "react";
import "./Leadership.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageF from "../Images/Sassy Lim.png";
import ImageS from "../Images/Ram Gamboa.png";
import ImageT from "../Images/maria_elizabeth_caoile.png";
import ImageFo from "../Images/Venice Florence Surban (1).png";
import ImageSi from "../Images/Nataniel Nosef Roraldo.png";
import Logo from "../Images/aivops logo.png";
import { Link } from "react-router-dom";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "black",
        borderRadius: "60%",
      }}
      onClick={onClick}
    />
  );
}

const Leadership = () => {
  const [isFlipped, setIsFlipped] = React.useState(false);

  const handleHover = () => {
    setIsFlipped(!isFlipped);
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div
      className="profit-main"
      style={{
        background: "#89c8ff",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div className="profit-second mt-8 mb-5" style={{ width: "90%" }}>
        <h2>AI Virtual Ops Leadership Team</h2>
        <div className="leadership-first my-5">
          <p className="leadershipwidth" style={{  }}>
            Meet our leadership team which has many year of supporting clients
            and enable businesses across the globe grow and succeed.
          </p>
          {/* <button
            className="learn-more"
            style={{ marginTop: "10px", height: "50px" }}
          >
            Explore Leaders
          </button> */}
        </div>
      </div>
      <div className="profit-second" style={{ width: "80%" }}>
        <Slider {...settings} className="slider-box d-flex">
          <div className="boxes-team mx-auto">
            <div className="the-card-main">
              <div className="team-front">
                <div
                  style={{
                    fontSize: "1.7rem",
                    marginLeft: "20px",
                    textDecoration: "none",
                    color: "black",
                    border: "none",
                    height: "80px",
                    display: "flex",
                    alignItems: "start",
                    marginBottom:'10px',
                  }}
                >
                  Sassy Lim
                </div>
                <div
                  style={{
                    fontSize: "1rem",
                    marginLeft: "20px",
                    height: "40px",
                    marginBottom:'10px',
                  }}
                >
                  Client Partner{" "}
                </div>

                <img
                  src={ImageF}
                  alt=""
                  style={{
                    // borderRadius: "20px",
                    width: "100%",
                    // height:'100%',
                    marginLeft: "0px",
                    // marginTop: "3vw",
                  }}
                />
              </div>
              <div className="team-back">
                <p
                className="p-pad"
                  style={{
                    width: "98%",
                    // marginTop: "30px",
                    // padding: "10px",
                    textAlign: "justify",
                    textJustify: "inter-word",
                  }}
                >
                  Hi, I’m Sassy, and while I have a background in health and natural sciences, I've always found creative work to be fascinating. As a VA for a few years now, I specialize in video and graphic creation, and I'm passionate about collaborating with others to create something that has a positive impact on the world. When I'm not donning my Virtual Assistant hat, you can find me juggling tasks as a small business owner, and babywearing consultant. But my biggest inspiration in life? My two energetic boys who keep me on my toes and fuel my drive to make a difference.
                </p>
              </div>
            </div>
          </div>
          <div className="boxes-team">
            <div className="the-card-main">
              <div className="team-front">
                <div
                  style={{
                    fontSize: "1.7rem",
                    marginLeft: "20px",
                    height: "80px",
                    display: "flex",
                    alignItems: "start",
                    marginBottom:'10px',
                  }}
                >
                  Ram Gamboa
                </div>
                <div
                  style={{
                    fontSize: "1rem",
                    marginLeft: "20px",
                    height: "40px",
                    marginBottom:'10px',
                  }}
                >
                  Human Resource Officer{" "}
                </div>
                <img
                  src={ImageS}
                  alt=""
                  style={{
                    // borderRadius: "20px",
                    width: "100%",
                    overflow:'hidden',
                    marginLeft: "0px",
                    // marginTop: "3vw",
                  }}
                />
              </div>
              <div className="team-back">
                <p
                className="p-pad"

                  style={{
                    width: "98%",
                    // marginTop: "30px",
                    // paddingTop: "100px",
                    textAlign: "justify",
                    textJustify: "inter-word",
                  }}
                >
                  I'm an enthusiastic individual who is always eager to help and learn. I'm also an introverted extrovert, finding joy in both quiet introspection and social interactions. My jolliness and approachability contribute to an inviting work environment where people feel valued. In the field of human resources, I specialize in offering advice on hiring practices and personnel development, boasting 2 years of work experience in this field. My roles encompass end-to-end recruitment, compensation and benefits, payroll and timekeeping, employee relations, and employee engagement, along with providing support for training, seminars, and company activities.
                </p>
              </div>
            </div>
          </div>
          <div className="boxes-team">
            <div className="the-card-main">
              <div className="team-front">
                <div
                  style={{
                    fontSize: "1.7rem",
                    marginLeft: "20px",
                    height: "80px",
                    display: "flex",
                    alignItems: "start",
                    marginBottom:'10px',
                  }}
                >
                  Venice Florence Surban
                </div>
                <div
                  style={{
                    fontSize: "1rem",
                    marginLeft: "20px",
                    height: "40px",
                    marginBottom:'10px',
                  }}
                >
                  Marketing Manager{" "}
                </div>
                <img
                  src={ImageFo}
                  alt=""
                  style={{
                    // borderRadius: "20px",
                    width: "100%",
                    marginLeft: "0px",
                    // marginTop: "3vw",
                  }}
                />
              </div>
              <div className="team-back">
                <p
                className="p-pad"

                  style={{
                    width: "98%",
                    // marginTop: "30px",
                    // padding: "10px",
                    textAlign: "justify",
                    textJustify: "inter-word",
                  }}
                >
                  Hello, I'm V. My passion lies in crafting compelling brand stories that truly resonate. When I'm not shaping our brand's identity, you'll often find me immersing myself in the world of art and photography, always seeking fresh inspiration for our next exciting project. I also have a deep love for reading books, which fuels my creative spirit and broadens my horizons.
                </p>
              </div>
            </div>
          </div>
          <div className="boxes-team">
            <div className="the-card-main">
              <div className="team-front">
                <div
                  style={{
                    fontSize: "1.7rem",
                    marginLeft: "20px",
                    height: "80px",
                    display: "flex",
                    alignItems: "start",
                    marginBottom:'10px',
                  }}
                >
                  Beth Caoile
                </div>
                <div
                  style={{
                    fontSize: "1rem",
                    marginLeft: "20px",
                    height: "40px",
                    marginBottom:'10px',
                  }}
                >
                  Lead Generation Officer{" "}
                </div>
                <img
                  src={ImageT}
                  alt=""
                  style={{
                    // borderRadius: "20px",
                    width: "100%",
                    marginLeft: "0px",
                    // marginTop: "3vw",
                  }}
                />
              </div>
              <div className="team-back">
                <p
                className="p-pad"

                  style={{
                    width: "98%",
                    // marginTop: "30px",
                    // padding: "10px",
                    textAlign: "justify",
                    textJustify: "inter-word",
                  }}
                >
               Hi! I'm Beth, a dedicated and hardworking individual with a passion for continuous learning and adaptability. With several years of experience as a Virtual Assistant, I specialize in providing top-notch Administrative Assistance. Beyond the professional realm, I find joy in various activities. When not immersed in the world of administrative tasks,I enjoy unwinding by playing mobile games, indulging in movies, and spending quality time with friends. The heart of my world lies in being a fur-mom to seven adorable dogs—six charming chihuahuas and one delightful pomchi.
                </p>
              </div>
            </div>
          </div>
          <div className="boxes-team">
            <div className="the-card-main">
              <div className="team-front">
                <div
                  style={{
                    fontSize: "1.7rem",
                    marginLeft: "20px",
                    height: "80px",
                    display: "flex",
                    alignItems: "start",
                    marginBottom:'10px',
                  }}
                >
                  Nataniel Nosef Roraldo
                </div>
                <div
                  style={{
                    fontSize: "1rem",
                    marginLeft: "20px",
                    height: "40px",
                    marginBottom:'10px',
                  }}
                >
                  Community Manager{" "}
                </div>
                <img
                  src={ImageSi}
                  alt=""
                  style={{
                    // borderRadius: "20px",
                    width: "100%",
                    marginLeft: "0px",
                    // marginTop: "3vw",
                  }}
                />
              </div>
              <div className="team-back">
                <p
                className="p-pad"

                  style={{
                    width: "98%",
                    // marginTop: "30px",
                    // padding: "10px",
                    textAlign: "justify",
                    textJustify: "inter-word",
                  }}
                >
                  I'm Nataniel Nosef Roraldo, a Nursing student at Pamantasan ng Lungsod ng Maynila and a proud Gawad Kalinga Awardee. With a background in a BPO company and a renowned fast-food chain, I've honed precision, communication, and customer service, delivering exceptional experiences. Proficient in both Filipino and English, I skillfully wield tools like Canva, Microsoft Office, and Google Suite. Known for my keen eye for detail, tenacity, and unwavering commitment to excellence, I've embarked on my latest adventure as the AI Virtual Ops Community Manager, where potential meets performance.
                </p>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default Leadership;
