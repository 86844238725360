import React from "react";

const TeamNew = () => {
  return (
    <div className="profit-main">
      <div className="profit-second my-8" style={{ marginBottom: "6vw" }}>
        <h1
          style={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "3rem",
          }}
          className="mb-5"
        >
          Enabling Business Growth combining AI + Human
        </h1>
        <p style={{ textAlign: "center", fontSize: "1.3rem" }}>
          We elevate businesses, grow startups, automate workflows, reduce time
          to market and overall reduce admin assistants, virtual assistants,
          executive assistants, Customer Service, Business Process Operations
          resources needed to create an "Intelligent Business"
        </p>
      </div>
    </div>
  );
};

export default TeamNew;
