import React, { useState,useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import "./header.css"; // Import your CSS file for styling
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Logo from "../Images/aivops logo.png";
import Video from "../Images/AIVO Logo Reveal.mp4";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Cookies from "js-cookie";

import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

import { FaTools } from "react-icons/fa";
import { FaBloggerB } from "react-icons/fa";
import { MdLogout } from "react-icons/md";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Header = () => {

 const[userCookie,setUserCookie]=useState('')
 const[currentPage,setCurrentPage]= useState('Home')

  useEffect(()=>{
     setUserCookie(Cookies.get("user"))
  },[])

  const [menuOpen, setMenuOpen] = useState(false);
  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };



  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const closeClick=(e)=>{
    setMenuOpen(false);
    setCurrentPage(e)
    
  }

  const handleLogout=()=>{
    Cookies.remove('user');
    setTimeout(() => {
      window.location.href = '/';
    }, 2000);
  }

  const SelectedCss = {backgroundColor:'grey', paddingLeft:'10px', paddingRight:'10px', borderRadius:'5px', color:'yellow', paddingTop:'5px',paddingBottom:'5px'}

  const location = useLocation();
  const { hash, pathname, search } = location;
  console.log('location,',location)

  return (
    <nav
      className={
        
        color
          ? `navbar flex-wrapt navbar-bg  ${menuOpen ? "open" : ""}`
          : `navbar flex-wrapt  ${menuOpen ? "open" : ""}`
      }
    >
      <div className="logo w-fullt">
        <Link to="/">
          {/* <img
            src={Logo}
            alt=""
            width={50}
            style={{ backgroundColor: "transparent", marginLeft: "40px" }}
          /> */}
          <div className="background-video-nav">
            <video autoPlay muted loop>
              <source src={Video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </Link>
      </div>

      <ul className={`menu w-fullt ${menuOpen ? "open" : ""}`}>
        {/* <li>How It Works</li> */}
        <li >
          <div >
            <NavLink
              to="/"
              data-value='Home'
              onClick={(e)=>{closeClick(e.target.getAttribute('data-value'))}}
              className={
                color
                  ? `navbar-bg ${menuOpen ? "open" : ""}`
                  : `link-color ${menuOpen ? "open" : ""}`
              }
              // style={{ textDecoration: "none", color: "white" }}
              style={ pathname === '/' ? SelectedCss:{color:'white'}}
            >
              Home
            </NavLink>
          </div>
        </li>
        <li >
          <div id="business">
            <NavLink
              to="/Aboutus"
              // style={({ isActive }) => ({
              //   color: isActive ? "lightgreen" : "black navbar-bg navbar",
              //   textDecoration: "none",
              // })}
              data-value='Aboutus'
              onClick={(e)=>closeClick(e.target.getAttribute('data-value'))}
              className={
                color
                  ? `navbar-bg ${menuOpen ? "open" : ""}`
                  : `link-color ${menuOpen ? "open" : ""}`
              }
              style={ pathname === '/Aboutus' ? SelectedCss:{color:'white'}}
            >
              About Us
            </NavLink>
          </div>
        </li>
        <li>
          <div>
            <NavLink
              to="/price"
              // style={({ isActive }) => ({
              //   color: isActive ? "lightgreen" : "black navbar-bg navbar",
              //   textDecoration: "none",
              // })}
              data-value='Price'
              onClick={(e)=>closeClick(e.target.getAttribute('data-value'))}
              className={
                color
                  ? `navbar-bg ${menuOpen ? "open" : ""}`
                  : `link-color ${menuOpen ? "open" : ""}`
              }
              style={ pathname === '/price' ? SelectedCss:{color:'white'}}
            >
              Pricing
            </NavLink>
          </div>
        </li>
        <li>
          <div>
            <NavLink
              to="/blog"
              // style={({ isActive }) => ({
              //   color: isActive ? "lightgreen" : "black navbar-bg navbar",
              //   textDecoration: "none",
              // })}
              data-value='Blog'
              onClick={(e)=>closeClick(e.target.getAttribute('data-value'))}
              className={
                color
                  ? `navbar-bg ${menuOpen ? "open" : ""}`
                  : `link-color ${menuOpen ? "open" : ""}`
              }
              style={ pathname.split('/').slice(0, 2).join('/') === '/blog' ? SelectedCss:{color:'white'}}
            >
              Blog
            </NavLink>
          </div>
        </li>
        <li>
          <div>
            <NavLink
              to="/Contactus"
              // style={({ isActive }) => ({
              //   color: isActive ? "lightgreen" : "black navbar-bg navbar",
              //   textDecoration: "none",
              // })}
              data-value='Contact'
              onClick={(e)=>closeClick(e.target.getAttribute('data-value'))}
              className={
                color
                  ? `navbar-bg ${menuOpen ? "open" : ""}`
                  : `link-color ${menuOpen ? "open" : ""}`
              }
              style={ pathname === '/Contactus' ? SelectedCss:{color:'white'}}
            >
              Contact Us
            </NavLink>
          </div>
        </li>
        <li>
          <div id="train">
            <NavLink
              // style={({ isActive }) => ({
              //   color: isActive ? "lightgreen" : "black navbar-bg navbar",
              //   textDecoration: "none",
              // })}
              data-value='VATraining'
              // onClick={(e)=>closeClick(e.target.getAttribute('data-value'))}
              className={
                color
                  ? `navbar-bg ${menuOpen ? "open" : ""}`
                  : `link-color ${menuOpen ? "open" : ""}`
              }
              style={ currentPage === 'VATraining' ? SelectedCss:{color:'white'}}
            >
              VA Trainings
            </NavLink>
          </div>
          <ReactTooltip anchorId="train" place="top" content="Coming Soon" />
        </li>
        <li>
          <div>
            <NavLink
              to="/aiproducts"
              // style={({ isActive }) => ({
              //   color: isActive ? "lightgreen" : "black navbar-bg navbar",
              //   textDecoration: "none",
              // })}
              data-value='AIProducts'
              onClick={(e)=>closeClick(e.target.getAttribute('data-value'))}
              className={
                color
                  ? `navbar-bg ${menuOpen ? "open" : ""}`
                  : `link-color ${menuOpen ? "open" : ""}`
              }
              style={ pathname === '/aiproducts' ? SelectedCss:{color:'white'}}
            >
              AI Product
            </NavLink>
          </div>
        </li>
{
  !userCookie?
  <li>
  <div>
    <NavLink
      to="/signin"
      // style={({ isActive }) => ({
      //   color: isActive ? "lightgreen" : "black navbar-bg navbar",
      //   textDecoration: "none",
      // })}
      data-value='Signin'
      onClick={(e)=>closeClick(e.target.getAttribute('data-value'))}
      className={
        color
          ? `navbar-bg ${menuOpen ? "open" : ""}`
          : `link-color ${menuOpen ? "open" : ""}`
      }
      style={ pathname === '/signin' ? SelectedCss:{color:'white'}}
    >
      Sign In
    </NavLink>
  </div>
</li>:''
}
        {/* {
          userCookie == "admin" ?
          <>
          <li>
          <div>
            <NavLink
              to="/getall"

              className={
                color
                  ? `navbar-bg ${menuOpen ? "open" : ""}`
                  : `link-color ${menuOpen ? "open" : ""}`
              }
              style={{ textDecoration: "none", color: "white" }}
            >
              Tool List
            </NavLink>
          </div>
        </li>
          <li>
          <div>
            <NavLink
              to="/getallBlog"
              className={
                color
                  ? `navbar-bg ${menuOpen ? "open" : ""}`
                  : `link-color ${menuOpen ? "open" : ""}`
              }
              style={{ textDecoration: "none", color: "white" }}
            >
              Blog List
            </NavLink>
          </div>
        </li>
        </>
        :''
        } */}

{/* {
          userCookie == "admin" ?
          <>
         
          <li>
          <div>
            <button
              className={
                color
                  ? `navbar-bg ${menuOpen ? "open" : ""}`
                  : `link-color ${menuOpen ? "open" : ""}`
              }
              style={{ textDecoration: "none", color: "white" }}
              onClick={ ()=>handleLogout()}
            >
             Log Out
            </button>
          </div>
        </li>
        </>
        :''
        } */}
        
        { userCookie == "admin" ?
        <li>

        <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
        //  className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        className={
          color
            ? `navbar-bg inline-flex w-full ${menuOpen ? "open" : ""}`
            : `link-color inline-flex w-full ${menuOpen ? "open" : ""}`
        }
        style={{ textDecoration: "none", color: "white" }}
        >
          Admin
          <ChevronDownIcon className="-mr-1 mt-2 h-5 w-5 text-gray-400" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items style={{right:'-30px'}} className="absolute  right-0 z-10 mt-2  w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
          <Menu.Item>
              {({ active, close }) => (
           <div className="my-2">
            
           <NavLink
             to="/getall"
             className='ms-2 hoverdrop'
             value='Getall'
             onClick={(e)=>{closeClick(e.target.value); close();}}
            //  style={{ textDecoration: "none", color: "black", display: "flex", alignItems: "center"  }}
             style={ pathname === '/getall' ? { textDecoration: "underline grey",  display: "flex", alignItems: "center"  }:{textDecoration:'none',  display: "flex", alignItems: "center"}}
           >
            <FaTools className="me-3" style={{color:'grey'}} /> Tool List
           </NavLink>
         </div>
              )}
            </Menu.Item>
          <Menu.Item>
              {({ active,close }) => (
                  <div className="my-2">
                  <NavLink
                    to="/getallBlog"
                    className='ms-2 hoverdrop'
                    value='Getallblog'
                    onClick={(e)=>{closeClick(e.target.value); close();}}
                    // style={{ textDecoration: "none", color: "black" , display: "flex", alignItems: "center" }}
             style={ pathname == '/getallBlog' ? { textDecoration: "underline grey", display: "flex", alignItems: "center"  }:{textDecoration:'none',  display: "flex", alignItems: "center"}}

                  >
                   <FaBloggerB className="me-3" style={{color:'grey'}} /> Blog List
                  </NavLink>
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active,close }) => (
                 <div className="my-2">
                 <button
                   className='ms-2 hoverdrop'
                   style={{ textDecoration: "none", display: "flex", alignItems: "center"}}
                   onClick={ ()=>{handleLogout();close();}}
                 >
                 <MdLogout className="me-3" style={{color:'grey'}} /> Log Out
                 </button>
               </div>
              )}
            </Menu.Item>
      
          </div>
        </Menu.Items>
      </Transition>
    </Menu>

        </li>
        :''}
        

      </ul>
      <div
        className={`menu-toggle ${menuOpen ? "open" : ""}`}
        onClick={handleMenuToggle}
      >
        
        <span ></span>
        <span></span>
        <span></span>
      </div>
    </nav>
  );
};

export default Header;
